{
  "twitch": [
    "4Head",
    "ANELE",
    "AngryJack",
    "AquamanGG",
    "ArgieB8",
    "ArigatoNas",
    "ArsonNoSexy",
    "AsexualPride",
    "AsianGlow",
    "BCWarrior",
    "BabyRage",
    "BatChest",
    "BegWan",
    "BibleThump",
    "BigBrother",
    "BigPhish",
    "BisexualPride",
    "BlargNaut",
    "BlessRNG",
    "BloodTrail",
    "BrainSlug",
    "BrokeBack",
    "BuddhaBar",
    "CarlSmile",
    "ChefFrank",
    "CoolCat",
    "CoolStoryBob",
    "CorgiDerp",
    "CrreamAwk",
    "CurseLit",
    "DAESuppy",
    "DBstyle",
    "DansGame",
    "DarkMode",
    "DatSheffy",
    "DendiFace",
    "DogFace",
    "DoritosChip",
    "DrinkPurple",
    "DxCat",
    "EarthDay",
    "EleGiggle",
    "EntropyWins",
    "FBBlock",
    "FBCatch",
    "FBChallenge",
    "FBPass",
    "FBPenalty",
    "FBRun",
    "FBSpiral",
    "FBtouchdown",
    "FUNgineer",
    "FailFish",
    "FrankerZ",
    "FreakinStinkin",
    "FutureMan",
    "GayPride",
    "GenderFluidPride",
    "GingerPower",
    "GivePLZ",
    "GrammarKing",
    "GreenTeam",
    "GunRun",
    "HSCheers",
    "HSWP",
    "HappyJack",
    "HassaanChop",
    "HassanChop",
    "HeyGuys",
    "HolidayCookie",
    "HolidayLog",
    "HolidayOrnament",
    "HolidayPresent",
    "HolidaySanta",
    "HolidayTree",
    "HotPokket",
    "HumbleLife",
    "IntersexPride",
    "InuyoFace",
    "ItsBoshyTime",
    "JKanStyle",
    "Jebaited",
    "JonCarnage",
    "KAPOW",
    "Kappa",
    "KappaClaus",
    "KappaPride",
    "KappaRoss",
    "KappaWealth",
    "Kappu",
    "Keepo",
    "KevinTurtle",
    "Kippa",
    "KomodoHype",
    "KonCha",
    "Kreygasm",
    "LUL",
    "LesbianPride",
    "MVGame",
    "Mau5",
    "MaxLOL",
    "MercyWing1",
    "MercyWing2",
    "MikeHogu",
    "MingLee",
    "MorphinTime",
    "MrDestructoid",
    "NinjaGrumpy",
    "NomNom",
    "NonBinaryPride",
    "NotATK",
    "NotLikeThis",
    "OSFrog",
    "OhMyDog",
    "OneHand",
    "OpieOP",
    "OptimizePrime",
    "PJSalt",
    "PJSugar",
    "PMSTwin",
    "PRChase",
    "PanicVis",
    "PansexualPride",
    "PartyHat",
    "PartyTime",
    "PeoplesChamp",
    "PermaSmug",
    "PeteZaroll",
    "PeteZarollOdyssey",
    "PicoMause",
    "PinkMercy",
    "PipeHype",
    "PixelBob",
    "PogChamp",
    "Poooound",
    "PopCorn",
    "PowerUpL",
    "PowerUpR",
    "PraiseIt",
    "PrimeMe",
    "PunOko",
    "PunchTrees",
    "PurpleStar",
    "RaccAttack",
    "RalpherZ",
    "RedCoat",
    "RedTeam",
    "ResidentSleeper",
    "RitzMitz",
    "RlyTho",
    "RuleFive",
    "SMOrc",
    "SSSsss",
    "SabaPing",
    "SeemsGood",
    "SeriousSloth",
    "ShadyLulu",
    "ShazBotstix",
    "SmoocherZ",
    "SoBayed",
    "SoonerLater",
    "Squid1",
    "Squid2",
    "Squid3",
    "Squid4",
    "StinkyCheese",
    "StoneLightning",
    "StrawBeary",
    "SuperVinlin",
    "SwiftRage",
    "TBAngel",
    "TF2John",
    "TPFufun",
    "TPcrunchyroll",
    "TTours",
    "TakeNRG",
    "TearGlove",
    "TehePelo",
    "ThankEgg",
    "TheIlluminati",
    "TheRinger",
    "TheTarFu",
    "TheThing",
    "ThunBeast",
    "TinyFace",
    "TombRaid",
    "TooSpicy",
    "TransgenderPride",
    "TriHard",
    "TwitchLit",
    "TwitchRPG",
    "TwitchUnity",
    "TwitchVotes",
    "UWot",
    "UnSane",
    "UncleNox",
    "VoHiYo",
    "VoteNay",
    "VoteYea",
    "WTRuck",
    "WholeWheat",
    "WutFace",
    "YouDontSay",
    "YouWHY",
    "bleedPurple",
    "cmonBruh",
    "copyThis",
    "duDudu",
    "imGlitch",
    "mcaT",
    "panicBasket",
    "pastaThat",
    "riPepperonis",
    "twitchRaid"
  ],
  "twitch-turbo": ["KappaHD", "MiniK"],
  "betterttv": [
    "OhMyGoodness",
    "PancakeMix",
    "PedoBear",
    "PokerFace",
    "RageFace",
    "RebeccaBlack",
    "aPliS",
    "CiGrip",
    "CHAccepted",
    "FuckYea",
    "DatSauce",
    "ForeverAlone",
    "GabeN",
    "HailHelix",
    "HerbPerve",
    "iDog",
    "rStrike",
    "ShoopDaWhoop",
    "SwedSwag",
    "M&Mjc",
    "bttvNice",
    "TopHam",
    "TwaT",
    "WhatAYolk",
    "WatChuSay",
    "Blackappa",
    "DogeWitIt",
    "BadAss",
    "SavageJerky",
    "Zappa",
    "tehPoleCat",
    "AngelThump",
    "Kaged",
    "HHydro",
    "TaxiBro",
    "BroBalt",
    "ButterSauce",
    "BaconEffect",
    "SuchFraud",
    "CandianRage",
    "She'llBeRight",
    "OhhhKee",
    "SexPanda",
    "bttvWink",
    "bttvAngry",
    "bttvConfused",
    "bttvCool",
    "bttvHappy",
    "bttvSad",
    "bttvSleep",
    "bttvSurprised",
    "bttvTongue",
    "bttvUnsure",
    "bttvGrin",
    "bttvHeart",
    "bttvTwink",
    "VisLaud",
    "SoSerious",
    "BatKappa",
    "KaRappa",
    "YetiZ",
    "miniJulia",
    "FishMoley",
    "Hhhehehe",
    "KKona",
    "OhGod",
    "PoleDoge",
    "motnahP",
    "sosGame",
    "CruW",
    "RarePepe",
    "iamsocal",
    "haHAA",
    "FeelsBirthdayMan",
    "RonSmug",
    "KappaCool",
    "FeelsBadMan",
    "BasedGod",
    "bUrself",
    "ConcernDoge",
    "FapFapFap",
    "FeelsGoodMan",
    "FireSpeed",
    "NaM",
    "SourPls",
    "LuL",
    "SaltyCorn",
    "FCreep",
    "monkaS",
    "VapeNation",
    "ariW",
    "notsquishY",
    "FeelsAmazingMan",
    "DuckerZ",
    "SqShy",
    "Wowee"
  ],
  "frankerfacez": [
    "BORT",
    "BeanieHipster",
    "CatBag",
    "LaterSooner",
    "LilZ",
    "ManChicken",
    "YellowFever",
    "YooHoo",
    "ZreknarF"
  ],
  "frankerfacez-custom": [
    "3Head",
    "4HEad",
    "4House",
    "4Shrug",
    "4WeirdW",
    "5Head",
    "AYAYA",
    "Champ",
    "COPIUM",
    "coronaS",
    "coronaW",
    "DICKS",
    "EZY",
    "FeelsDankMan",
    "FeelsOkayMan",
    "FeelsSpecialMan",
    "FeelsStrongMan",
    "FeelsWeirdMan",
    "FrankerB",
    "FrankerG",
    "FrankerO",
    "FrankerP",
    "FrankerR",
    "FrankerY",
    "FrankerZPride",
    "gachiGASM",
    "HandsUp",
    "HYPERBRUH",
    "HyperLUL",
    "HYPERS",
    "Kadda",
    "Kapp",
    "KEKW",
    "KKomrade",
    "KKonaW",
    "Klappa",
    "LIES",
    "LULW",
    "Madge",
    "monkaEyes",
    "monkaGun",
    "monkaH",
    "monkaHmm",
    "monkaMEGA",
    "monkaOMEGA",
    "monkaStare",
    "monkaTOS",
    "monkaW",
    "NODUDE",
    "NotLikeZam",
    "NootLikeThis",
    "OkayChamp",
    "Okayge",
    "OMEGALUL",
    "OMGScoots",
    "PagChomp",
    "PauseChamp",
    "peepoHappy",
    "Pepega",
    "PepeHands",
    "PepeLaugh",
    "PepoG",
    "PepoThink",
    "Pog",
    "PogCena",
    "POGGERS",
    "PogU",
    "PogYou",
    "PogZam",
    "REEeee",
    "Sadge",
    "SexyDaddy",
    "ThisIsFine",
    "WeirdChamp",
    "weSmart",
    "WICKED",
    "WideHard",
    "widepeepoHappy",
    "widepeepoSad"
  ]
}
