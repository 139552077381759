[
  {
    "id": 0,
    "newID": "R+Y+G+B+P",
    "name": "No Variant",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"]
  },
  {
    "id": 1,
    "newID": "R+Y+G+B+P+T",
    "name": "6 Suits",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"]
  },
  {
    "id": 15,
    "newID": "R+Y+G+B",
    "name": "4 Suits",
    "suits": ["Red", "Yellow", "Green", "Blue"]
  },
  {
    "id": 18,
    "newID": "R+G+B",
    "name": "3 Suits",
    "suits": ["Red", "Green", "Blue"]
  },
  {
    "id": 2,
    "newID": "R+Y+G+B+P+Bk",
    "name": "Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"]
  },
  {
    "id": 21,
    "newID": "R+Y+G+B+Bk",
    "name": "Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"]
  },
  {
    "id": 3,
    "newID": "R+Y+G+B+P+Ra",
    "name": "Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"]
  },
  {
    "id": 16,
    "newID": "R+Y+G+B+Ra",
    "name": "Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"]
  },
  {
    "id": 17,
    "newID": "R+G+B+Ra",
    "name": "Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"]
  },
  {
    "id": 19,
    "newID": "R+B+Ra",
    "name": "Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"]
  },
  {
    "id": 106,
    "newID": "R+Y+G+B+P+Pi",
    "name": "Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"]
  },
  {
    "id": 107,
    "newID": "R+Y+G+B+Pi",
    "name": "Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"]
  },
  {
    "id": 108,
    "newID": "R+G+B+Pi",
    "name": "Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"]
  },
  {
    "id": 109,
    "newID": "R+B+Pi",
    "name": "Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"]
  },
  {
    "id": 13,
    "newID": "R+Y+G+B+P+Wh",
    "name": "White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"]
  },
  {
    "id": 22,
    "newID": "R+Y+G+B+Wh",
    "name": "White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"]
  },
  {
    "id": 26,
    "newID": "R+G+B+Wh",
    "name": "White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"]
  },
  {
    "id": 27,
    "newID": "R+B+Wh",
    "name": "White (3 Suits)",
    "suits": ["Red", "Blue", "White"]
  },
  {
    "id": 69,
    "newID": "R+Y+G+B+P+Br",
    "name": "Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"]
  },
  {
    "id": 70,
    "newID": "R+Y+G+B+Br",
    "name": "Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"]
  },
  {
    "id": 71,
    "newID": "R+G+B+Br",
    "name": "Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"]
  },
  {
    "id": 72,
    "newID": "R+B+Br",
    "name": "Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"]
  },
  {
    "id": 176,
    "newID": "R+Y+G+B+P+Om",
    "name": "Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"]
  },
  {
    "id": 177,
    "newID": "R+Y+G+B+Om",
    "name": "Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"]
  },
  {
    "id": 178,
    "newID": "R+G+B+Om",
    "name": "Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"]
  },
  {
    "id": 179,
    "newID": "R+B+Om",
    "name": "Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"]
  },
  {
    "id": 180,
    "newID": "R+Y+G+B+P+Nu",
    "name": "Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"]
  },
  {
    "id": 181,
    "newID": "R+Y+G+B+Nu",
    "name": "Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"]
  },
  {
    "id": 182,
    "newID": "R+G+B+Nu",
    "name": "Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"]
  },
  {
    "id": 183,
    "newID": "R+B+Nu",
    "name": "Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"]
  },
  {
    "id": 160,
    "newID": "R+Y+G+B+P+BrRa",
    "name": "Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"]
  },
  {
    "id": 161,
    "newID": "R+Y+G+B+BrRa",
    "name": "Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"]
  },
  {
    "id": 162,
    "newID": "R+G+B+BrRa",
    "name": "Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"]
  },
  {
    "id": 163,
    "newID": "R+B+BrRa",
    "name": "Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"]
  },
  {
    "id": 164,
    "newID": "R+Y+G+B+P+WhPi",
    "name": "Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"]
  },
  {
    "id": 165,
    "newID": "R+Y+G+B+WhPi",
    "name": "Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"]
  },
  {
    "id": 166,
    "newID": "R+G+B+WhPi",
    "name": "Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"]
  },
  {
    "id": 167,
    "newID": "R+B+WhPi",
    "name": "Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"]
  },
  {
    "id": 1464,
    "newID": "R+Y+G+B+P+Pr",
    "name": "Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"]
  },
  {
    "id": 1465,
    "newID": "R+Y+G+B+Pr",
    "name": "Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"]
  },
  {
    "id": 1466,
    "newID": "R+G+B+Pr",
    "name": "Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"]
  },
  {
    "id": 1467,
    "newID": "R+B+Pr",
    "name": "Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"]
  },
  {
    "id": 11,
    "newID": "R+Y+G+B+P+DRa",
    "name": "Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"]
  },
  {
    "id": 23,
    "newID": "R+Y+G+B+DRa",
    "name": "Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"]
  },
  {
    "id": 137,
    "newID": "R+Y+G+B+P+DPi",
    "name": "Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"]
  },
  {
    "id": 138,
    "newID": "R+Y+G+B+DPi",
    "name": "Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"]
  },
  {
    "id": 59,
    "newID": "R+Y+G+B+P+DWh",
    "name": "Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"]
  },
  {
    "id": 58,
    "newID": "R+Y+G+B+DWh",
    "name": "Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"]
  },
  {
    "id": 89,
    "newID": "R+Y+G+B+P+DBr",
    "name": "Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"]
  },
  {
    "id": 90,
    "newID": "R+Y+G+B+DBr",
    "name": "Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"]
  },
  {
    "id": 242,
    "newID": "R+Y+G+B+P+DOm",
    "name": "Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"]
  },
  {
    "id": 243,
    "newID": "R+Y+G+B+DOm",
    "name": "Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"]
  },
  {
    "id": 255,
    "newID": "R+Y+G+B+P+DNu",
    "name": "Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"]
  },
  {
    "id": 256,
    "newID": "R+Y+G+B+DNu",
    "name": "Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"]
  },
  {
    "id": 290,
    "newID": "R+Y+G+B+P+DBrRa",
    "name": "Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"]
  },
  {
    "id": 291,
    "newID": "R+Y+G+B+DBrRa",
    "name": "Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"]
  },
  {
    "id": 292,
    "newID": "R+Y+G+B+P+DWhPi",
    "name": "Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"]
  },
  {
    "id": 293,
    "newID": "R+Y+G+B+DWhPi",
    "name": "Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"]
  },
  {
    "id": 1468,
    "newID": "R+Y+G+B+P+DPr",
    "name": "Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"]
  },
  {
    "id": 1469,
    "newID": "R+Y+G+B+DPr",
    "name": "Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"]
  },
  {
    "id": 65,
    "newID": "R+Y+G+B+Bk+Ra",
    "name": "Black & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Rainbow"]
  },
  {
    "id": 66,
    "newID": "R+G+B+Bk+Ra",
    "name": "Black & Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Rainbow"]
  },
  {
    "id": 118,
    "newID": "R+Y+G+B+Bk+Pi",
    "name": "Black & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Pink"]
  },
  {
    "id": 119,
    "newID": "R+G+B+Bk+Pi",
    "name": "Black & Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Pink"]
  },
  {
    "id": 63,
    "newID": "R+Y+G+B+Bk+Wh",
    "name": "Black & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "White"]
  },
  {
    "id": 64,
    "newID": "R+G+B+Bk+Wh",
    "name": "Black & White (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "White"]
  },
  {
    "id": 73,
    "newID": "R+Y+G+B+Bk+Br",
    "name": "Black & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Brown"]
  },
  {
    "id": 74,
    "newID": "R+G+B+Bk+Br",
    "name": "Black & Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Brown"]
  },
  {
    "id": 186,
    "newID": "R+Y+G+B+Bk+Om",
    "name": "Black & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Omni"]
  },
  {
    "id": 187,
    "newID": "R+G+B+Bk+Om",
    "name": "Black & Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Omni"]
  },
  {
    "id": 188,
    "newID": "R+Y+G+B+Bk+Nu",
    "name": "Black & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Null"]
  },
  {
    "id": 189,
    "newID": "R+G+B+Bk+Nu",
    "name": "Black & Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Null"]
  },
  {
    "id": 758,
    "newID": "R+Y+G+B+Bk+BrRa",
    "name": "Black & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Muddy Rainbow"]
  },
  {
    "id": 759,
    "newID": "R+G+B+Bk+BrRa",
    "name": "Black & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Muddy Rainbow"]
  },
  {
    "id": 780,
    "newID": "R+Y+G+B+Bk+WhPi",
    "name": "Black & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Light Pink"]
  },
  {
    "id": 781,
    "newID": "R+G+B+Bk+WhPi",
    "name": "Black & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Light Pink"]
  },
  {
    "id": 1470,
    "newID": "R+Y+G+B+Bk+Pr",
    "name": "Black & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Prism"]
  },
  {
    "id": 1471,
    "newID": "R+G+B+Bk+Pr",
    "name": "Black & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Black", "Prism"]
  },
  {
    "id": 12,
    "newID": "R+Y+G+B+Bk+DRa",
    "name": "Black & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Dark Rainbow"]
  },
  {
    "id": 139,
    "newID": "R+Y+G+B+Bk+DPi",
    "name": "Black & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Dark Pink"]
  },
  {
    "id": 60,
    "newID": "R+Y+G+B+Bk+DWh",
    "name": "Black & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Gray"]
  },
  {
    "id": 91,
    "newID": "R+Y+G+B+Bk+DBr",
    "name": "Black & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Dark Brown"]
  },
  {
    "id": 244,
    "newID": "R+Y+G+B+Bk+DOm",
    "name": "Black & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Dark Omni"]
  },
  {
    "id": 257,
    "newID": "R+Y+G+B+Bk+DNu",
    "name": "Black & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Dark Null"]
  },
  {
    "id": 826,
    "newID": "R+Y+G+B+Bk+DBrRa",
    "name": "Black & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Cocoa Rainbow"]
  },
  {
    "id": 839,
    "newID": "R+Y+G+B+Bk+DWhPi",
    "name": "Black & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Gray Pink"]
  },
  {
    "id": 1472,
    "newID": "R+Y+G+B+Bk+DPr",
    "name": "Black & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black", "Dark Prism"]
  },
  {
    "id": 120,
    "newID": "R+Y+G+B+Ra+Pi",
    "name": "Rainbow & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Pink"]
  },
  {
    "id": 121,
    "newID": "R+G+B+Ra+Pi",
    "name": "Rainbow & Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Pink"]
  },
  {
    "id": 122,
    "newID": "R+B+Ra+Pi",
    "name": "Rainbow & Pink (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Pink"]
  },
  {
    "id": 149,
    "newID": "R+Ra+Pi",
    "name": "Rainbow & Pink (3 Suits)",
    "suits": ["Red", "Rainbow", "Pink"]
  },
  {
    "id": 6,
    "newID": "R+Y+G+B+Ra+Wh",
    "name": "Rainbow & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "White"]
  },
  {
    "id": 28,
    "newID": "R+G+B+Ra+Wh",
    "name": "Rainbow & White (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "White"]
  },
  {
    "id": 29,
    "newID": "R+B+Ra+Wh",
    "name": "Rainbow & White (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "White"]
  },
  {
    "id": 75,
    "newID": "R+Y+G+B+Ra+Br",
    "name": "Rainbow & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Brown"]
  },
  {
    "id": 76,
    "newID": "R+G+B+Ra+Br",
    "name": "Rainbow & Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Brown"]
  },
  {
    "id": 77,
    "newID": "R+B+Ra+Br",
    "name": "Rainbow & Brown (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Brown"]
  },
  {
    "id": 78,
    "newID": "R+Ra+Br",
    "name": "Rainbow & Brown (3 Suits)",
    "suits": ["Red", "Rainbow", "Brown"]
  },
  {
    "id": 190,
    "newID": "R+Y+G+B+Ra+Om",
    "name": "Rainbow & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Omni"]
  },
  {
    "id": 191,
    "newID": "R+G+B+Ra+Om",
    "name": "Rainbow & Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Omni"]
  },
  {
    "id": 192,
    "newID": "R+B+Ra+Om",
    "name": "Rainbow & Omni (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Omni"]
  },
  {
    "id": 194,
    "newID": "R+Y+G+B+Ra+Nu",
    "name": "Rainbow & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Null"]
  },
  {
    "id": 195,
    "newID": "R+G+B+Ra+Nu",
    "name": "Rainbow & Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Null"]
  },
  {
    "id": 196,
    "newID": "R+B+Ra+Nu",
    "name": "Rainbow & Null (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Null"]
  },
  {
    "id": 1292,
    "newID": "R+Y+G+B+Ra+BrRa",
    "name": "Rainbow & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Muddy Rainbow"]
  },
  {
    "id": 1293,
    "newID": "R+G+B+Ra+BrRa",
    "name": "Rainbow & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Muddy Rainbow"]
  },
  {
    "id": 1294,
    "newID": "R+B+Ra+BrRa",
    "name": "Rainbow & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Muddy Rainbow"]
  },
  {
    "id": 782,
    "newID": "R+Y+G+B+Ra+WhPi",
    "name": "Rainbow & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Light Pink"]
  },
  {
    "id": 783,
    "newID": "R+G+B+Ra+WhPi",
    "name": "Rainbow & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Light Pink"]
  },
  {
    "id": 784,
    "newID": "R+B+Ra+WhPi",
    "name": "Rainbow & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Light Pink"]
  },
  {
    "id": 1473,
    "newID": "R+Y+G+B+Ra+Pr",
    "name": "Rainbow & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Prism"]
  },
  {
    "id": 1474,
    "newID": "R+G+B+Ra+Pr",
    "name": "Rainbow & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Prism"]
  },
  {
    "id": 1475,
    "newID": "R+B+Ra+Pr",
    "name": "Rainbow & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Rainbow", "Prism"]
  },
  {
    "id": 140,
    "newID": "R+Y+G+B+Ra+DPi",
    "name": "Rainbow & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Dark Pink"]
  },
  {
    "id": 141,
    "newID": "R+G+B+Ra+DPi",
    "name": "Rainbow & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Dark Pink"]
  },
  {
    "id": 67,
    "newID": "R+Y+G+B+Ra+DWh",
    "name": "Rainbow & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Gray"]
  },
  {
    "id": 68,
    "newID": "R+G+B+Ra+DWh",
    "name": "Rainbow & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Gray"]
  },
  {
    "id": 92,
    "newID": "R+Y+G+B+Ra+DBr",
    "name": "Rainbow & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Dark Brown"]
  },
  {
    "id": 93,
    "newID": "R+G+B+Ra+DBr",
    "name": "Rainbow & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Dark Brown"]
  },
  {
    "id": 245,
    "newID": "R+Y+G+B+Ra+DOm",
    "name": "Rainbow & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Dark Omni"]
  },
  {
    "id": 246,
    "newID": "R+G+B+Ra+DOm",
    "name": "Rainbow & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Dark Omni"]
  },
  {
    "id": 258,
    "newID": "R+Y+G+B+Ra+DNu",
    "name": "Rainbow & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Dark Null"]
  },
  {
    "id": 259,
    "newID": "R+G+B+Ra+DNu",
    "name": "Rainbow & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Dark Null"]
  },
  {
    "id": 1300,
    "newID": "R+Y+G+B+Ra+DBrRa",
    "name": "Rainbow & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Cocoa Rainbow"]
  },
  {
    "id": 1301,
    "newID": "R+G+B+Ra+DBrRa",
    "name": "Rainbow & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Cocoa Rainbow"]
  },
  {
    "id": 840,
    "newID": "R+Y+G+B+Ra+DWhPi",
    "name": "Rainbow & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Gray Pink"]
  },
  {
    "id": 841,
    "newID": "R+G+B+Ra+DWhPi",
    "name": "Rainbow & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Gray Pink"]
  },
  {
    "id": 1477,
    "newID": "R+Y+G+B+Ra+DPr",
    "name": "Rainbow & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow", "Dark Prism"]
  },
  {
    "id": 1478,
    "newID": "R+G+B+Ra+DPr",
    "name": "Rainbow & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow", "Dark Prism"]
  },
  {
    "id": 123,
    "newID": "R+Y+G+B+Pi+Wh",
    "name": "Pink & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "White"]
  },
  {
    "id": 124,
    "newID": "R+G+B+Pi+Wh",
    "name": "Pink & White (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "White"]
  },
  {
    "id": 125,
    "newID": "R+B+Pi+Wh",
    "name": "Pink & White (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "White"]
  },
  {
    "id": 126,
    "newID": "R+Pi+Wh",
    "name": "Pink & White (3 Suits)",
    "suits": ["Red", "Pink", "White"]
  },
  {
    "id": 127,
    "newID": "R+Y+G+B+Pi+Br",
    "name": "Pink & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Brown"]
  },
  {
    "id": 128,
    "newID": "R+G+B+Pi+Br",
    "name": "Pink & Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Brown"]
  },
  {
    "id": 129,
    "newID": "R+B+Pi+Br",
    "name": "Pink & Brown (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "Brown"]
  },
  {
    "id": 130,
    "newID": "R+Pi+Br",
    "name": "Pink & Brown (3 Suits)",
    "suits": ["Red", "Pink", "Brown"]
  },
  {
    "id": 198,
    "newID": "R+Y+G+B+Pi+Om",
    "name": "Pink & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Omni"]
  },
  {
    "id": 199,
    "newID": "R+G+B+Pi+Om",
    "name": "Pink & Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Omni"]
  },
  {
    "id": 200,
    "newID": "R+B+Pi+Om",
    "name": "Pink & Omni (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "Omni"]
  },
  {
    "id": 201,
    "newID": "R+Pi+Om",
    "name": "Pink & Omni (3 Suits)",
    "suits": ["Red", "Pink", "Omni"]
  },
  {
    "id": 202,
    "newID": "R+Y+G+B+Pi+Nu",
    "name": "Pink & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Null"]
  },
  {
    "id": 203,
    "newID": "R+G+B+Pi+Nu",
    "name": "Pink & Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Null"]
  },
  {
    "id": 204,
    "newID": "R+B+Pi+Nu",
    "name": "Pink & Null (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "Null"]
  },
  {
    "id": 205,
    "newID": "R+Pi+Nu",
    "name": "Pink & Null (3 Suits)",
    "suits": ["Red", "Pink", "Null"]
  },
  {
    "id": 760,
    "newID": "R+Y+G+B+Pi+BrRa",
    "name": "Pink & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Muddy Rainbow"]
  },
  {
    "id": 761,
    "newID": "R+G+B+Pi+BrRa",
    "name": "Pink & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Muddy Rainbow"]
  },
  {
    "id": 762,
    "newID": "R+B+Pi+BrRa",
    "name": "Pink & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "Muddy Rainbow"]
  },
  {
    "id": 763,
    "newID": "R+Pi+BrRa",
    "name": "Pink & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Pink", "Muddy Rainbow"]
  },
  {
    "id": 1296,
    "newID": "R+Y+G+B+Pi+WhPi",
    "name": "Pink & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Light Pink"]
  },
  {
    "id": 1297,
    "newID": "R+G+B+Pi+WhPi",
    "name": "Pink & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Light Pink"]
  },
  {
    "id": 1298,
    "newID": "R+B+Pi+WhPi",
    "name": "Pink & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "Light Pink"]
  },
  {
    "id": 1299,
    "newID": "R+Pi+WhPi",
    "name": "Pink & Light Pink (3 Suits)",
    "suits": ["Red", "Pink", "Light Pink"]
  },
  {
    "id": 1479,
    "newID": "R+Y+G+B+Pi+Pr",
    "name": "Pink & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Prism"]
  },
  {
    "id": 1480,
    "newID": "R+G+B+Pi+Pr",
    "name": "Pink & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Prism"]
  },
  {
    "id": 1481,
    "newID": "R+B+Pi+Pr",
    "name": "Pink & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Pink", "Prism"]
  },
  {
    "id": 1482,
    "newID": "R+Pi+Pr",
    "name": "Pink & Prism (3 Suits)",
    "suits": ["Red", "Pink", "Prism"]
  },
  {
    "id": 131,
    "newID": "R+Y+G+B+Pi+DRa",
    "name": "Pink & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Dark Rainbow"]
  },
  {
    "id": 132,
    "newID": "R+G+B+Pi+DRa",
    "name": "Pink & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Dark Rainbow"]
  },
  {
    "id": 133,
    "newID": "R+Y+G+B+Pi+DWh",
    "name": "Pink & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Gray"]
  },
  {
    "id": 134,
    "newID": "R+G+B+Pi+DWh",
    "name": "Pink & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Gray"]
  },
  {
    "id": 135,
    "newID": "R+Y+G+B+Pi+DBr",
    "name": "Pink & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Dark Brown"]
  },
  {
    "id": 136,
    "newID": "R+G+B+Pi+DBr",
    "name": "Pink & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Dark Brown"]
  },
  {
    "id": 247,
    "newID": "R+Y+G+B+Pi+DOm",
    "name": "Pink & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Dark Omni"]
  },
  {
    "id": 248,
    "newID": "R+G+B+Pi+DOm",
    "name": "Pink & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Dark Omni"]
  },
  {
    "id": 260,
    "newID": "R+Y+G+B+Pi+DNu",
    "name": "Pink & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Dark Null"]
  },
  {
    "id": 261,
    "newID": "R+G+B+Pi+DNu",
    "name": "Pink & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Dark Null"]
  },
  {
    "id": 827,
    "newID": "R+Y+G+B+Pi+DBrRa",
    "name": "Pink & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Cocoa Rainbow"]
  },
  {
    "id": 828,
    "newID": "R+G+B+Pi+DBrRa",
    "name": "Pink & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Cocoa Rainbow"]
  },
  {
    "id": 1302,
    "newID": "R+Y+G+B+Pi+DWhPi",
    "name": "Pink & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Gray Pink"]
  },
  {
    "id": 1303,
    "newID": "R+G+B+Pi+DWhPi",
    "name": "Pink & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Gray Pink"]
  },
  {
    "id": 1483,
    "newID": "R+Y+G+B+Pi+DPr",
    "name": "Pink & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink", "Dark Prism"]
  },
  {
    "id": 1484,
    "newID": "R+G+B+Pi+DPr",
    "name": "Pink & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink", "Dark Prism"]
  },
  {
    "id": 79,
    "newID": "R+Y+G+B+Wh+Br",
    "name": "White & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Brown"]
  },
  {
    "id": 80,
    "newID": "R+G+B+Wh+Br",
    "name": "White & Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Brown"]
  },
  {
    "id": 81,
    "newID": "R+B+Wh+Br",
    "name": "White & Brown (4 Suits)",
    "suits": ["Red", "Blue", "White", "Brown"]
  },
  {
    "id": 82,
    "newID": "R+Wh+Br",
    "name": "White & Brown (3 Suits)",
    "suits": ["Red", "White", "Brown"]
  },
  {
    "id": 206,
    "newID": "R+Y+G+B+Wh+Om",
    "name": "White & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Omni"]
  },
  {
    "id": 207,
    "newID": "R+G+B+Wh+Om",
    "name": "White & Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Omni"]
  },
  {
    "id": 208,
    "newID": "R+B+Wh+Om",
    "name": "White & Omni (4 Suits)",
    "suits": ["Red", "Blue", "White", "Omni"]
  },
  {
    "id": 209,
    "newID": "R+Wh+Om",
    "name": "White & Omni (3 Suits)",
    "suits": ["Red", "White", "Omni"]
  },
  {
    "id": 210,
    "newID": "R+Y+G+B+Wh+Nu",
    "name": "White & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Null"]
  },
  {
    "id": 211,
    "newID": "R+G+B+Wh+Nu",
    "name": "White & Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Null"]
  },
  {
    "id": 212,
    "newID": "R+B+Wh+Nu",
    "name": "White & Null (4 Suits)",
    "suits": ["Red", "Blue", "White", "Null"]
  },
  {
    "id": 213,
    "newID": "R+Wh+Nu",
    "name": "White & Null (3 Suits)",
    "suits": ["Red", "White", "Null"]
  },
  {
    "id": 764,
    "newID": "R+Y+G+B+Wh+BrRa",
    "name": "White & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Muddy Rainbow"]
  },
  {
    "id": 765,
    "newID": "R+G+B+Wh+BrRa",
    "name": "White & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Muddy Rainbow"]
  },
  {
    "id": 766,
    "newID": "R+B+Wh+BrRa",
    "name": "White & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Blue", "White", "Muddy Rainbow"]
  },
  {
    "id": 767,
    "newID": "R+Wh+BrRa",
    "name": "White & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "White", "Muddy Rainbow"]
  },
  {
    "id": 786,
    "newID": "R+Y+G+B+Wh+WhPi",
    "name": "White & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Light Pink"]
  },
  {
    "id": 787,
    "newID": "R+G+B+Wh+WhPi",
    "name": "White & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Light Pink"]
  },
  {
    "id": 788,
    "newID": "R+B+Wh+WhPi",
    "name": "White & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "White", "Light Pink"]
  },
  {
    "id": 789,
    "newID": "R+Wh+WhPi",
    "name": "White & Light Pink (3 Suits)",
    "suits": ["Red", "White", "Light Pink"]
  },
  {
    "id": 1485,
    "newID": "R+Y+G+B+Wh+Pr",
    "name": "White & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Prism"]
  },
  {
    "id": 1486,
    "newID": "R+G+B+Wh+Pr",
    "name": "White & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Prism"]
  },
  {
    "id": 1487,
    "newID": "R+B+Wh+Pr",
    "name": "White & Prism (4 Suits)",
    "suits": ["Red", "Blue", "White", "Prism"]
  },
  {
    "id": 83,
    "newID": "R+Y+G+B+Wh+DRa",
    "name": "White & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Dark Rainbow"]
  },
  {
    "id": 84,
    "newID": "R+G+B+Wh+DRa",
    "name": "White & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Dark Rainbow"]
  },
  {
    "id": 142,
    "newID": "R+Y+G+B+Wh+DPi",
    "name": "White & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Dark Pink"]
  },
  {
    "id": 143,
    "newID": "R+G+B+Wh+DPi",
    "name": "White & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Dark Pink"]
  },
  {
    "id": 94,
    "newID": "R+Y+G+B+Wh+DBr",
    "name": "White & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Dark Brown"]
  },
  {
    "id": 95,
    "newID": "R+G+B+Wh+DBr",
    "name": "White & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Dark Brown"]
  },
  {
    "id": 249,
    "newID": "R+Y+G+B+Wh+DOm",
    "name": "White & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Dark Omni"]
  },
  {
    "id": 250,
    "newID": "R+G+B+Wh+DOm",
    "name": "White & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Dark Omni"]
  },
  {
    "id": 262,
    "newID": "R+Y+G+B+Wh+DNu",
    "name": "White & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Dark Null"]
  },
  {
    "id": 263,
    "newID": "R+G+B+Wh+DNu",
    "name": "White & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Dark Null"]
  },
  {
    "id": 829,
    "newID": "R+Y+G+B+Wh+DBrRa",
    "name": "White & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Cocoa Rainbow"]
  },
  {
    "id": 830,
    "newID": "R+G+B+Wh+DBrRa",
    "name": "White & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Cocoa Rainbow"]
  },
  {
    "id": 842,
    "newID": "R+Y+G+B+Wh+DWhPi",
    "name": "White & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Gray Pink"]
  },
  {
    "id": 843,
    "newID": "R+G+B+Wh+DWhPi",
    "name": "White & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Gray Pink"]
  },
  {
    "id": 1489,
    "newID": "R+Y+G+B+Wh+DPr",
    "name": "White & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White", "Dark Prism"]
  },
  {
    "id": 1490,
    "newID": "R+G+B+Wh+DPr",
    "name": "White & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "White", "Dark Prism"]
  },
  {
    "id": 214,
    "newID": "R+Y+G+B+Br+Om",
    "name": "Brown & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Omni"]
  },
  {
    "id": 215,
    "newID": "R+G+B+Br+Om",
    "name": "Brown & Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Omni"]
  },
  {
    "id": 216,
    "newID": "R+B+Br+Om",
    "name": "Brown & Omni (4 Suits)",
    "suits": ["Red", "Blue", "Brown", "Omni"]
  },
  {
    "id": 217,
    "newID": "R+Br+Om",
    "name": "Brown & Omni (3 Suits)",
    "suits": ["Red", "Brown", "Omni"]
  },
  {
    "id": 218,
    "newID": "R+Y+G+B+Br+Nu",
    "name": "Brown & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Null"]
  },
  {
    "id": 219,
    "newID": "R+G+B+Br+Nu",
    "name": "Brown & Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Null"]
  },
  {
    "id": 220,
    "newID": "R+B+Br+Nu",
    "name": "Brown & Null (4 Suits)",
    "suits": ["Red", "Blue", "Brown", "Null"]
  },
  {
    "id": 221,
    "newID": "R+Br+Nu",
    "name": "Brown & Null (3 Suits)",
    "suits": ["Red", "Brown", "Null"]
  },
  {
    "id": 768,
    "newID": "R+Y+G+B+Br+BrRa",
    "name": "Brown & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Muddy Rainbow"]
  },
  {
    "id": 769,
    "newID": "R+G+B+Br+BrRa",
    "name": "Brown & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Muddy Rainbow"]
  },
  {
    "id": 770,
    "newID": "R+B+Br+BrRa",
    "name": "Brown & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Blue", "Brown", "Muddy Rainbow"]
  },
  {
    "id": 771,
    "newID": "R+Br+BrRa",
    "name": "Brown & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Brown", "Muddy Rainbow"]
  },
  {
    "id": 790,
    "newID": "R+Y+G+B+Br+WhPi",
    "name": "Brown & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Light Pink"]
  },
  {
    "id": 791,
    "newID": "R+G+B+Br+WhPi",
    "name": "Brown & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Light Pink"]
  },
  {
    "id": 792,
    "newID": "R+B+Br+WhPi",
    "name": "Brown & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "Brown", "Light Pink"]
  },
  {
    "id": 793,
    "newID": "R+Br+WhPi",
    "name": "Brown & Light Pink (3 Suits)",
    "suits": ["Red", "Brown", "Light Pink"]
  },
  {
    "id": 1491,
    "newID": "R+Y+G+B+Br+Pr",
    "name": "Brown & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Prism"]
  },
  {
    "id": 1492,
    "newID": "R+G+B+Br+Pr",
    "name": "Brown & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Prism"]
  },
  {
    "id": 1493,
    "newID": "R+B+Br+Pr",
    "name": "Brown & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Brown", "Prism"]
  },
  {
    "id": 1494,
    "newID": "R+Br+Pr",
    "name": "Brown & Prism (3 Suits)",
    "suits": ["Red", "Brown", "Prism"]
  },
  {
    "id": 85,
    "newID": "R+Y+G+B+Br+DRa",
    "name": "Brown & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Dark Rainbow"]
  },
  {
    "id": 86,
    "newID": "R+G+B+Br+DRa",
    "name": "Brown & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Dark Rainbow"]
  },
  {
    "id": 144,
    "newID": "R+Y+G+B+Br+DPi",
    "name": "Brown & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Dark Pink"]
  },
  {
    "id": 145,
    "newID": "R+G+B+Br+DPi",
    "name": "Brown & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Dark Pink"]
  },
  {
    "id": 87,
    "newID": "R+Y+G+B+Br+DWh",
    "name": "Brown & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Gray"]
  },
  {
    "id": 88,
    "newID": "R+G+B+Br+DWh",
    "name": "Brown & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Gray"]
  },
  {
    "id": 251,
    "newID": "R+Y+G+B+Br+DOm",
    "name": "Brown & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Dark Omni"]
  },
  {
    "id": 252,
    "newID": "R+G+B+Br+DOm",
    "name": "Brown & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Dark Omni"]
  },
  {
    "id": 264,
    "newID": "R+Y+G+B+Br+DNu",
    "name": "Brown & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Dark Null"]
  },
  {
    "id": 265,
    "newID": "R+G+B+Br+DNu",
    "name": "Brown & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Dark Null"]
  },
  {
    "id": 831,
    "newID": "R+Y+G+B+Br+DBrRa",
    "name": "Brown & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Cocoa Rainbow"]
  },
  {
    "id": 832,
    "newID": "R+G+B+Br+DBrRa",
    "name": "Brown & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Cocoa Rainbow"]
  },
  {
    "id": 844,
    "newID": "R+Y+G+B+Br+DWhPi",
    "name": "Brown & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Gray Pink"]
  },
  {
    "id": 845,
    "newID": "R+G+B+Br+DWhPi",
    "name": "Brown & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Gray Pink"]
  },
  {
    "id": 1495,
    "newID": "R+Y+G+B+Br+DPr",
    "name": "Brown & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown", "Dark Prism"]
  },
  {
    "id": 1496,
    "newID": "R+G+B+Br+DPr",
    "name": "Brown & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown", "Dark Prism"]
  },
  {
    "id": 222,
    "newID": "R+Y+G+B+Om+Nu",
    "name": "Omni & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Null"]
  },
  {
    "id": 223,
    "newID": "R+G+B+Om+Nu",
    "name": "Omni & Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Null"]
  },
  {
    "id": 224,
    "newID": "R+B+Om+Nu",
    "name": "Omni & Null (4 Suits)",
    "suits": ["Red", "Blue", "Omni", "Null"]
  },
  {
    "id": 225,
    "newID": "R+Om+Nu",
    "name": "Omni & Null (3 Suits)",
    "suits": ["Red", "Omni", "Null"]
  },
  {
    "id": 772,
    "newID": "R+Y+G+B+Om+BrRa",
    "name": "Omni & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Muddy Rainbow"]
  },
  {
    "id": 773,
    "newID": "R+G+B+Om+BrRa",
    "name": "Omni & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Muddy Rainbow"]
  },
  {
    "id": 774,
    "newID": "R+B+Om+BrRa",
    "name": "Omni & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Blue", "Omni", "Muddy Rainbow"]
  },
  {
    "id": 775,
    "newID": "R+Om+BrRa",
    "name": "Omni & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Omni", "Muddy Rainbow"]
  },
  {
    "id": 794,
    "newID": "R+Y+G+B+Om+WhPi",
    "name": "Omni & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Light Pink"]
  },
  {
    "id": 795,
    "newID": "R+G+B+Om+WhPi",
    "name": "Omni & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Light Pink"]
  },
  {
    "id": 796,
    "newID": "R+B+Om+WhPi",
    "name": "Omni & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "Omni", "Light Pink"]
  },
  {
    "id": 797,
    "newID": "R+Om+WhPi",
    "name": "Omni & Light Pink (3 Suits)",
    "suits": ["Red", "Omni", "Light Pink"]
  },
  {
    "id": 1497,
    "newID": "R+Y+G+B+Om+Pr",
    "name": "Omni & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Prism"]
  },
  {
    "id": 1498,
    "newID": "R+G+B+Om+Pr",
    "name": "Omni & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Prism"]
  },
  {
    "id": 1499,
    "newID": "R+B+Om+Pr",
    "name": "Omni & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Omni", "Prism"]
  },
  {
    "id": 226,
    "newID": "R+Y+G+B+Om+DRa",
    "name": "Omni & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Dark Rainbow"]
  },
  {
    "id": 227,
    "newID": "R+G+B+Om+DRa",
    "name": "Omni & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Dark Rainbow"]
  },
  {
    "id": 230,
    "newID": "R+Y+G+B+Om+DPi",
    "name": "Omni & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Dark Pink"]
  },
  {
    "id": 231,
    "newID": "R+G+B+Om+DPi",
    "name": "Omni & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Dark Pink"]
  },
  {
    "id": 234,
    "newID": "R+Y+G+B+Om+DWh",
    "name": "Omni & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Gray"]
  },
  {
    "id": 235,
    "newID": "R+G+B+Om+DWh",
    "name": "Omni & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Gray"]
  },
  {
    "id": 238,
    "newID": "R+Y+G+B+Om+DBr",
    "name": "Omni & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Dark Brown"]
  },
  {
    "id": 239,
    "newID": "R+G+B+Om+DBr",
    "name": "Omni & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Dark Brown"]
  },
  {
    "id": 266,
    "newID": "R+Y+G+B+Om+DNu",
    "name": "Omni & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Dark Null"]
  },
  {
    "id": 267,
    "newID": "R+G+B+Om+DNu",
    "name": "Omni & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Dark Null"]
  },
  {
    "id": 833,
    "newID": "R+Y+G+B+Om+DBrRa",
    "name": "Omni & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Cocoa Rainbow"]
  },
  {
    "id": 834,
    "newID": "R+G+B+Om+DBrRa",
    "name": "Omni & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Cocoa Rainbow"]
  },
  {
    "id": 846,
    "newID": "R+Y+G+B+Om+DWhPi",
    "name": "Omni & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Gray Pink"]
  },
  {
    "id": 847,
    "newID": "R+G+B+Om+DWhPi",
    "name": "Omni & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Gray Pink"]
  },
  {
    "id": 1501,
    "newID": "R+Y+G+B+Om+DPr",
    "name": "Omni & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni", "Dark Prism"]
  },
  {
    "id": 1502,
    "newID": "R+G+B+Om+DPr",
    "name": "Omni & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni", "Dark Prism"]
  },
  {
    "id": 776,
    "newID": "R+Y+G+B+Nu+BrRa",
    "name": "Null & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Muddy Rainbow"]
  },
  {
    "id": 777,
    "newID": "R+G+B+Nu+BrRa",
    "name": "Null & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Muddy Rainbow"]
  },
  {
    "id": 778,
    "newID": "R+B+Nu+BrRa",
    "name": "Null & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Blue", "Null", "Muddy Rainbow"]
  },
  {
    "id": 779,
    "newID": "R+Nu+BrRa",
    "name": "Null & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Null", "Muddy Rainbow"]
  },
  {
    "id": 798,
    "newID": "R+Y+G+B+Nu+WhPi",
    "name": "Null & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Light Pink"]
  },
  {
    "id": 799,
    "newID": "R+G+B+Nu+WhPi",
    "name": "Null & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Light Pink"]
  },
  {
    "id": 800,
    "newID": "R+B+Nu+WhPi",
    "name": "Null & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "Null", "Light Pink"]
  },
  {
    "id": 801,
    "newID": "R+Nu+WhPi",
    "name": "Null & Light Pink (3 Suits)",
    "suits": ["Red", "Null", "Light Pink"]
  },
  {
    "id": 1503,
    "newID": "R+Y+G+B+Nu+Pr",
    "name": "Null & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Prism"]
  },
  {
    "id": 1504,
    "newID": "R+G+B+Nu+Pr",
    "name": "Null & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Prism"]
  },
  {
    "id": 1505,
    "newID": "R+B+Nu+Pr",
    "name": "Null & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Null", "Prism"]
  },
  {
    "id": 228,
    "newID": "R+Y+G+B+Nu+DRa",
    "name": "Null & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Dark Rainbow"]
  },
  {
    "id": 229,
    "newID": "R+G+B+Nu+DRa",
    "name": "Null & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Dark Rainbow"]
  },
  {
    "id": 232,
    "newID": "R+Y+G+B+Nu+DPi",
    "name": "Null & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Dark Pink"]
  },
  {
    "id": 233,
    "newID": "R+G+B+Nu+DPi",
    "name": "Null & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Dark Pink"]
  },
  {
    "id": 236,
    "newID": "R+Y+G+B+Nu+DWh",
    "name": "Null & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Gray"]
  },
  {
    "id": 237,
    "newID": "R+G+B+Nu+DWh",
    "name": "Null & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Gray"]
  },
  {
    "id": 240,
    "newID": "R+Y+G+B+Nu+DBr",
    "name": "Null & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Dark Brown"]
  },
  {
    "id": 241,
    "newID": "R+G+B+Nu+DBr",
    "name": "Null & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Dark Brown"]
  },
  {
    "id": 253,
    "newID": "R+Y+G+B+Nu+DOm",
    "name": "Null & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Dark Omni"]
  },
  {
    "id": 254,
    "newID": "R+G+B+Nu+DOm",
    "name": "Null & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Dark Omni"]
  },
  {
    "id": 835,
    "newID": "R+Y+G+B+Nu+DBrRa",
    "name": "Null & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Cocoa Rainbow"]
  },
  {
    "id": 836,
    "newID": "R+G+B+Nu+DBrRa",
    "name": "Null & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Cocoa Rainbow"]
  },
  {
    "id": 848,
    "newID": "R+Y+G+B+Nu+DWhPi",
    "name": "Null & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Gray Pink"]
  },
  {
    "id": 849,
    "newID": "R+G+B+Nu+DWhPi",
    "name": "Null & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Gray Pink"]
  },
  {
    "id": 1507,
    "newID": "R+Y+G+B+Nu+DPr",
    "name": "Null & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null", "Dark Prism"]
  },
  {
    "id": 1508,
    "newID": "R+G+B+Nu+DPr",
    "name": "Null & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Null", "Dark Prism"]
  },
  {
    "id": 802,
    "newID": "R+Y+G+B+BrRa+WhPi",
    "name": "Muddy Rainbow & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Light Pink"]
  },
  {
    "id": 803,
    "newID": "R+G+B+BrRa+WhPi",
    "name": "Muddy Rainbow & Light Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Light Pink"]
  },
  {
    "id": 804,
    "newID": "R+B+BrRa+WhPi",
    "name": "Muddy Rainbow & Light Pink (4 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow", "Light Pink"]
  },
  {
    "id": 805,
    "newID": "R+BrRa+WhPi",
    "name": "Muddy Rainbow & Light Pink (3 Suits)",
    "suits": ["Red", "Muddy Rainbow", "Light Pink"]
  },
  {
    "id": 1509,
    "newID": "R+Y+G+B+BrRa+Pr",
    "name": "Muddy Rainbow & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Prism"]
  },
  {
    "id": 1510,
    "newID": "R+G+B+BrRa+Pr",
    "name": "Muddy Rainbow & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Prism"]
  },
  {
    "id": 1511,
    "newID": "R+B+BrRa+Pr",
    "name": "Muddy Rainbow & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow", "Prism"]
  },
  {
    "id": 1304,
    "newID": "R+Y+G+B+BrRa+DRa",
    "name": "Muddy Rainbow & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Dark Rainbow"]
  },
  {
    "id": 1305,
    "newID": "R+G+B+BrRa+DRa",
    "name": "Muddy Rainbow & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Dark Rainbow"]
  },
  {
    "id": 808,
    "newID": "R+Y+G+B+BrRa+DPi",
    "name": "Muddy Rainbow & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Dark Pink"]
  },
  {
    "id": 809,
    "newID": "R+G+B+BrRa+DPi",
    "name": "Muddy Rainbow & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Dark Pink"]
  },
  {
    "id": 810,
    "newID": "R+Y+G+B+BrRa+DWh",
    "name": "Muddy Rainbow & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Gray"]
  },
  {
    "id": 811,
    "newID": "R+G+B+BrRa+DWh",
    "name": "Muddy Rainbow & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Gray"]
  },
  {
    "id": 814,
    "newID": "R+Y+G+B+BrRa+DBr",
    "name": "Muddy Rainbow & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Dark Brown"]
  },
  {
    "id": 815,
    "newID": "R+G+B+BrRa+DBr",
    "name": "Muddy Rainbow & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Dark Brown"]
  },
  {
    "id": 818,
    "newID": "R+Y+G+B+BrRa+DOm",
    "name": "Muddy Rainbow & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Dark Omni"]
  },
  {
    "id": 819,
    "newID": "R+G+B+BrRa+DOm",
    "name": "Muddy Rainbow & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Dark Omni"]
  },
  {
    "id": 822,
    "newID": "R+Y+G+B+BrRa+DNu",
    "name": "Muddy Rainbow & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Dark Null"]
  },
  {
    "id": 823,
    "newID": "R+G+B+BrRa+DNu",
    "name": "Muddy Rainbow & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Dark Null"]
  },
  {
    "id": 850,
    "newID": "R+Y+G+B+BrRa+DWhPi",
    "name": "Muddy Rainbow & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Gray Pink"]
  },
  {
    "id": 851,
    "newID": "R+G+B+BrRa+DWhPi",
    "name": "Muddy Rainbow & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Gray Pink"]
  },
  {
    "id": 1513,
    "newID": "R+Y+G+B+BrRa+DPr",
    "name": "Muddy Rainbow & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow", "Dark Prism"]
  },
  {
    "id": 1514,
    "newID": "R+G+B+BrRa+DPr",
    "name": "Muddy Rainbow & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow", "Dark Prism"]
  },
  {
    "id": 1515,
    "newID": "R+Y+G+B+WhPi+Pr",
    "name": "Light Pink & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Prism"]
  },
  {
    "id": 1516,
    "newID": "R+G+B+WhPi+Pr",
    "name": "Light Pink & Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Prism"]
  },
  {
    "id": 1517,
    "newID": "R+B+WhPi+Pr",
    "name": "Light Pink & Prism (4 Suits)",
    "suits": ["Red", "Blue", "Light Pink", "Prism"]
  },
  {
    "id": 806,
    "newID": "R+Y+G+B+WhPi+DRa",
    "name": "Light Pink & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Dark Rainbow"]
  },
  {
    "id": 807,
    "newID": "R+G+B+WhPi+DRa",
    "name": "Light Pink & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Dark Rainbow"]
  },
  {
    "id": 1306,
    "newID": "R+Y+G+B+WhPi+DPi",
    "name": "Light Pink & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Dark Pink"]
  },
  {
    "id": 1307,
    "newID": "R+G+B+WhPi+DPi",
    "name": "Light Pink & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Dark Pink"]
  },
  {
    "id": 812,
    "newID": "R+Y+G+B+WhPi+DWh",
    "name": "Light Pink & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Gray"]
  },
  {
    "id": 813,
    "newID": "R+G+B+WhPi+DWh",
    "name": "Light Pink & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Gray"]
  },
  {
    "id": 816,
    "newID": "R+Y+G+B+WhPi+DBr",
    "name": "Light Pink & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Dark Brown"]
  },
  {
    "id": 817,
    "newID": "R+G+B+WhPi+DBr",
    "name": "Light Pink & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Dark Brown"]
  },
  {
    "id": 820,
    "newID": "R+Y+G+B+WhPi+DOm",
    "name": "Light Pink & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Dark Omni"]
  },
  {
    "id": 821,
    "newID": "R+G+B+WhPi+DOm",
    "name": "Light Pink & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Dark Omni"]
  },
  {
    "id": 824,
    "newID": "R+Y+G+B+WhPi+DNu",
    "name": "Light Pink & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Dark Null"]
  },
  {
    "id": 825,
    "newID": "R+G+B+WhPi+DNu",
    "name": "Light Pink & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Dark Null"]
  },
  {
    "id": 837,
    "newID": "R+Y+G+B+WhPi+DBrRa",
    "name": "Light Pink & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Cocoa Rainbow"]
  },
  {
    "id": 838,
    "newID": "R+G+B+WhPi+DBrRa",
    "name": "Light Pink & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Cocoa Rainbow"]
  },
  {
    "id": 1519,
    "newID": "R+Y+G+B+WhPi+DPr",
    "name": "Light Pink & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink", "Dark Prism"]
  },
  {
    "id": 1520,
    "newID": "R+G+B+WhPi+DPr",
    "name": "Light Pink & Dark Prism (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink", "Dark Prism"]
  },
  {
    "id": 1521,
    "newID": "R+Y+G+B+Pr+DRa",
    "name": "Prism & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Dark Rainbow"]
  },
  {
    "id": 1522,
    "newID": "R+G+B+Pr+DRa",
    "name": "Prism & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Dark Rainbow"]
  },
  {
    "id": 1523,
    "newID": "R+Y+G+B+Pr+DPi",
    "name": "Prism & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Dark Pink"]
  },
  {
    "id": 1524,
    "newID": "R+G+B+Pr+DPi",
    "name": "Prism & Dark Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Dark Pink"]
  },
  {
    "id": 1525,
    "newID": "R+Y+G+B+Pr+DWh",
    "name": "Prism & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Gray"]
  },
  {
    "id": 1526,
    "newID": "R+G+B+Pr+DWh",
    "name": "Prism & Gray (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Gray"]
  },
  {
    "id": 1527,
    "newID": "R+Y+G+B+Pr+DBr",
    "name": "Prism & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Dark Brown"]
  },
  {
    "id": 1528,
    "newID": "R+G+B+Pr+DBr",
    "name": "Prism & Dark Brown (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Dark Brown"]
  },
  {
    "id": 1529,
    "newID": "R+Y+G+B+Pr+DOm",
    "name": "Prism & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Dark Omni"]
  },
  {
    "id": 1530,
    "newID": "R+G+B+Pr+DOm",
    "name": "Prism & Dark Omni (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Dark Omni"]
  },
  {
    "id": 1531,
    "newID": "R+Y+G+B+Pr+DNu",
    "name": "Prism & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Dark Null"]
  },
  {
    "id": 1532,
    "newID": "R+G+B+Pr+DNu",
    "name": "Prism & Dark Null (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Dark Null"]
  },
  {
    "id": 1533,
    "newID": "R+Y+G+B+Pr+DBrRa",
    "name": "Prism & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Cocoa Rainbow"]
  },
  {
    "id": 1534,
    "newID": "R+G+B+Pr+DBrRa",
    "name": "Prism & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Cocoa Rainbow"]
  },
  {
    "id": 1535,
    "newID": "R+Y+G+B+Pr+DWhPi",
    "name": "Prism & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism", "Gray Pink"]
  },
  {
    "id": 1536,
    "newID": "R+G+B+Pr+DWhPi",
    "name": "Prism & Gray Pink (5 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism", "Gray Pink"]
  },
  {
    "id": 146,
    "newID": "R+Y+G+B+DRa+DPi",
    "name": "Dark Rainbow & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Dark Pink"]
  },
  {
    "id": 62,
    "newID": "R+Y+G+B+DRa+DWh",
    "name": "Dark Rainbow & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Gray"]
  },
  {
    "id": 96,
    "newID": "R+Y+G+B+DRa+DBr",
    "name": "Dark Rainbow & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Dark Brown"]
  },
  {
    "id": 268,
    "newID": "R+Y+G+B+DRa+DOm",
    "name": "Dark Rainbow & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Dark Omni"]
  },
  {
    "id": 269,
    "newID": "R+Y+G+B+DRa+DNu",
    "name": "Dark Rainbow & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Dark Null"]
  },
  {
    "id": 1308,
    "newID": "R+Y+G+B+DRa+DBrRa",
    "name": "Dark Rainbow & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Cocoa Rainbow"]
  },
  {
    "id": 852,
    "newID": "R+Y+G+B+DRa+DWhPi",
    "name": "Dark Rainbow & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Gray Pink"]
  },
  {
    "id": 1537,
    "newID": "R+Y+G+B+DRa+DPr",
    "name": "Dark Rainbow & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow", "Dark Prism"]
  },
  {
    "id": 147,
    "newID": "R+Y+G+B+DPi+DWh",
    "name": "Dark Pink & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Gray"]
  },
  {
    "id": 148,
    "newID": "R+Y+G+B+DPi+DBr",
    "name": "Dark Pink & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Dark Brown"]
  },
  {
    "id": 270,
    "newID": "R+Y+G+B+DPi+DOm",
    "name": "Dark Pink & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Dark Omni"]
  },
  {
    "id": 271,
    "newID": "R+Y+G+B+DPi+DNu",
    "name": "Dark Pink & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Dark Null"]
  },
  {
    "id": 853,
    "newID": "R+Y+G+B+DPi+DBrRa",
    "name": "Dark Pink & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Cocoa Rainbow"]
  },
  {
    "id": 1309,
    "newID": "R+Y+G+B+DPi+DWhPi",
    "name": "Dark Pink & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Gray Pink"]
  },
  {
    "id": 1538,
    "newID": "R+Y+G+B+DPi+DPr",
    "name": "Dark Pink & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink", "Dark Prism"]
  },
  {
    "id": 97,
    "newID": "R+Y+G+B+DWh+DBr",
    "name": "Gray & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray", "Dark Brown"]
  },
  {
    "id": 272,
    "newID": "R+Y+G+B+DWh+DOm",
    "name": "Gray & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray", "Dark Omni"]
  },
  {
    "id": 273,
    "newID": "R+Y+G+B+DWh+DNu",
    "name": "Gray & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray", "Dark Null"]
  },
  {
    "id": 854,
    "newID": "R+Y+G+B+DWh+DBrRa",
    "name": "Gray & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray", "Cocoa Rainbow"]
  },
  {
    "id": 855,
    "newID": "R+Y+G+B+DWh+DWhPi",
    "name": "Gray & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray", "Gray Pink"]
  },
  {
    "id": 1539,
    "newID": "R+Y+G+B+DWh+DPr",
    "name": "Gray & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray", "Dark Prism"]
  },
  {
    "id": 274,
    "newID": "R+Y+G+B+DBr+DOm",
    "name": "Dark Brown & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown", "Dark Omni"]
  },
  {
    "id": 275,
    "newID": "R+Y+G+B+DBr+DNu",
    "name": "Dark Brown & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown", "Dark Null"]
  },
  {
    "id": 856,
    "newID": "R+Y+G+B+DBr+DBrRa",
    "name": "Dark Brown & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown", "Cocoa Rainbow"]
  },
  {
    "id": 857,
    "newID": "R+Y+G+B+DBr+DWhPi",
    "name": "Dark Brown & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown", "Gray Pink"]
  },
  {
    "id": 1540,
    "newID": "R+Y+G+B+DBr+DPr",
    "name": "Dark Brown & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown", "Dark Prism"]
  },
  {
    "id": 276,
    "newID": "R+Y+G+B+DOm+DNu",
    "name": "Dark Omni & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni", "Dark Null"]
  },
  {
    "id": 858,
    "newID": "R+Y+G+B+DOm+DBrRa",
    "name": "Dark Omni & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni", "Cocoa Rainbow"]
  },
  {
    "id": 859,
    "newID": "R+Y+G+B+DOm+DWhPi",
    "name": "Dark Omni & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni", "Gray Pink"]
  },
  {
    "id": 1541,
    "newID": "R+Y+G+B+DOm+DPr",
    "name": "Dark Omni & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni", "Dark Prism"]
  },
  {
    "id": 860,
    "newID": "R+Y+G+B+DNu+DBrRa",
    "name": "Dark Null & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null", "Cocoa Rainbow"]
  },
  {
    "id": 861,
    "newID": "R+Y+G+B+DNu+DWhPi",
    "name": "Dark Null & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null", "Gray Pink"]
  },
  {
    "id": 1542,
    "newID": "R+Y+G+B+DNu+DPr",
    "name": "Dark Null & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null", "Dark Prism"]
  },
  {
    "id": 862,
    "newID": "R+Y+G+B+DBrRa+DWhPi",
    "name": "Cocoa Rainbow & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow", "Gray Pink"]
  },
  {
    "id": 1543,
    "newID": "R+Y+G+B+DBrRa+DPr",
    "name": "Cocoa Rainbow & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow", "Dark Prism"]
  },
  {
    "id": 1544,
    "newID": "R+Y+G+B+DWhPi+DPr",
    "name": "Gray Pink & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink", "Dark Prism"]
  },
  {
    "id": 168,
    "newID": "R+Y+G+B+P+T,R1",
    "name": "Rainbow-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 169,
    "newID": "R+Y+G+B+P,R1",
    "name": "Rainbow-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 170,
    "newID": "R+Y+G+B,R1",
    "name": "Rainbow-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 171,
    "newID": "R+G+B,R1",
    "name": "Rainbow-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1310,
    "newID": "R+Y+G+B+P+Bk,R1",
    "name": "Rainbow-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1311,
    "newID": "R+Y+G+B+Bk,R1",
    "name": "Rainbow-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 294,
    "newID": "R+Y+G+B+P+Ra,R1",
    "name": "Rainbow-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 295,
    "newID": "R+Y+G+B+Ra,R1",
    "name": "Rainbow-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 296,
    "newID": "R+G+B+Ra,R1",
    "name": "Rainbow-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 297,
    "newID": "R+B+Ra,R1",
    "name": "Rainbow-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 172,
    "newID": "R+Y+G+B+P+Pi,R1",
    "name": "Rainbow-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 173,
    "newID": "R+Y+G+B+Pi,R1",
    "name": "Rainbow-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 174,
    "newID": "R+G+B+Pi,R1",
    "name": "Rainbow-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 175,
    "newID": "R+B+Pi,R1",
    "name": "Rainbow-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 298,
    "newID": "R+Y+G+B+P+Wh,R1",
    "name": "Rainbow-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 299,
    "newID": "R+Y+G+B+Wh,R1",
    "name": "Rainbow-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 300,
    "newID": "R+G+B+Wh,R1",
    "name": "Rainbow-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 301,
    "newID": "R+B+Wh,R1",
    "name": "Rainbow-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 302,
    "newID": "R+Y+G+B+P+Br,R1",
    "name": "Rainbow-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 303,
    "newID": "R+Y+G+B+Br,R1",
    "name": "Rainbow-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 304,
    "newID": "R+G+B+Br,R1",
    "name": "Rainbow-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 305,
    "newID": "R+B+Br,R1",
    "name": "Rainbow-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 306,
    "newID": "R+Y+G+B+P+Om,R1",
    "name": "Rainbow-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 307,
    "newID": "R+Y+G+B+Om,R1",
    "name": "Rainbow-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 308,
    "newID": "R+G+B+Om,R1",
    "name": "Rainbow-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 309,
    "newID": "R+B+Om,R1",
    "name": "Rainbow-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 310,
    "newID": "R+Y+G+B+P+Nu,R1",
    "name": "Rainbow-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 311,
    "newID": "R+Y+G+B+Nu,R1",
    "name": "Rainbow-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 312,
    "newID": "R+G+B+Nu,R1",
    "name": "Rainbow-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 313,
    "newID": "R+B+Nu,R1",
    "name": "Rainbow-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 867,
    "newID": "R+Y+G+B+P+BrRa,R1",
    "name": "Rainbow-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 868,
    "newID": "R+Y+G+B+BrRa,R1",
    "name": "Rainbow-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 869,
    "newID": "R+G+B+BrRa,R1",
    "name": "Rainbow-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 870,
    "newID": "R+B+BrRa,R1",
    "name": "Rainbow-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 871,
    "newID": "R+Y+G+B+P+WhPi,R1",
    "name": "Rainbow-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 872,
    "newID": "R+Y+G+B+WhPi,R1",
    "name": "Rainbow-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 873,
    "newID": "R+G+B+WhPi,R1",
    "name": "Rainbow-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 874,
    "newID": "R+B+WhPi,R1",
    "name": "Rainbow-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1545,
    "newID": "R+Y+G+B+P+Pr,R1",
    "name": "Rainbow-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1546,
    "newID": "R+Y+G+B+Pr,R1",
    "name": "Rainbow-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1547,
    "newID": "R+G+B+Pr,R1",
    "name": "Rainbow-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1548,
    "newID": "R+B+Pr,R1",
    "name": "Rainbow-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 314,
    "newID": "R+Y+G+B+P+DRa,R1",
    "name": "Rainbow-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 315,
    "newID": "R+Y+G+B+DRa,R1",
    "name": "Rainbow-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 316,
    "newID": "R+Y+G+B+P+DPi,R1",
    "name": "Rainbow-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 317,
    "newID": "R+Y+G+B+DPi,R1",
    "name": "Rainbow-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 318,
    "newID": "R+Y+G+B+P+DWh,R1",
    "name": "Rainbow-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 319,
    "newID": "R+Y+G+B+DWh,R1",
    "name": "Rainbow-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 320,
    "newID": "R+Y+G+B+P+DBr,R1",
    "name": "Rainbow-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 321,
    "newID": "R+Y+G+B+DBr,R1",
    "name": "Rainbow-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 322,
    "newID": "R+Y+G+B+P+DOm,R1",
    "name": "Rainbow-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 323,
    "newID": "R+Y+G+B+DOm,R1",
    "name": "Rainbow-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 324,
    "newID": "R+Y+G+B+P+DNu,R1",
    "name": "Rainbow-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 325,
    "newID": "R+Y+G+B+DNu,R1",
    "name": "Rainbow-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 875,
    "newID": "R+Y+G+B+P+DBrRa,R1",
    "name": "Rainbow-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 876,
    "newID": "R+Y+G+B+DBrRa,R1",
    "name": "Rainbow-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 877,
    "newID": "R+Y+G+B+P+DWhPi,R1",
    "name": "Rainbow-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 878,
    "newID": "R+Y+G+B+DWhPi,R1",
    "name": "Rainbow-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1549,
    "newID": "R+Y+G+B+P+DPr,R1",
    "name": "Rainbow-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 1550,
    "newID": "R+Y+G+B+DPr,R1",
    "name": "Rainbow-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true
  },
  {
    "id": 326,
    "newID": "R+Y+G+B+P+T,P1",
    "name": "Pink-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 327,
    "newID": "R+Y+G+B+P,P1",
    "name": "Pink-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 328,
    "newID": "R+Y+G+B,P1",
    "name": "Pink-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 329,
    "newID": "R+G+B,P1",
    "name": "Pink-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1312,
    "newID": "R+Y+G+B+P+Bk,P1",
    "name": "Pink-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1313,
    "newID": "R+Y+G+B+Bk,P1",
    "name": "Pink-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 330,
    "newID": "R+Y+G+B+P+Ra,P1",
    "name": "Pink-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 331,
    "newID": "R+Y+G+B+Ra,P1",
    "name": "Pink-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 332,
    "newID": "R+G+B+Ra,P1",
    "name": "Pink-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 333,
    "newID": "R+B+Ra,P1",
    "name": "Pink-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 334,
    "newID": "R+Y+G+B+P+Pi,P1",
    "name": "Pink-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 335,
    "newID": "R+Y+G+B+Pi,P1",
    "name": "Pink-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 336,
    "newID": "R+G+B+Pi,P1",
    "name": "Pink-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 337,
    "newID": "R+B+Pi,P1",
    "name": "Pink-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 338,
    "newID": "R+Y+G+B+P+Wh,P1",
    "name": "Pink-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 339,
    "newID": "R+Y+G+B+Wh,P1",
    "name": "Pink-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 340,
    "newID": "R+G+B+Wh,P1",
    "name": "Pink-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 341,
    "newID": "R+B+Wh,P1",
    "name": "Pink-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 342,
    "newID": "R+Y+G+B+P+Br,P1",
    "name": "Pink-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 343,
    "newID": "R+Y+G+B+Br,P1",
    "name": "Pink-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 344,
    "newID": "R+G+B+Br,P1",
    "name": "Pink-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 345,
    "newID": "R+B+Br,P1",
    "name": "Pink-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 346,
    "newID": "R+Y+G+B+P+Om,P1",
    "name": "Pink-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 347,
    "newID": "R+Y+G+B+Om,P1",
    "name": "Pink-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 348,
    "newID": "R+G+B+Om,P1",
    "name": "Pink-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 349,
    "newID": "R+B+Om,P1",
    "name": "Pink-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 350,
    "newID": "R+Y+G+B+P+Nu,P1",
    "name": "Pink-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 351,
    "newID": "R+Y+G+B+Nu,P1",
    "name": "Pink-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 352,
    "newID": "R+G+B+Nu,P1",
    "name": "Pink-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 353,
    "newID": "R+B+Nu,P1",
    "name": "Pink-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 879,
    "newID": "R+Y+G+B+P+BrRa,P1",
    "name": "Pink-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 880,
    "newID": "R+Y+G+B+BrRa,P1",
    "name": "Pink-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 881,
    "newID": "R+G+B+BrRa,P1",
    "name": "Pink-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 882,
    "newID": "R+B+BrRa,P1",
    "name": "Pink-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 883,
    "newID": "R+Y+G+B+P+WhPi,P1",
    "name": "Pink-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 884,
    "newID": "R+Y+G+B+WhPi,P1",
    "name": "Pink-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 885,
    "newID": "R+G+B+WhPi,P1",
    "name": "Pink-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 886,
    "newID": "R+B+WhPi,P1",
    "name": "Pink-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1551,
    "newID": "R+Y+G+B+P+Pr,P1",
    "name": "Pink-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1552,
    "newID": "R+Y+G+B+Pr,P1",
    "name": "Pink-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1553,
    "newID": "R+G+B+Pr,P1",
    "name": "Pink-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1554,
    "newID": "R+B+Pr,P1",
    "name": "Pink-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 354,
    "newID": "R+Y+G+B+P+DRa,P1",
    "name": "Pink-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 355,
    "newID": "R+Y+G+B+DRa,P1",
    "name": "Pink-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 356,
    "newID": "R+Y+G+B+P+DPi,P1",
    "name": "Pink-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 357,
    "newID": "R+Y+G+B+DPi,P1",
    "name": "Pink-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 358,
    "newID": "R+Y+G+B+P+DWh,P1",
    "name": "Pink-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 359,
    "newID": "R+Y+G+B+DWh,P1",
    "name": "Pink-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 360,
    "newID": "R+Y+G+B+P+DBr,P1",
    "name": "Pink-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 361,
    "newID": "R+Y+G+B+DBr,P1",
    "name": "Pink-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 362,
    "newID": "R+Y+G+B+P+DOm,P1",
    "name": "Pink-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 363,
    "newID": "R+Y+G+B+DOm,P1",
    "name": "Pink-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 364,
    "newID": "R+Y+G+B+P+DNu,P1",
    "name": "Pink-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 365,
    "newID": "R+Y+G+B+DNu,P1",
    "name": "Pink-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 887,
    "newID": "R+Y+G+B+P+DBrRa,P1",
    "name": "Pink-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 888,
    "newID": "R+Y+G+B+DBrRa,P1",
    "name": "Pink-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 889,
    "newID": "R+Y+G+B+P+DWhPi,P1",
    "name": "Pink-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 890,
    "newID": "R+Y+G+B+DWhPi,P1",
    "name": "Pink-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1555,
    "newID": "R+Y+G+B+P+DPr,P1",
    "name": "Pink-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1556,
    "newID": "R+Y+G+B+DPr,P1",
    "name": "Pink-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 366,
    "newID": "R+Y+G+B+P+T,W1",
    "name": "White-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 367,
    "newID": "R+Y+G+B+P,W1",
    "name": "White-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 368,
    "newID": "R+Y+G+B,W1",
    "name": "White-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 369,
    "newID": "R+G+B,W1",
    "name": "White-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1314,
    "newID": "R+Y+G+B+P+Bk,W1",
    "name": "White-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1315,
    "newID": "R+Y+G+B+Bk,W1",
    "name": "White-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 370,
    "newID": "R+Y+G+B+P+Ra,W1",
    "name": "White-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 371,
    "newID": "R+Y+G+B+Ra,W1",
    "name": "White-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 372,
    "newID": "R+G+B+Ra,W1",
    "name": "White-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 373,
    "newID": "R+B+Ra,W1",
    "name": "White-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 374,
    "newID": "R+Y+G+B+P+Pi,W1",
    "name": "White-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 375,
    "newID": "R+Y+G+B+Pi,W1",
    "name": "White-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 376,
    "newID": "R+G+B+Pi,W1",
    "name": "White-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 377,
    "newID": "R+B+Pi,W1",
    "name": "White-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 378,
    "newID": "R+Y+G+B+P+Wh,W1",
    "name": "White-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 379,
    "newID": "R+Y+G+B+Wh,W1",
    "name": "White-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 380,
    "newID": "R+G+B+Wh,W1",
    "name": "White-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 381,
    "newID": "R+B+Wh,W1",
    "name": "White-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 382,
    "newID": "R+Y+G+B+P+Br,W1",
    "name": "White-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 383,
    "newID": "R+Y+G+B+Br,W1",
    "name": "White-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 384,
    "newID": "R+G+B+Br,W1",
    "name": "White-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 385,
    "newID": "R+B+Br,W1",
    "name": "White-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 386,
    "newID": "R+Y+G+B+P+Om,W1",
    "name": "White-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 387,
    "newID": "R+Y+G+B+Om,W1",
    "name": "White-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 388,
    "newID": "R+G+B+Om,W1",
    "name": "White-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 389,
    "newID": "R+B+Om,W1",
    "name": "White-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 390,
    "newID": "R+Y+G+B+P+Nu,W1",
    "name": "White-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 391,
    "newID": "R+Y+G+B+Nu,W1",
    "name": "White-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 392,
    "newID": "R+G+B+Nu,W1",
    "name": "White-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 393,
    "newID": "R+B+Nu,W1",
    "name": "White-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 891,
    "newID": "R+Y+G+B+P+BrRa,W1",
    "name": "White-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 892,
    "newID": "R+Y+G+B+BrRa,W1",
    "name": "White-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 893,
    "newID": "R+G+B+BrRa,W1",
    "name": "White-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 894,
    "newID": "R+B+BrRa,W1",
    "name": "White-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 895,
    "newID": "R+Y+G+B+P+WhPi,W1",
    "name": "White-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 896,
    "newID": "R+Y+G+B+WhPi,W1",
    "name": "White-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 897,
    "newID": "R+G+B+WhPi,W1",
    "name": "White-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 898,
    "newID": "R+B+WhPi,W1",
    "name": "White-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1557,
    "newID": "R+Y+G+B+P+Pr,W1",
    "name": "White-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1558,
    "newID": "R+Y+G+B+Pr,W1",
    "name": "White-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1559,
    "newID": "R+G+B+Pr,W1",
    "name": "White-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1560,
    "newID": "R+B+Pr,W1",
    "name": "White-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 394,
    "newID": "R+Y+G+B+P+DRa,W1",
    "name": "White-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 395,
    "newID": "R+Y+G+B+DRa,W1",
    "name": "White-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 396,
    "newID": "R+Y+G+B+P+DPi,W1",
    "name": "White-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 397,
    "newID": "R+Y+G+B+DPi,W1",
    "name": "White-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 398,
    "newID": "R+Y+G+B+P+DWh,W1",
    "name": "White-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 399,
    "newID": "R+Y+G+B+DWh,W1",
    "name": "White-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 400,
    "newID": "R+Y+G+B+P+DBr,W1",
    "name": "White-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 401,
    "newID": "R+Y+G+B+DBr,W1",
    "name": "White-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 402,
    "newID": "R+Y+G+B+P+DOm,W1",
    "name": "White-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 403,
    "newID": "R+Y+G+B+DOm,W1",
    "name": "White-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 404,
    "newID": "R+Y+G+B+P+DNu,W1",
    "name": "White-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 405,
    "newID": "R+Y+G+B+DNu,W1",
    "name": "White-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 899,
    "newID": "R+Y+G+B+P+DBrRa,W1",
    "name": "White-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 900,
    "newID": "R+Y+G+B+DBrRa,W1",
    "name": "White-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 901,
    "newID": "R+Y+G+B+P+DWhPi,W1",
    "name": "White-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 902,
    "newID": "R+Y+G+B+DWhPi,W1",
    "name": "White-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1561,
    "newID": "R+Y+G+B+P+DPr,W1",
    "name": "White-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 1562,
    "newID": "R+Y+G+B+DPr,W1",
    "name": "White-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true
  },
  {
    "id": 406,
    "newID": "R+Y+G+B+P+T,B1",
    "name": "Brown-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 407,
    "newID": "R+Y+G+B+P,B1",
    "name": "Brown-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 408,
    "newID": "R+Y+G+B,B1",
    "name": "Brown-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 409,
    "newID": "R+G+B,B1",
    "name": "Brown-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1316,
    "newID": "R+Y+G+B+P+Bk,B1",
    "name": "Brown-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1317,
    "newID": "R+Y+G+B+Bk,B1",
    "name": "Brown-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 410,
    "newID": "R+Y+G+B+P+Ra,B1",
    "name": "Brown-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 411,
    "newID": "R+Y+G+B+Ra,B1",
    "name": "Brown-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 412,
    "newID": "R+G+B+Ra,B1",
    "name": "Brown-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 413,
    "newID": "R+B+Ra,B1",
    "name": "Brown-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 414,
    "newID": "R+Y+G+B+P+Pi,B1",
    "name": "Brown-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 415,
    "newID": "R+Y+G+B+Pi,B1",
    "name": "Brown-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 416,
    "newID": "R+G+B+Pi,B1",
    "name": "Brown-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 417,
    "newID": "R+B+Pi,B1",
    "name": "Brown-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 418,
    "newID": "R+Y+G+B+P+Wh,B1",
    "name": "Brown-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 419,
    "newID": "R+Y+G+B+Wh,B1",
    "name": "Brown-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 420,
    "newID": "R+G+B+Wh,B1",
    "name": "Brown-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 421,
    "newID": "R+B+Wh,B1",
    "name": "Brown-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 422,
    "newID": "R+Y+G+B+P+Br,B1",
    "name": "Brown-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 423,
    "newID": "R+Y+G+B+Br,B1",
    "name": "Brown-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 424,
    "newID": "R+G+B+Br,B1",
    "name": "Brown-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 425,
    "newID": "R+B+Br,B1",
    "name": "Brown-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 426,
    "newID": "R+Y+G+B+P+Om,B1",
    "name": "Brown-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 427,
    "newID": "R+Y+G+B+Om,B1",
    "name": "Brown-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 428,
    "newID": "R+G+B+Om,B1",
    "name": "Brown-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 429,
    "newID": "R+B+Om,B1",
    "name": "Brown-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 430,
    "newID": "R+Y+G+B+P+Nu,B1",
    "name": "Brown-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 431,
    "newID": "R+Y+G+B+Nu,B1",
    "name": "Brown-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 432,
    "newID": "R+G+B+Nu,B1",
    "name": "Brown-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 433,
    "newID": "R+B+Nu,B1",
    "name": "Brown-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 903,
    "newID": "R+Y+G+B+P+BrRa,B1",
    "name": "Brown-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 904,
    "newID": "R+Y+G+B+BrRa,B1",
    "name": "Brown-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 905,
    "newID": "R+G+B+BrRa,B1",
    "name": "Brown-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 906,
    "newID": "R+B+BrRa,B1",
    "name": "Brown-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 907,
    "newID": "R+Y+G+B+P+WhPi,B1",
    "name": "Brown-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 908,
    "newID": "R+Y+G+B+WhPi,B1",
    "name": "Brown-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 909,
    "newID": "R+G+B+WhPi,B1",
    "name": "Brown-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 910,
    "newID": "R+B+WhPi,B1",
    "name": "Brown-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1563,
    "newID": "R+Y+G+B+P+Pr,B1",
    "name": "Brown-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1564,
    "newID": "R+Y+G+B+Pr,B1",
    "name": "Brown-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1565,
    "newID": "R+G+B+Pr,B1",
    "name": "Brown-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1566,
    "newID": "R+B+Pr,B1",
    "name": "Brown-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 434,
    "newID": "R+Y+G+B+P+DRa,B1",
    "name": "Brown-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 435,
    "newID": "R+Y+G+B+DRa,B1",
    "name": "Brown-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 436,
    "newID": "R+Y+G+B+P+DPi,B1",
    "name": "Brown-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 437,
    "newID": "R+Y+G+B+DPi,B1",
    "name": "Brown-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 438,
    "newID": "R+Y+G+B+P+DWh,B1",
    "name": "Brown-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 439,
    "newID": "R+Y+G+B+DWh,B1",
    "name": "Brown-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 440,
    "newID": "R+Y+G+B+P+DBr,B1",
    "name": "Brown-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 441,
    "newID": "R+Y+G+B+DBr,B1",
    "name": "Brown-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 442,
    "newID": "R+Y+G+B+P+DOm,B1",
    "name": "Brown-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 443,
    "newID": "R+Y+G+B+DOm,B1",
    "name": "Brown-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 444,
    "newID": "R+Y+G+B+P+DNu,B1",
    "name": "Brown-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 445,
    "newID": "R+Y+G+B+DNu,B1",
    "name": "Brown-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 911,
    "newID": "R+Y+G+B+P+DBrRa,B1",
    "name": "Brown-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 912,
    "newID": "R+Y+G+B+DBrRa,B1",
    "name": "Brown-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 913,
    "newID": "R+Y+G+B+P+DWhPi,B1",
    "name": "Brown-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 914,
    "newID": "R+Y+G+B+DWhPi,B1",
    "name": "Brown-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1567,
    "newID": "R+Y+G+B+P+DPr,B1",
    "name": "Brown-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1568,
    "newID": "R+Y+G+B+DPr,B1",
    "name": "Brown-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 446,
    "newID": "R+Y+G+B+P+T,O1",
    "name": "Omni-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 447,
    "newID": "R+Y+G+B+P,O1",
    "name": "Omni-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 448,
    "newID": "R+Y+G+B,O1",
    "name": "Omni-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 449,
    "newID": "R+G+B,O1",
    "name": "Omni-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1320,
    "newID": "R+Y+G+B+P+Bk,O1",
    "name": "Omni-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1321,
    "newID": "R+Y+G+B+Bk,O1",
    "name": "Omni-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 450,
    "newID": "R+Y+G+B+P+Ra,O1",
    "name": "Omni-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 451,
    "newID": "R+Y+G+B+Ra,O1",
    "name": "Omni-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 452,
    "newID": "R+G+B+Ra,O1",
    "name": "Omni-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 453,
    "newID": "R+B+Ra,O1",
    "name": "Omni-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 454,
    "newID": "R+Y+G+B+P+Pi,O1",
    "name": "Omni-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 455,
    "newID": "R+Y+G+B+Pi,O1",
    "name": "Omni-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 456,
    "newID": "R+G+B+Pi,O1",
    "name": "Omni-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 457,
    "newID": "R+B+Pi,O1",
    "name": "Omni-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 458,
    "newID": "R+Y+G+B+P+Wh,O1",
    "name": "Omni-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 459,
    "newID": "R+Y+G+B+Wh,O1",
    "name": "Omni-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 460,
    "newID": "R+G+B+Wh,O1",
    "name": "Omni-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 461,
    "newID": "R+B+Wh,O1",
    "name": "Omni-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 462,
    "newID": "R+Y+G+B+P+Br,O1",
    "name": "Omni-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 463,
    "newID": "R+Y+G+B+Br,O1",
    "name": "Omni-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 464,
    "newID": "R+G+B+Br,O1",
    "name": "Omni-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 465,
    "newID": "R+B+Br,O1",
    "name": "Omni-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 466,
    "newID": "R+Y+G+B+P+Om,O1",
    "name": "Omni-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 467,
    "newID": "R+Y+G+B+Om,O1",
    "name": "Omni-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 468,
    "newID": "R+G+B+Om,O1",
    "name": "Omni-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 469,
    "newID": "R+B+Om,O1",
    "name": "Omni-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 470,
    "newID": "R+Y+G+B+P+Nu,O1",
    "name": "Omni-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 471,
    "newID": "R+Y+G+B+Nu,O1",
    "name": "Omni-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 472,
    "newID": "R+G+B+Nu,O1",
    "name": "Omni-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 473,
    "newID": "R+B+Nu,O1",
    "name": "Omni-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 915,
    "newID": "R+Y+G+B+P+BrRa,O1",
    "name": "Omni-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 916,
    "newID": "R+Y+G+B+BrRa,O1",
    "name": "Omni-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 917,
    "newID": "R+G+B+BrRa,O1",
    "name": "Omni-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 918,
    "newID": "R+B+BrRa,O1",
    "name": "Omni-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 919,
    "newID": "R+Y+G+B+P+WhPi,O1",
    "name": "Omni-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 920,
    "newID": "R+Y+G+B+WhPi,O1",
    "name": "Omni-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 921,
    "newID": "R+G+B+WhPi,O1",
    "name": "Omni-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 922,
    "newID": "R+B+WhPi,O1",
    "name": "Omni-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1569,
    "newID": "R+Y+G+B+P+Pr,O1",
    "name": "Omni-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1570,
    "newID": "R+Y+G+B+Pr,O1",
    "name": "Omni-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1571,
    "newID": "R+G+B+Pr,O1",
    "name": "Omni-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1572,
    "newID": "R+B+Pr,O1",
    "name": "Omni-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 474,
    "newID": "R+Y+G+B+P+DRa,O1",
    "name": "Omni-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 475,
    "newID": "R+Y+G+B+DRa,O1",
    "name": "Omni-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 476,
    "newID": "R+Y+G+B+P+DPi,O1",
    "name": "Omni-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 477,
    "newID": "R+Y+G+B+DPi,O1",
    "name": "Omni-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 478,
    "newID": "R+Y+G+B+P+DWh,O1",
    "name": "Omni-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 479,
    "newID": "R+Y+G+B+DWh,O1",
    "name": "Omni-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 480,
    "newID": "R+Y+G+B+P+DBr,O1",
    "name": "Omni-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 481,
    "newID": "R+Y+G+B+DBr,O1",
    "name": "Omni-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 482,
    "newID": "R+Y+G+B+P+DOm,O1",
    "name": "Omni-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 483,
    "newID": "R+Y+G+B+DOm,O1",
    "name": "Omni-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 484,
    "newID": "R+Y+G+B+P+DNu,O1",
    "name": "Omni-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 485,
    "newID": "R+Y+G+B+DNu,O1",
    "name": "Omni-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 923,
    "newID": "R+Y+G+B+P+DBrRa,O1",
    "name": "Omni-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 924,
    "newID": "R+Y+G+B+DBrRa,O1",
    "name": "Omni-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 925,
    "newID": "R+Y+G+B+P+DWhPi,O1",
    "name": "Omni-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 926,
    "newID": "R+Y+G+B+DWhPi,O1",
    "name": "Omni-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1573,
    "newID": "R+Y+G+B+P+DPr,O1",
    "name": "Omni-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1574,
    "newID": "R+Y+G+B+DPr,O1",
    "name": "Omni-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 486,
    "newID": "R+Y+G+B+P+T,N1",
    "name": "Null-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 487,
    "newID": "R+Y+G+B+P,N1",
    "name": "Null-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 488,
    "newID": "R+Y+G+B,N1",
    "name": "Null-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 489,
    "newID": "R+G+B,N1",
    "name": "Null-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1326,
    "newID": "R+Y+G+B+P+Bk,N1",
    "name": "Null-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1327,
    "newID": "R+Y+G+B+Bk,N1",
    "name": "Null-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 490,
    "newID": "R+Y+G+B+P+Ra,N1",
    "name": "Null-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 491,
    "newID": "R+Y+G+B+Ra,N1",
    "name": "Null-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 492,
    "newID": "R+G+B+Ra,N1",
    "name": "Null-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 493,
    "newID": "R+B+Ra,N1",
    "name": "Null-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 494,
    "newID": "R+Y+G+B+P+Pi,N1",
    "name": "Null-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 495,
    "newID": "R+Y+G+B+Pi,N1",
    "name": "Null-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 496,
    "newID": "R+G+B+Pi,N1",
    "name": "Null-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 497,
    "newID": "R+B+Pi,N1",
    "name": "Null-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 498,
    "newID": "R+Y+G+B+P+Wh,N1",
    "name": "Null-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 499,
    "newID": "R+Y+G+B+Wh,N1",
    "name": "Null-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 500,
    "newID": "R+G+B+Wh,N1",
    "name": "Null-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 501,
    "newID": "R+B+Wh,N1",
    "name": "Null-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 502,
    "newID": "R+Y+G+B+P+Br,N1",
    "name": "Null-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 503,
    "newID": "R+Y+G+B+Br,N1",
    "name": "Null-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 504,
    "newID": "R+G+B+Br,N1",
    "name": "Null-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 505,
    "newID": "R+B+Br,N1",
    "name": "Null-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 506,
    "newID": "R+Y+G+B+P+Om,N1",
    "name": "Null-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 507,
    "newID": "R+Y+G+B+Om,N1",
    "name": "Null-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 508,
    "newID": "R+G+B+Om,N1",
    "name": "Null-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 509,
    "newID": "R+B+Om,N1",
    "name": "Null-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 510,
    "newID": "R+Y+G+B+P+Nu,N1",
    "name": "Null-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 511,
    "newID": "R+Y+G+B+Nu,N1",
    "name": "Null-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 512,
    "newID": "R+G+B+Nu,N1",
    "name": "Null-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 513,
    "newID": "R+B+Nu,N1",
    "name": "Null-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 927,
    "newID": "R+Y+G+B+P+BrRa,N1",
    "name": "Null-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 928,
    "newID": "R+Y+G+B+BrRa,N1",
    "name": "Null-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 929,
    "newID": "R+G+B+BrRa,N1",
    "name": "Null-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 930,
    "newID": "R+B+BrRa,N1",
    "name": "Null-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 931,
    "newID": "R+Y+G+B+P+WhPi,N1",
    "name": "Null-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 932,
    "newID": "R+Y+G+B+WhPi,N1",
    "name": "Null-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 933,
    "newID": "R+G+B+WhPi,N1",
    "name": "Null-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 934,
    "newID": "R+B+WhPi,N1",
    "name": "Null-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1575,
    "newID": "R+Y+G+B+P+Pr,N1",
    "name": "Null-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1576,
    "newID": "R+Y+G+B+Pr,N1",
    "name": "Null-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1577,
    "newID": "R+G+B+Pr,N1",
    "name": "Null-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1578,
    "newID": "R+B+Pr,N1",
    "name": "Null-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 514,
    "newID": "R+Y+G+B+P+DRa,N1",
    "name": "Null-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 515,
    "newID": "R+Y+G+B+DRa,N1",
    "name": "Null-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 516,
    "newID": "R+Y+G+B+P+DPi,N1",
    "name": "Null-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 517,
    "newID": "R+Y+G+B+DPi,N1",
    "name": "Null-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 518,
    "newID": "R+Y+G+B+P+DWh,N1",
    "name": "Null-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 519,
    "newID": "R+Y+G+B+DWh,N1",
    "name": "Null-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 520,
    "newID": "R+Y+G+B+P+DBr,N1",
    "name": "Null-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 521,
    "newID": "R+Y+G+B+DBr,N1",
    "name": "Null-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 522,
    "newID": "R+Y+G+B+P+DOm,N1",
    "name": "Null-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 523,
    "newID": "R+Y+G+B+DOm,N1",
    "name": "Null-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 524,
    "newID": "R+Y+G+B+P+DNu,N1",
    "name": "Null-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 525,
    "newID": "R+Y+G+B+DNu,N1",
    "name": "Null-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 935,
    "newID": "R+Y+G+B+P+DBrRa,N1",
    "name": "Null-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 936,
    "newID": "R+Y+G+B+DBrRa,N1",
    "name": "Null-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 937,
    "newID": "R+Y+G+B+P+DWhPi,N1",
    "name": "Null-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 938,
    "newID": "R+Y+G+B+DWhPi,N1",
    "name": "Null-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1579,
    "newID": "R+Y+G+B+P+DPr,N1",
    "name": "Null-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1580,
    "newID": "R+Y+G+B+DPr,N1",
    "name": "Null-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1011,
    "newID": "R+Y+G+B+P+T,M1",
    "name": "Muddy-Rainbow-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1012,
    "newID": "R+Y+G+B+P,M1",
    "name": "Muddy-Rainbow-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1013,
    "newID": "R+Y+G+B,M1",
    "name": "Muddy-Rainbow-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1014,
    "newID": "R+G+B,M1",
    "name": "Muddy-Rainbow-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1330,
    "newID": "R+Y+G+B+P+Bk,M1",
    "name": "Muddy-Rainbow-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1331,
    "newID": "R+Y+G+B+Bk,M1",
    "name": "Muddy-Rainbow-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1015,
    "newID": "R+Y+G+B+P+Ra,M1",
    "name": "Muddy-Rainbow-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1016,
    "newID": "R+Y+G+B+Ra,M1",
    "name": "Muddy-Rainbow-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1017,
    "newID": "R+G+B+Ra,M1",
    "name": "Muddy-Rainbow-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1018,
    "newID": "R+B+Ra,M1",
    "name": "Muddy-Rainbow-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1019,
    "newID": "R+Y+G+B+P+Pi,M1",
    "name": "Muddy-Rainbow-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1020,
    "newID": "R+Y+G+B+Pi,M1",
    "name": "Muddy-Rainbow-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1021,
    "newID": "R+G+B+Pi,M1",
    "name": "Muddy-Rainbow-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1022,
    "newID": "R+B+Pi,M1",
    "name": "Muddy-Rainbow-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1023,
    "newID": "R+Y+G+B+P+Wh,M1",
    "name": "Muddy-Rainbow-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1024,
    "newID": "R+Y+G+B+Wh,M1",
    "name": "Muddy-Rainbow-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1025,
    "newID": "R+G+B+Wh,M1",
    "name": "Muddy-Rainbow-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1026,
    "newID": "R+B+Wh,M1",
    "name": "Muddy-Rainbow-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1027,
    "newID": "R+Y+G+B+P+Br,M1",
    "name": "Muddy-Rainbow-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1028,
    "newID": "R+Y+G+B+Br,M1",
    "name": "Muddy-Rainbow-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1029,
    "newID": "R+G+B+Br,M1",
    "name": "Muddy-Rainbow-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1030,
    "newID": "R+B+Br,M1",
    "name": "Muddy-Rainbow-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1031,
    "newID": "R+Y+G+B+P+Om,M1",
    "name": "Muddy-Rainbow-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1032,
    "newID": "R+Y+G+B+Om,M1",
    "name": "Muddy-Rainbow-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1033,
    "newID": "R+G+B+Om,M1",
    "name": "Muddy-Rainbow-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1034,
    "newID": "R+B+Om,M1",
    "name": "Muddy-Rainbow-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1035,
    "newID": "R+Y+G+B+P+Nu,M1",
    "name": "Muddy-Rainbow-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1036,
    "newID": "R+Y+G+B+Nu,M1",
    "name": "Muddy-Rainbow-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1037,
    "newID": "R+G+B+Nu,M1",
    "name": "Muddy-Rainbow-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1038,
    "newID": "R+B+Nu,M1",
    "name": "Muddy-Rainbow-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1039,
    "newID": "R+Y+G+B+P+BrRa,M1",
    "name": "Muddy-Rainbow-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1040,
    "newID": "R+Y+G+B+BrRa,M1",
    "name": "Muddy-Rainbow-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1041,
    "newID": "R+G+B+BrRa,M1",
    "name": "Muddy-Rainbow-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1042,
    "newID": "R+B+BrRa,M1",
    "name": "Muddy-Rainbow-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1043,
    "newID": "R+Y+G+B+P+WhPi,M1",
    "name": "Muddy-Rainbow-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1044,
    "newID": "R+Y+G+B+WhPi,M1",
    "name": "Muddy-Rainbow-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1045,
    "newID": "R+G+B+WhPi,M1",
    "name": "Muddy-Rainbow-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1046,
    "newID": "R+B+WhPi,M1",
    "name": "Muddy-Rainbow-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1581,
    "newID": "R+Y+G+B+P+Pr,M1",
    "name": "Muddy-Rainbow-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1582,
    "newID": "R+Y+G+B+Pr,M1",
    "name": "Muddy-Rainbow-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1583,
    "newID": "R+G+B+Pr,M1",
    "name": "Muddy-Rainbow-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1584,
    "newID": "R+B+Pr,M1",
    "name": "Muddy-Rainbow-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1047,
    "newID": "R+Y+G+B+P+DRa,M1",
    "name": "Muddy-Rainbow-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1048,
    "newID": "R+Y+G+B+DRa,M1",
    "name": "Muddy-Rainbow-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1049,
    "newID": "R+Y+G+B+P+DPi,M1",
    "name": "Muddy-Rainbow-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1050,
    "newID": "R+Y+G+B+DPi,M1",
    "name": "Muddy-Rainbow-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1051,
    "newID": "R+Y+G+B+P+DWh,M1",
    "name": "Muddy-Rainbow-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1052,
    "newID": "R+Y+G+B+DWh,M1",
    "name": "Muddy-Rainbow-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1053,
    "newID": "R+Y+G+B+P+DBr,M1",
    "name": "Muddy-Rainbow-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1054,
    "newID": "R+Y+G+B+DBr,M1",
    "name": "Muddy-Rainbow-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1055,
    "newID": "R+Y+G+B+P+DOm,M1",
    "name": "Muddy-Rainbow-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1056,
    "newID": "R+Y+G+B+DOm,M1",
    "name": "Muddy-Rainbow-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1057,
    "newID": "R+Y+G+B+P+DNu,M1",
    "name": "Muddy-Rainbow-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1058,
    "newID": "R+Y+G+B+DNu,M1",
    "name": "Muddy-Rainbow-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1059,
    "newID": "R+Y+G+B+P+DBrRa,M1",
    "name": "Muddy-Rainbow-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1060,
    "newID": "R+Y+G+B+DBrRa,M1",
    "name": "Muddy-Rainbow-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1061,
    "newID": "R+Y+G+B+P+DWhPi,M1",
    "name": "Muddy-Rainbow-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1062,
    "newID": "R+Y+G+B+DWhPi,M1",
    "name": "Muddy-Rainbow-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1585,
    "newID": "R+Y+G+B+P+DPr,M1",
    "name": "Muddy-Rainbow-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1586,
    "newID": "R+Y+G+B+DPr,M1",
    "name": "Muddy-Rainbow-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1063,
    "newID": "R+Y+G+B+P+T,L1",
    "name": "Light-Pink-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1064,
    "newID": "R+Y+G+B+P,L1",
    "name": "Light-Pink-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1065,
    "newID": "R+Y+G+B,L1",
    "name": "Light-Pink-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1066,
    "newID": "R+G+B,L1",
    "name": "Light-Pink-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1334,
    "newID": "R+Y+G+B+P+Bk,L1",
    "name": "Light-Pink-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1335,
    "newID": "R+Y+G+B+Bk,L1",
    "name": "Light-Pink-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1067,
    "newID": "R+Y+G+B+P+Ra,L1",
    "name": "Light-Pink-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1068,
    "newID": "R+Y+G+B+Ra,L1",
    "name": "Light-Pink-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1069,
    "newID": "R+G+B+Ra,L1",
    "name": "Light-Pink-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1070,
    "newID": "R+B+Ra,L1",
    "name": "Light-Pink-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1071,
    "newID": "R+Y+G+B+P+Pi,L1",
    "name": "Light-Pink-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1072,
    "newID": "R+Y+G+B+Pi,L1",
    "name": "Light-Pink-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1073,
    "newID": "R+G+B+Pi,L1",
    "name": "Light-Pink-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1074,
    "newID": "R+B+Pi,L1",
    "name": "Light-Pink-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1075,
    "newID": "R+Y+G+B+P+Wh,L1",
    "name": "Light-Pink-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1076,
    "newID": "R+Y+G+B+Wh,L1",
    "name": "Light-Pink-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1077,
    "newID": "R+G+B+Wh,L1",
    "name": "Light-Pink-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1078,
    "newID": "R+B+Wh,L1",
    "name": "Light-Pink-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1079,
    "newID": "R+Y+G+B+P+Br,L1",
    "name": "Light-Pink-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1080,
    "newID": "R+Y+G+B+Br,L1",
    "name": "Light-Pink-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1081,
    "newID": "R+G+B+Br,L1",
    "name": "Light-Pink-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1082,
    "newID": "R+B+Br,L1",
    "name": "Light-Pink-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1083,
    "newID": "R+Y+G+B+P+Om,L1",
    "name": "Light-Pink-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1084,
    "newID": "R+Y+G+B+Om,L1",
    "name": "Light-Pink-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1085,
    "newID": "R+G+B+Om,L1",
    "name": "Light-Pink-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1086,
    "newID": "R+B+Om,L1",
    "name": "Light-Pink-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1087,
    "newID": "R+Y+G+B+P+Nu,L1",
    "name": "Light-Pink-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1088,
    "newID": "R+Y+G+B+Nu,L1",
    "name": "Light-Pink-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1089,
    "newID": "R+G+B+Nu,L1",
    "name": "Light-Pink-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1090,
    "newID": "R+B+Nu,L1",
    "name": "Light-Pink-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1091,
    "newID": "R+Y+G+B+P+BrRa,L1",
    "name": "Light-Pink-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1092,
    "newID": "R+Y+G+B+BrRa,L1",
    "name": "Light-Pink-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1093,
    "newID": "R+G+B+BrRa,L1",
    "name": "Light-Pink-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1094,
    "newID": "R+B+BrRa,L1",
    "name": "Light-Pink-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1095,
    "newID": "R+Y+G+B+P+WhPi,L1",
    "name": "Light-Pink-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1096,
    "newID": "R+Y+G+B+WhPi,L1",
    "name": "Light-Pink-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1097,
    "newID": "R+G+B+WhPi,L1",
    "name": "Light-Pink-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1098,
    "newID": "R+B+WhPi,L1",
    "name": "Light-Pink-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1587,
    "newID": "R+Y+G+B+P+Pr,L1",
    "name": "Light-Pink-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1588,
    "newID": "R+Y+G+B+Pr,L1",
    "name": "Light-Pink-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1589,
    "newID": "R+G+B+Pr,L1",
    "name": "Light-Pink-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1590,
    "newID": "R+B+Pr,L1",
    "name": "Light-Pink-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1099,
    "newID": "R+Y+G+B+P+DRa,L1",
    "name": "Light-Pink-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1100,
    "newID": "R+Y+G+B+DRa,L1",
    "name": "Light-Pink-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1101,
    "newID": "R+Y+G+B+P+DPi,L1",
    "name": "Light-Pink-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1102,
    "newID": "R+Y+G+B+DPi,L1",
    "name": "Light-Pink-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1103,
    "newID": "R+Y+G+B+P+DWh,L1",
    "name": "Light-Pink-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1104,
    "newID": "R+Y+G+B+DWh,L1",
    "name": "Light-Pink-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1105,
    "newID": "R+Y+G+B+P+DBr,L1",
    "name": "Light-Pink-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1106,
    "newID": "R+Y+G+B+DBr,L1",
    "name": "Light-Pink-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1107,
    "newID": "R+Y+G+B+P+DOm,L1",
    "name": "Light-Pink-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1108,
    "newID": "R+Y+G+B+DOm,L1",
    "name": "Light-Pink-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1109,
    "newID": "R+Y+G+B+P+DNu,L1",
    "name": "Light-Pink-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1110,
    "newID": "R+Y+G+B+DNu,L1",
    "name": "Light-Pink-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1111,
    "newID": "R+Y+G+B+P+DBrRa,L1",
    "name": "Light-Pink-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1112,
    "newID": "R+Y+G+B+DBrRa,L1",
    "name": "Light-Pink-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1113,
    "newID": "R+Y+G+B+P+DWhPi,L1",
    "name": "Light-Pink-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1114,
    "newID": "R+Y+G+B+DWhPi,L1",
    "name": "Light-Pink-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1591,
    "newID": "R+Y+G+B+P+DPr,L1",
    "name": "Light-Pink-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1592,
    "newID": "R+Y+G+B+DPr,L1",
    "name": "Light-Pink-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1671,
    "newID": "R+Y+G+B+P+T,D1",
    "name": "Deceptive-Ones (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1672,
    "newID": "R+Y+G+B+P,D1",
    "name": "Deceptive-Ones (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1673,
    "newID": "R+Y+G+B,D1",
    "name": "Deceptive-Ones (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1674,
    "newID": "R+G+B,D1",
    "name": "Deceptive-Ones (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1675,
    "newID": "R+Y+G+B+P+Bk,D1",
    "name": "Deceptive-Ones & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1676,
    "newID": "R+Y+G+B+Bk,D1",
    "name": "Deceptive-Ones & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1677,
    "newID": "R+Y+G+B+P+Ra,D1",
    "name": "Deceptive-Ones & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1678,
    "newID": "R+Y+G+B+Ra,D1",
    "name": "Deceptive-Ones & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1679,
    "newID": "R+G+B+Ra,D1",
    "name": "Deceptive-Ones & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1680,
    "newID": "R+B+Ra,D1",
    "name": "Deceptive-Ones & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1681,
    "newID": "R+Y+G+B+P+Pi,D1",
    "name": "Deceptive-Ones & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1682,
    "newID": "R+Y+G+B+Pi,D1",
    "name": "Deceptive-Ones & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1683,
    "newID": "R+G+B+Pi,D1",
    "name": "Deceptive-Ones & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1684,
    "newID": "R+B+Pi,D1",
    "name": "Deceptive-Ones & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1685,
    "newID": "R+Y+G+B+P+Wh,D1",
    "name": "Deceptive-Ones & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1686,
    "newID": "R+Y+G+B+Wh,D1",
    "name": "Deceptive-Ones & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1687,
    "newID": "R+G+B+Wh,D1",
    "name": "Deceptive-Ones & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1688,
    "newID": "R+B+Wh,D1",
    "name": "Deceptive-Ones & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1689,
    "newID": "R+Y+G+B+P+Br,D1",
    "name": "Deceptive-Ones & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1690,
    "newID": "R+Y+G+B+Br,D1",
    "name": "Deceptive-Ones & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1691,
    "newID": "R+G+B+Br,D1",
    "name": "Deceptive-Ones & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1692,
    "newID": "R+B+Br,D1",
    "name": "Deceptive-Ones & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1693,
    "newID": "R+Y+G+B+P+Om,D1",
    "name": "Deceptive-Ones & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1694,
    "newID": "R+Y+G+B+Om,D1",
    "name": "Deceptive-Ones & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1695,
    "newID": "R+G+B+Om,D1",
    "name": "Deceptive-Ones & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1696,
    "newID": "R+B+Om,D1",
    "name": "Deceptive-Ones & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1697,
    "newID": "R+Y+G+B+P+Nu,D1",
    "name": "Deceptive-Ones & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1698,
    "newID": "R+Y+G+B+Nu,D1",
    "name": "Deceptive-Ones & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1699,
    "newID": "R+G+B+Nu,D1",
    "name": "Deceptive-Ones & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1700,
    "newID": "R+B+Nu,D1",
    "name": "Deceptive-Ones & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1701,
    "newID": "R+Y+G+B+P+BrRa,D1",
    "name": "Deceptive-Ones & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1702,
    "newID": "R+Y+G+B+BrRa,D1",
    "name": "Deceptive-Ones & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1703,
    "newID": "R+G+B+BrRa,D1",
    "name": "Deceptive-Ones & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1704,
    "newID": "R+B+BrRa,D1",
    "name": "Deceptive-Ones & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1705,
    "newID": "R+Y+G+B+P+WhPi,D1",
    "name": "Deceptive-Ones & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1706,
    "newID": "R+Y+G+B+WhPi,D1",
    "name": "Deceptive-Ones & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1707,
    "newID": "R+G+B+WhPi,D1",
    "name": "Deceptive-Ones & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1708,
    "newID": "R+B+WhPi,D1",
    "name": "Deceptive-Ones & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1709,
    "newID": "R+Y+G+B+P+Pr,D1",
    "name": "Deceptive-Ones & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1710,
    "newID": "R+Y+G+B+Pr,D1",
    "name": "Deceptive-Ones & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1711,
    "newID": "R+G+B+Pr,D1",
    "name": "Deceptive-Ones & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1712,
    "newID": "R+B+Pr,D1",
    "name": "Deceptive-Ones & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1713,
    "newID": "R+Y+G+B+P+DRa,D1",
    "name": "Deceptive-Ones & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1714,
    "newID": "R+Y+G+B+DRa,D1",
    "name": "Deceptive-Ones & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1715,
    "newID": "R+Y+G+B+P+DPi,D1",
    "name": "Deceptive-Ones & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1716,
    "newID": "R+Y+G+B+DPi,D1",
    "name": "Deceptive-Ones & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1717,
    "newID": "R+Y+G+B+P+DWh,D1",
    "name": "Deceptive-Ones & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1718,
    "newID": "R+Y+G+B+DWh,D1",
    "name": "Deceptive-Ones & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1719,
    "newID": "R+Y+G+B+P+DBr,D1",
    "name": "Deceptive-Ones & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1720,
    "newID": "R+Y+G+B+DBr,D1",
    "name": "Deceptive-Ones & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1721,
    "newID": "R+Y+G+B+P+DOm,D1",
    "name": "Deceptive-Ones & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1722,
    "newID": "R+Y+G+B+DOm,D1",
    "name": "Deceptive-Ones & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1723,
    "newID": "R+Y+G+B+P+DNu,D1",
    "name": "Deceptive-Ones & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1724,
    "newID": "R+Y+G+B+DNu,D1",
    "name": "Deceptive-Ones & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1725,
    "newID": "R+Y+G+B+P+DBrRa,D1",
    "name": "Deceptive-Ones & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1726,
    "newID": "R+Y+G+B+DBrRa,D1",
    "name": "Deceptive-Ones & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1727,
    "newID": "R+Y+G+B+P+DWhPi,D1",
    "name": "Deceptive-Ones & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1728,
    "newID": "R+Y+G+B+DWhPi,D1",
    "name": "Deceptive-Ones & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1729,
    "newID": "R+Y+G+B+P+DPr,D1",
    "name": "Deceptive-Ones & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 1730,
    "newID": "R+Y+G+B+DPr,D1",
    "name": "Deceptive-Ones & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 1,
    "specialRankDeceptive": true,
    "clueRanks": [2, 3, 4, 5]
  },
  {
    "id": 526,
    "newID": "R+Y+G+B+P+T,R5",
    "name": "Rainbow-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 527,
    "newID": "R+Y+G+B+P,R5",
    "name": "Rainbow-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 528,
    "newID": "R+Y+G+B,R5",
    "name": "Rainbow-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 529,
    "newID": "R+G+B,R5",
    "name": "Rainbow-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1318,
    "newID": "R+Y+G+B+P+Bk,R5",
    "name": "Rainbow-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1319,
    "newID": "R+Y+G+B+Bk,R5",
    "name": "Rainbow-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 530,
    "newID": "R+Y+G+B+P+Ra,R5",
    "name": "Rainbow-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 531,
    "newID": "R+Y+G+B+Ra,R5",
    "name": "Rainbow-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 532,
    "newID": "R+G+B+Ra,R5",
    "name": "Rainbow-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 533,
    "newID": "R+B+Ra,R5",
    "name": "Rainbow-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 534,
    "newID": "R+Y+G+B+P+Pi,R5",
    "name": "Rainbow-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 535,
    "newID": "R+Y+G+B+Pi,R5",
    "name": "Rainbow-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 536,
    "newID": "R+G+B+Pi,R5",
    "name": "Rainbow-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 537,
    "newID": "R+B+Pi,R5",
    "name": "Rainbow-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 538,
    "newID": "R+Y+G+B+P+Wh,R5",
    "name": "Rainbow-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 539,
    "newID": "R+Y+G+B+Wh,R5",
    "name": "Rainbow-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 540,
    "newID": "R+G+B+Wh,R5",
    "name": "Rainbow-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 541,
    "newID": "R+B+Wh,R5",
    "name": "Rainbow-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 542,
    "newID": "R+Y+G+B+P+Br,R5",
    "name": "Rainbow-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 543,
    "newID": "R+Y+G+B+Br,R5",
    "name": "Rainbow-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 544,
    "newID": "R+G+B+Br,R5",
    "name": "Rainbow-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 545,
    "newID": "R+B+Br,R5",
    "name": "Rainbow-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 546,
    "newID": "R+Y+G+B+P+Om,R5",
    "name": "Rainbow-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 547,
    "newID": "R+Y+G+B+Om,R5",
    "name": "Rainbow-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 548,
    "newID": "R+G+B+Om,R5",
    "name": "Rainbow-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 549,
    "newID": "R+B+Om,R5",
    "name": "Rainbow-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 550,
    "newID": "R+Y+G+B+P+Nu,R5",
    "name": "Rainbow-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 551,
    "newID": "R+Y+G+B+Nu,R5",
    "name": "Rainbow-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 552,
    "newID": "R+G+B+Nu,R5",
    "name": "Rainbow-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 553,
    "newID": "R+B+Nu,R5",
    "name": "Rainbow-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 939,
    "newID": "R+Y+G+B+P+BrRa,R5",
    "name": "Rainbow-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 940,
    "newID": "R+Y+G+B+BrRa,R5",
    "name": "Rainbow-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 941,
    "newID": "R+G+B+BrRa,R5",
    "name": "Rainbow-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 942,
    "newID": "R+B+BrRa,R5",
    "name": "Rainbow-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 943,
    "newID": "R+Y+G+B+P+WhPi,R5",
    "name": "Rainbow-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 944,
    "newID": "R+Y+G+B+WhPi,R5",
    "name": "Rainbow-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 945,
    "newID": "R+G+B+WhPi,R5",
    "name": "Rainbow-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 946,
    "newID": "R+B+WhPi,R5",
    "name": "Rainbow-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1593,
    "newID": "R+Y+G+B+P+Pr,R5",
    "name": "Rainbow-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1594,
    "newID": "R+Y+G+B+Pr,R5",
    "name": "Rainbow-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1595,
    "newID": "R+G+B+Pr,R5",
    "name": "Rainbow-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1596,
    "newID": "R+B+Pr,R5",
    "name": "Rainbow-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 554,
    "newID": "R+Y+G+B+P+DRa,R5",
    "name": "Rainbow-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 555,
    "newID": "R+Y+G+B+DRa,R5",
    "name": "Rainbow-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 556,
    "newID": "R+Y+G+B+P+DPi,R5",
    "name": "Rainbow-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 557,
    "newID": "R+Y+G+B+DPi,R5",
    "name": "Rainbow-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 558,
    "newID": "R+Y+G+B+P+DWh,R5",
    "name": "Rainbow-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 559,
    "newID": "R+Y+G+B+DWh,R5",
    "name": "Rainbow-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 560,
    "newID": "R+Y+G+B+P+DBr,R5",
    "name": "Rainbow-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 561,
    "newID": "R+Y+G+B+DBr,R5",
    "name": "Rainbow-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 562,
    "newID": "R+Y+G+B+P+DOm,R5",
    "name": "Rainbow-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 563,
    "newID": "R+Y+G+B+DOm,R5",
    "name": "Rainbow-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 564,
    "newID": "R+Y+G+B+P+DNu,R5",
    "name": "Rainbow-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 565,
    "newID": "R+Y+G+B+DNu,R5",
    "name": "Rainbow-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 947,
    "newID": "R+Y+G+B+P+DBrRa,R5",
    "name": "Rainbow-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 948,
    "newID": "R+Y+G+B+DBrRa,R5",
    "name": "Rainbow-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 949,
    "newID": "R+Y+G+B+P+DWhPi,R5",
    "name": "Rainbow-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 950,
    "newID": "R+Y+G+B+DWhPi,R5",
    "name": "Rainbow-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1597,
    "newID": "R+Y+G+B+P+DPr,R5",
    "name": "Rainbow-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 1598,
    "newID": "R+Y+G+B+DPr,R5",
    "name": "Rainbow-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true
  },
  {
    "id": 45,
    "newID": "R+Y+G+B+P+T,P5",
    "name": "Pink-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 46,
    "newID": "R+Y+G+B+P,P5",
    "name": "Pink-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 49,
    "newID": "R+Y+G+B,P5",
    "name": "Pink-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 50,
    "newID": "R+G+B,P5",
    "name": "Pink-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1322,
    "newID": "R+Y+G+B+P+Bk,P5",
    "name": "Pink-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1323,
    "newID": "R+Y+G+B+Bk,P5",
    "name": "Pink-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 39,
    "newID": "R+Y+G+B+P+Ra,P5",
    "name": "Pink-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 14,
    "newID": "R+Y+G+B+Ra,P5",
    "name": "Pink-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 47,
    "newID": "R+G+B+Ra,P5",
    "name": "Pink-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 48,
    "newID": "R+B+Ra,P5",
    "name": "Pink-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 566,
    "newID": "R+Y+G+B+P+Pi,P5",
    "name": "Pink-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 567,
    "newID": "R+Y+G+B+Pi,P5",
    "name": "Pink-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 568,
    "newID": "R+G+B+Pi,P5",
    "name": "Pink-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 569,
    "newID": "R+B+Pi,P5",
    "name": "Pink-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 570,
    "newID": "R+Y+G+B+P+Wh,P5",
    "name": "Pink-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 571,
    "newID": "R+Y+G+B+Wh,P5",
    "name": "Pink-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 572,
    "newID": "R+G+B+Wh,P5",
    "name": "Pink-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 573,
    "newID": "R+B+Wh,P5",
    "name": "Pink-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 574,
    "newID": "R+Y+G+B+P+Br,P5",
    "name": "Pink-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 575,
    "newID": "R+Y+G+B+Br,P5",
    "name": "Pink-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 576,
    "newID": "R+G+B+Br,P5",
    "name": "Pink-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 577,
    "newID": "R+B+Br,P5",
    "name": "Pink-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 578,
    "newID": "R+Y+G+B+P+Om,P5",
    "name": "Pink-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 579,
    "newID": "R+Y+G+B+Om,P5",
    "name": "Pink-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 580,
    "newID": "R+G+B+Om,P5",
    "name": "Pink-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 581,
    "newID": "R+B+Om,P5",
    "name": "Pink-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 582,
    "newID": "R+Y+G+B+P+Nu,P5",
    "name": "Pink-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 583,
    "newID": "R+Y+G+B+Nu,P5",
    "name": "Pink-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 584,
    "newID": "R+G+B+Nu,P5",
    "name": "Pink-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 585,
    "newID": "R+B+Nu,P5",
    "name": "Pink-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 951,
    "newID": "R+Y+G+B+P+BrRa,P5",
    "name": "Pink-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 952,
    "newID": "R+Y+G+B+BrRa,P5",
    "name": "Pink-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 953,
    "newID": "R+G+B+BrRa,P5",
    "name": "Pink-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 954,
    "newID": "R+B+BrRa,P5",
    "name": "Pink-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 955,
    "newID": "R+Y+G+B+P+WhPi,P5",
    "name": "Pink-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 956,
    "newID": "R+Y+G+B+WhPi,P5",
    "name": "Pink-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 957,
    "newID": "R+G+B+WhPi,P5",
    "name": "Pink-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 958,
    "newID": "R+B+WhPi,P5",
    "name": "Pink-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1599,
    "newID": "R+Y+G+B+P+Pr,P5",
    "name": "Pink-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1600,
    "newID": "R+Y+G+B+Pr,P5",
    "name": "Pink-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1601,
    "newID": "R+G+B+Pr,P5",
    "name": "Pink-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1602,
    "newID": "R+B+Pr,P5",
    "name": "Pink-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 586,
    "newID": "R+Y+G+B+P+DRa,P5",
    "name": "Pink-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 587,
    "newID": "R+Y+G+B+DRa,P5",
    "name": "Pink-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 588,
    "newID": "R+Y+G+B+P+DPi,P5",
    "name": "Pink-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 589,
    "newID": "R+Y+G+B+DPi,P5",
    "name": "Pink-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 590,
    "newID": "R+Y+G+B+P+DWh,P5",
    "name": "Pink-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 591,
    "newID": "R+Y+G+B+DWh,P5",
    "name": "Pink-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 592,
    "newID": "R+Y+G+B+P+DBr,P5",
    "name": "Pink-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 593,
    "newID": "R+Y+G+B+DBr,P5",
    "name": "Pink-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 594,
    "newID": "R+Y+G+B+P+DOm,P5",
    "name": "Pink-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 595,
    "newID": "R+Y+G+B+DOm,P5",
    "name": "Pink-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 596,
    "newID": "R+Y+G+B+P+DNu,P5",
    "name": "Pink-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 597,
    "newID": "R+Y+G+B+DNu,P5",
    "name": "Pink-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 959,
    "newID": "R+Y+G+B+P+DBrRa,P5",
    "name": "Pink-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 960,
    "newID": "R+Y+G+B+DBrRa,P5",
    "name": "Pink-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 961,
    "newID": "R+Y+G+B+P+DWhPi,P5",
    "name": "Pink-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 962,
    "newID": "R+Y+G+B+DWhPi,P5",
    "name": "Pink-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1603,
    "newID": "R+Y+G+B+P+DPr,P5",
    "name": "Pink-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1604,
    "newID": "R+Y+G+B+DPr,P5",
    "name": "Pink-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 598,
    "newID": "R+Y+G+B+P+T,W5",
    "name": "White-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 599,
    "newID": "R+Y+G+B+P,W5",
    "name": "White-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 600,
    "newID": "R+Y+G+B,W5",
    "name": "White-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 601,
    "newID": "R+G+B,W5",
    "name": "White-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1324,
    "newID": "R+Y+G+B+P+Bk,W5",
    "name": "White-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1325,
    "newID": "R+Y+G+B+Bk,W5",
    "name": "White-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 602,
    "newID": "R+Y+G+B+P+Ra,W5",
    "name": "White-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 603,
    "newID": "R+Y+G+B+Ra,W5",
    "name": "White-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 604,
    "newID": "R+G+B+Ra,W5",
    "name": "White-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 605,
    "newID": "R+B+Ra,W5",
    "name": "White-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 606,
    "newID": "R+Y+G+B+P+Pi,W5",
    "name": "White-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 607,
    "newID": "R+Y+G+B+Pi,W5",
    "name": "White-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 608,
    "newID": "R+G+B+Pi,W5",
    "name": "White-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 609,
    "newID": "R+B+Pi,W5",
    "name": "White-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 610,
    "newID": "R+Y+G+B+P+Wh,W5",
    "name": "White-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 611,
    "newID": "R+Y+G+B+Wh,W5",
    "name": "White-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 612,
    "newID": "R+G+B+Wh,W5",
    "name": "White-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 613,
    "newID": "R+B+Wh,W5",
    "name": "White-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 614,
    "newID": "R+Y+G+B+P+Br,W5",
    "name": "White-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 615,
    "newID": "R+Y+G+B+Br,W5",
    "name": "White-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 616,
    "newID": "R+G+B+Br,W5",
    "name": "White-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 617,
    "newID": "R+B+Br,W5",
    "name": "White-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 618,
    "newID": "R+Y+G+B+P+Om,W5",
    "name": "White-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 619,
    "newID": "R+Y+G+B+Om,W5",
    "name": "White-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 620,
    "newID": "R+G+B+Om,W5",
    "name": "White-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 621,
    "newID": "R+B+Om,W5",
    "name": "White-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 622,
    "newID": "R+Y+G+B+P+Nu,W5",
    "name": "White-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 623,
    "newID": "R+Y+G+B+Nu,W5",
    "name": "White-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 624,
    "newID": "R+G+B+Nu,W5",
    "name": "White-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 625,
    "newID": "R+B+Nu,W5",
    "name": "White-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 963,
    "newID": "R+Y+G+B+P+BrRa,W5",
    "name": "White-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 964,
    "newID": "R+Y+G+B+BrRa,W5",
    "name": "White-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 965,
    "newID": "R+G+B+BrRa,W5",
    "name": "White-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 966,
    "newID": "R+B+BrRa,W5",
    "name": "White-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 967,
    "newID": "R+Y+G+B+P+WhPi,W5",
    "name": "White-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 968,
    "newID": "R+Y+G+B+WhPi,W5",
    "name": "White-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 969,
    "newID": "R+G+B+WhPi,W5",
    "name": "White-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 970,
    "newID": "R+B+WhPi,W5",
    "name": "White-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1605,
    "newID": "R+Y+G+B+P+Pr,W5",
    "name": "White-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1606,
    "newID": "R+Y+G+B+Pr,W5",
    "name": "White-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1607,
    "newID": "R+G+B+Pr,W5",
    "name": "White-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1608,
    "newID": "R+B+Pr,W5",
    "name": "White-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 626,
    "newID": "R+Y+G+B+P+DRa,W5",
    "name": "White-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 627,
    "newID": "R+Y+G+B+DRa,W5",
    "name": "White-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 628,
    "newID": "R+Y+G+B+P+DPi,W5",
    "name": "White-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 629,
    "newID": "R+Y+G+B+DPi,W5",
    "name": "White-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 630,
    "newID": "R+Y+G+B+P+DWh,W5",
    "name": "White-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 631,
    "newID": "R+Y+G+B+DWh,W5",
    "name": "White-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 632,
    "newID": "R+Y+G+B+P+DBr,W5",
    "name": "White-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 633,
    "newID": "R+Y+G+B+DBr,W5",
    "name": "White-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 634,
    "newID": "R+Y+G+B+P+DOm,W5",
    "name": "White-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 635,
    "newID": "R+Y+G+B+DOm,W5",
    "name": "White-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 636,
    "newID": "R+Y+G+B+P+DNu,W5",
    "name": "White-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 637,
    "newID": "R+Y+G+B+DNu,W5",
    "name": "White-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 971,
    "newID": "R+Y+G+B+P+DBrRa,W5",
    "name": "White-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 972,
    "newID": "R+Y+G+B+DBrRa,W5",
    "name": "White-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 973,
    "newID": "R+Y+G+B+P+DWhPi,W5",
    "name": "White-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 974,
    "newID": "R+Y+G+B+DWhPi,W5",
    "name": "White-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1609,
    "newID": "R+Y+G+B+P+DPr,W5",
    "name": "White-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 1610,
    "newID": "R+Y+G+B+DPr,W5",
    "name": "White-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true
  },
  {
    "id": 638,
    "newID": "R+Y+G+B+P+T,B5",
    "name": "Brown-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 639,
    "newID": "R+Y+G+B+P,B5",
    "name": "Brown-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 640,
    "newID": "R+Y+G+B,B5",
    "name": "Brown-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 641,
    "newID": "R+G+B,B5",
    "name": "Brown-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1328,
    "newID": "R+Y+G+B+P+Bk,B5",
    "name": "Brown-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1329,
    "newID": "R+Y+G+B+Bk,B5",
    "name": "Brown-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 642,
    "newID": "R+Y+G+B+P+Ra,B5",
    "name": "Brown-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 643,
    "newID": "R+Y+G+B+Ra,B5",
    "name": "Brown-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 644,
    "newID": "R+G+B+Ra,B5",
    "name": "Brown-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 645,
    "newID": "R+B+Ra,B5",
    "name": "Brown-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 646,
    "newID": "R+Y+G+B+P+Pi,B5",
    "name": "Brown-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 647,
    "newID": "R+Y+G+B+Pi,B5",
    "name": "Brown-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 648,
    "newID": "R+G+B+Pi,B5",
    "name": "Brown-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 649,
    "newID": "R+B+Pi,B5",
    "name": "Brown-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 650,
    "newID": "R+Y+G+B+P+Wh,B5",
    "name": "Brown-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 651,
    "newID": "R+Y+G+B+Wh,B5",
    "name": "Brown-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 652,
    "newID": "R+G+B+Wh,B5",
    "name": "Brown-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 653,
    "newID": "R+B+Wh,B5",
    "name": "Brown-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 654,
    "newID": "R+Y+G+B+P+Br,B5",
    "name": "Brown-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 655,
    "newID": "R+Y+G+B+Br,B5",
    "name": "Brown-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 656,
    "newID": "R+G+B+Br,B5",
    "name": "Brown-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 657,
    "newID": "R+B+Br,B5",
    "name": "Brown-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 658,
    "newID": "R+Y+G+B+P+Om,B5",
    "name": "Brown-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 659,
    "newID": "R+Y+G+B+Om,B5",
    "name": "Brown-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 660,
    "newID": "R+G+B+Om,B5",
    "name": "Brown-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 661,
    "newID": "R+B+Om,B5",
    "name": "Brown-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 662,
    "newID": "R+Y+G+B+P+Nu,B5",
    "name": "Brown-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 663,
    "newID": "R+Y+G+B+Nu,B5",
    "name": "Brown-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 664,
    "newID": "R+G+B+Nu,B5",
    "name": "Brown-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 665,
    "newID": "R+B+Nu,B5",
    "name": "Brown-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 975,
    "newID": "R+Y+G+B+P+BrRa,B5",
    "name": "Brown-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 976,
    "newID": "R+Y+G+B+BrRa,B5",
    "name": "Brown-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 977,
    "newID": "R+G+B+BrRa,B5",
    "name": "Brown-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 978,
    "newID": "R+B+BrRa,B5",
    "name": "Brown-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 979,
    "newID": "R+Y+G+B+P+WhPi,B5",
    "name": "Brown-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 980,
    "newID": "R+Y+G+B+WhPi,B5",
    "name": "Brown-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 981,
    "newID": "R+G+B+WhPi,B5",
    "name": "Brown-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 982,
    "newID": "R+B+WhPi,B5",
    "name": "Brown-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1611,
    "newID": "R+Y+G+B+P+Pr,B5",
    "name": "Brown-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1612,
    "newID": "R+Y+G+B+Pr,B5",
    "name": "Brown-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1613,
    "newID": "R+G+B+Pr,B5",
    "name": "Brown-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1614,
    "newID": "R+B+Pr,B5",
    "name": "Brown-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 666,
    "newID": "R+Y+G+B+P+DRa,B5",
    "name": "Brown-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 667,
    "newID": "R+Y+G+B+DRa,B5",
    "name": "Brown-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 668,
    "newID": "R+Y+G+B+P+DPi,B5",
    "name": "Brown-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 669,
    "newID": "R+Y+G+B+DPi,B5",
    "name": "Brown-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 670,
    "newID": "R+Y+G+B+P+DWh,B5",
    "name": "Brown-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 671,
    "newID": "R+Y+G+B+DWh,B5",
    "name": "Brown-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 672,
    "newID": "R+Y+G+B+P+DBr,B5",
    "name": "Brown-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 673,
    "newID": "R+Y+G+B+DBr,B5",
    "name": "Brown-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 674,
    "newID": "R+Y+G+B+P+DOm,B5",
    "name": "Brown-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 675,
    "newID": "R+Y+G+B+DOm,B5",
    "name": "Brown-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 676,
    "newID": "R+Y+G+B+P+DNu,B5",
    "name": "Brown-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 677,
    "newID": "R+Y+G+B+DNu,B5",
    "name": "Brown-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 983,
    "newID": "R+Y+G+B+P+DBrRa,B5",
    "name": "Brown-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 984,
    "newID": "R+Y+G+B+DBrRa,B5",
    "name": "Brown-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 985,
    "newID": "R+Y+G+B+P+DWhPi,B5",
    "name": "Brown-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 986,
    "newID": "R+Y+G+B+DWhPi,B5",
    "name": "Brown-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1615,
    "newID": "R+Y+G+B+P+DPr,B5",
    "name": "Brown-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1616,
    "newID": "R+Y+G+B+DPr,B5",
    "name": "Brown-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 678,
    "newID": "R+Y+G+B+P+T,O5",
    "name": "Omni-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 679,
    "newID": "R+Y+G+B+P,O5",
    "name": "Omni-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 680,
    "newID": "R+Y+G+B,O5",
    "name": "Omni-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 681,
    "newID": "R+G+B,O5",
    "name": "Omni-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1332,
    "newID": "R+Y+G+B+P+Bk,O5",
    "name": "Omni-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1333,
    "newID": "R+Y+G+B+Bk,O5",
    "name": "Omni-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 682,
    "newID": "R+Y+G+B+P+Ra,O5",
    "name": "Omni-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 683,
    "newID": "R+Y+G+B+Ra,O5",
    "name": "Omni-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 684,
    "newID": "R+G+B+Ra,O5",
    "name": "Omni-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 685,
    "newID": "R+B+Ra,O5",
    "name": "Omni-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 686,
    "newID": "R+Y+G+B+P+Pi,O5",
    "name": "Omni-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 687,
    "newID": "R+Y+G+B+Pi,O5",
    "name": "Omni-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 688,
    "newID": "R+G+B+Pi,O5",
    "name": "Omni-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 689,
    "newID": "R+B+Pi,O5",
    "name": "Omni-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 690,
    "newID": "R+Y+G+B+P+Wh,O5",
    "name": "Omni-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 691,
    "newID": "R+Y+G+B+Wh,O5",
    "name": "Omni-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 692,
    "newID": "R+G+B+Wh,O5",
    "name": "Omni-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 693,
    "newID": "R+B+Wh,O5",
    "name": "Omni-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 694,
    "newID": "R+Y+G+B+P+Br,O5",
    "name": "Omni-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 695,
    "newID": "R+Y+G+B+Br,O5",
    "name": "Omni-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 696,
    "newID": "R+G+B+Br,O5",
    "name": "Omni-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 697,
    "newID": "R+B+Br,O5",
    "name": "Omni-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 698,
    "newID": "R+Y+G+B+P+Om,O5",
    "name": "Omni-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 699,
    "newID": "R+Y+G+B+Om,O5",
    "name": "Omni-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 700,
    "newID": "R+G+B+Om,O5",
    "name": "Omni-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 701,
    "newID": "R+B+Om,O5",
    "name": "Omni-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 702,
    "newID": "R+Y+G+B+P+Nu,O5",
    "name": "Omni-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 703,
    "newID": "R+Y+G+B+Nu,O5",
    "name": "Omni-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 704,
    "newID": "R+G+B+Nu,O5",
    "name": "Omni-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 705,
    "newID": "R+B+Nu,O5",
    "name": "Omni-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 987,
    "newID": "R+Y+G+B+P+BrRa,O5",
    "name": "Omni-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 988,
    "newID": "R+Y+G+B+BrRa,O5",
    "name": "Omni-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 989,
    "newID": "R+G+B+BrRa,O5",
    "name": "Omni-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 990,
    "newID": "R+B+BrRa,O5",
    "name": "Omni-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 991,
    "newID": "R+Y+G+B+P+WhPi,O5",
    "name": "Omni-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 992,
    "newID": "R+Y+G+B+WhPi,O5",
    "name": "Omni-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 993,
    "newID": "R+G+B+WhPi,O5",
    "name": "Omni-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 994,
    "newID": "R+B+WhPi,O5",
    "name": "Omni-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1617,
    "newID": "R+Y+G+B+P+Pr,O5",
    "name": "Omni-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1618,
    "newID": "R+Y+G+B+Pr,O5",
    "name": "Omni-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1619,
    "newID": "R+G+B+Pr,O5",
    "name": "Omni-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1620,
    "newID": "R+B+Pr,O5",
    "name": "Omni-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 706,
    "newID": "R+Y+G+B+P+DRa,O5",
    "name": "Omni-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 707,
    "newID": "R+Y+G+B+DRa,O5",
    "name": "Omni-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 708,
    "newID": "R+Y+G+B+P+DPi,O5",
    "name": "Omni-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 709,
    "newID": "R+Y+G+B+DPi,O5",
    "name": "Omni-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 710,
    "newID": "R+Y+G+B+P+DWh,O5",
    "name": "Omni-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 711,
    "newID": "R+Y+G+B+DWh,O5",
    "name": "Omni-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 712,
    "newID": "R+Y+G+B+P+DBr,O5",
    "name": "Omni-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 713,
    "newID": "R+Y+G+B+DBr,O5",
    "name": "Omni-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 714,
    "newID": "R+Y+G+B+P+DOm,O5",
    "name": "Omni-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 715,
    "newID": "R+Y+G+B+DOm,O5",
    "name": "Omni-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 716,
    "newID": "R+Y+G+B+P+DNu,O5",
    "name": "Omni-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 717,
    "newID": "R+Y+G+B+DNu,O5",
    "name": "Omni-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 995,
    "newID": "R+Y+G+B+P+DBrRa,O5",
    "name": "Omni-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 996,
    "newID": "R+Y+G+B+DBrRa,O5",
    "name": "Omni-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 997,
    "newID": "R+Y+G+B+P+DWhPi,O5",
    "name": "Omni-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 998,
    "newID": "R+Y+G+B+DWhPi,O5",
    "name": "Omni-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1621,
    "newID": "R+Y+G+B+P+DPr,O5",
    "name": "Omni-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1622,
    "newID": "R+Y+G+B+DPr,O5",
    "name": "Omni-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 718,
    "newID": "R+Y+G+B+P+T,N5",
    "name": "Null-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 719,
    "newID": "R+Y+G+B+P,N5",
    "name": "Null-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 720,
    "newID": "R+Y+G+B,N5",
    "name": "Null-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 721,
    "newID": "R+G+B,N5",
    "name": "Null-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1336,
    "newID": "R+Y+G+B+P+Bk,N5",
    "name": "Null-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1337,
    "newID": "R+Y+G+B+Bk,N5",
    "name": "Null-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 722,
    "newID": "R+Y+G+B+P+Ra,N5",
    "name": "Null-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 723,
    "newID": "R+Y+G+B+Ra,N5",
    "name": "Null-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 724,
    "newID": "R+G+B+Ra,N5",
    "name": "Null-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 725,
    "newID": "R+B+Ra,N5",
    "name": "Null-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 726,
    "newID": "R+Y+G+B+P+Pi,N5",
    "name": "Null-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 727,
    "newID": "R+Y+G+B+Pi,N5",
    "name": "Null-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 728,
    "newID": "R+G+B+Pi,N5",
    "name": "Null-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 729,
    "newID": "R+B+Pi,N5",
    "name": "Null-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 730,
    "newID": "R+Y+G+B+P+Wh,N5",
    "name": "Null-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 731,
    "newID": "R+Y+G+B+Wh,N5",
    "name": "Null-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 732,
    "newID": "R+G+B+Wh,N5",
    "name": "Null-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 733,
    "newID": "R+B+Wh,N5",
    "name": "Null-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 734,
    "newID": "R+Y+G+B+P+Br,N5",
    "name": "Null-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 735,
    "newID": "R+Y+G+B+Br,N5",
    "name": "Null-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 736,
    "newID": "R+G+B+Br,N5",
    "name": "Null-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 737,
    "newID": "R+B+Br,N5",
    "name": "Null-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 738,
    "newID": "R+Y+G+B+P+Om,N5",
    "name": "Null-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 739,
    "newID": "R+Y+G+B+Om,N5",
    "name": "Null-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 740,
    "newID": "R+G+B+Om,N5",
    "name": "Null-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 741,
    "newID": "R+B+Om,N5",
    "name": "Null-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 742,
    "newID": "R+Y+G+B+P+Nu,N5",
    "name": "Null-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 743,
    "newID": "R+Y+G+B+Nu,N5",
    "name": "Null-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 744,
    "newID": "R+G+B+Nu,N5",
    "name": "Null-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 745,
    "newID": "R+B+Nu,N5",
    "name": "Null-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 999,
    "newID": "R+Y+G+B+P+BrRa,N5",
    "name": "Null-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1000,
    "newID": "R+Y+G+B+BrRa,N5",
    "name": "Null-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1001,
    "newID": "R+G+B+BrRa,N5",
    "name": "Null-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1002,
    "newID": "R+B+BrRa,N5",
    "name": "Null-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1003,
    "newID": "R+Y+G+B+P+WhPi,N5",
    "name": "Null-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1004,
    "newID": "R+Y+G+B+WhPi,N5",
    "name": "Null-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1005,
    "newID": "R+G+B+WhPi,N5",
    "name": "Null-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1006,
    "newID": "R+B+WhPi,N5",
    "name": "Null-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1623,
    "newID": "R+Y+G+B+P+Pr,N5",
    "name": "Null-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1624,
    "newID": "R+Y+G+B+Pr,N5",
    "name": "Null-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1625,
    "newID": "R+G+B+Pr,N5",
    "name": "Null-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1626,
    "newID": "R+B+Pr,N5",
    "name": "Null-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 746,
    "newID": "R+Y+G+B+P+DRa,N5",
    "name": "Null-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 747,
    "newID": "R+Y+G+B+DRa,N5",
    "name": "Null-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 748,
    "newID": "R+Y+G+B+P+DPi,N5",
    "name": "Null-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 749,
    "newID": "R+Y+G+B+DPi,N5",
    "name": "Null-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 750,
    "newID": "R+Y+G+B+P+DWh,N5",
    "name": "Null-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 751,
    "newID": "R+Y+G+B+DWh,N5",
    "name": "Null-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 752,
    "newID": "R+Y+G+B+P+DBr,N5",
    "name": "Null-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 753,
    "newID": "R+Y+G+B+DBr,N5",
    "name": "Null-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 754,
    "newID": "R+Y+G+B+P+DOm,N5",
    "name": "Null-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 755,
    "newID": "R+Y+G+B+DOm,N5",
    "name": "Null-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 756,
    "newID": "R+Y+G+B+P+DNu,N5",
    "name": "Null-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 757,
    "newID": "R+Y+G+B+DNu,N5",
    "name": "Null-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1007,
    "newID": "R+Y+G+B+P+DBrRa,N5",
    "name": "Null-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1008,
    "newID": "R+Y+G+B+DBrRa,N5",
    "name": "Null-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1009,
    "newID": "R+Y+G+B+P+DWhPi,N5",
    "name": "Null-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1010,
    "newID": "R+Y+G+B+DWhPi,N5",
    "name": "Null-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1627,
    "newID": "R+Y+G+B+P+DPr,N5",
    "name": "Null-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1628,
    "newID": "R+Y+G+B+DPr,N5",
    "name": "Null-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1115,
    "newID": "R+Y+G+B+P+T,M5",
    "name": "Muddy-Rainbow-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1116,
    "newID": "R+Y+G+B+P,M5",
    "name": "Muddy-Rainbow-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1117,
    "newID": "R+Y+G+B,M5",
    "name": "Muddy-Rainbow-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1118,
    "newID": "R+G+B,M5",
    "name": "Muddy-Rainbow-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1338,
    "newID": "R+Y+G+B+P+Bk,M5",
    "name": "Muddy-Rainbow-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1339,
    "newID": "R+Y+G+B+Bk,M5",
    "name": "Muddy-Rainbow-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1119,
    "newID": "R+Y+G+B+P+Ra,M5",
    "name": "Muddy-Rainbow-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1120,
    "newID": "R+Y+G+B+Ra,M5",
    "name": "Muddy-Rainbow-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1121,
    "newID": "R+G+B+Ra,M5",
    "name": "Muddy-Rainbow-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1122,
    "newID": "R+B+Ra,M5",
    "name": "Muddy-Rainbow-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1123,
    "newID": "R+Y+G+B+P+Pi,M5",
    "name": "Muddy-Rainbow-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1124,
    "newID": "R+Y+G+B+Pi,M5",
    "name": "Muddy-Rainbow-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1125,
    "newID": "R+G+B+Pi,M5",
    "name": "Muddy-Rainbow-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1126,
    "newID": "R+B+Pi,M5",
    "name": "Muddy-Rainbow-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1127,
    "newID": "R+Y+G+B+P+Wh,M5",
    "name": "Muddy-Rainbow-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1128,
    "newID": "R+Y+G+B+Wh,M5",
    "name": "Muddy-Rainbow-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1129,
    "newID": "R+G+B+Wh,M5",
    "name": "Muddy-Rainbow-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1130,
    "newID": "R+B+Wh,M5",
    "name": "Muddy-Rainbow-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1131,
    "newID": "R+Y+G+B+P+Br,M5",
    "name": "Muddy-Rainbow-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1132,
    "newID": "R+Y+G+B+Br,M5",
    "name": "Muddy-Rainbow-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1133,
    "newID": "R+G+B+Br,M5",
    "name": "Muddy-Rainbow-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1134,
    "newID": "R+B+Br,M5",
    "name": "Muddy-Rainbow-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1135,
    "newID": "R+Y+G+B+P+Om,M5",
    "name": "Muddy-Rainbow-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1136,
    "newID": "R+Y+G+B+Om,M5",
    "name": "Muddy-Rainbow-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1137,
    "newID": "R+G+B+Om,M5",
    "name": "Muddy-Rainbow-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1138,
    "newID": "R+B+Om,M5",
    "name": "Muddy-Rainbow-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1139,
    "newID": "R+Y+G+B+P+Nu,M5",
    "name": "Muddy-Rainbow-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1140,
    "newID": "R+Y+G+B+Nu,M5",
    "name": "Muddy-Rainbow-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1141,
    "newID": "R+G+B+Nu,M5",
    "name": "Muddy-Rainbow-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1142,
    "newID": "R+B+Nu,M5",
    "name": "Muddy-Rainbow-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1143,
    "newID": "R+Y+G+B+P+BrRa,M5",
    "name": "Muddy-Rainbow-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1144,
    "newID": "R+Y+G+B+BrRa,M5",
    "name": "Muddy-Rainbow-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1145,
    "newID": "R+G+B+BrRa,M5",
    "name": "Muddy-Rainbow-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1146,
    "newID": "R+B+BrRa,M5",
    "name": "Muddy-Rainbow-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1147,
    "newID": "R+Y+G+B+P+WhPi,M5",
    "name": "Muddy-Rainbow-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1148,
    "newID": "R+Y+G+B+WhPi,M5",
    "name": "Muddy-Rainbow-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1149,
    "newID": "R+G+B+WhPi,M5",
    "name": "Muddy-Rainbow-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1150,
    "newID": "R+B+WhPi,M5",
    "name": "Muddy-Rainbow-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1629,
    "newID": "R+Y+G+B+P+Pr,M5",
    "name": "Muddy-Rainbow-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1630,
    "newID": "R+Y+G+B+Pr,M5",
    "name": "Muddy-Rainbow-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1631,
    "newID": "R+G+B+Pr,M5",
    "name": "Muddy-Rainbow-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1632,
    "newID": "R+B+Pr,M5",
    "name": "Muddy-Rainbow-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1151,
    "newID": "R+Y+G+B+P+DRa,M5",
    "name": "Muddy-Rainbow-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1152,
    "newID": "R+Y+G+B+DRa,M5",
    "name": "Muddy-Rainbow-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1153,
    "newID": "R+Y+G+B+P+DPi,M5",
    "name": "Muddy-Rainbow-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1154,
    "newID": "R+Y+G+B+DPi,M5",
    "name": "Muddy-Rainbow-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1155,
    "newID": "R+Y+G+B+P+DWh,M5",
    "name": "Muddy-Rainbow-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1156,
    "newID": "R+Y+G+B+DWh,M5",
    "name": "Muddy-Rainbow-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1157,
    "newID": "R+Y+G+B+P+DBr,M5",
    "name": "Muddy-Rainbow-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1158,
    "newID": "R+Y+G+B+DBr,M5",
    "name": "Muddy-Rainbow-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1159,
    "newID": "R+Y+G+B+P+DOm,M5",
    "name": "Muddy-Rainbow-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1160,
    "newID": "R+Y+G+B+DOm,M5",
    "name": "Muddy-Rainbow-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1161,
    "newID": "R+Y+G+B+P+DNu,M5",
    "name": "Muddy-Rainbow-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1162,
    "newID": "R+Y+G+B+DNu,M5",
    "name": "Muddy-Rainbow-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1163,
    "newID": "R+Y+G+B+P+DBrRa,M5",
    "name": "Muddy-Rainbow-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1164,
    "newID": "R+Y+G+B+DBrRa,M5",
    "name": "Muddy-Rainbow-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1165,
    "newID": "R+Y+G+B+P+DWhPi,M5",
    "name": "Muddy-Rainbow-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1166,
    "newID": "R+Y+G+B+DWhPi,M5",
    "name": "Muddy-Rainbow-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1633,
    "newID": "R+Y+G+B+P+DPr,M5",
    "name": "Muddy-Rainbow-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1634,
    "newID": "R+Y+G+B+DPr,M5",
    "name": "Muddy-Rainbow-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankAllClueColors": true,
    "specialRankNoClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1167,
    "newID": "R+Y+G+B+P+T,L5",
    "name": "Light-Pink-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1168,
    "newID": "R+Y+G+B+P,L5",
    "name": "Light-Pink-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1169,
    "newID": "R+Y+G+B,L5",
    "name": "Light-Pink-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1170,
    "newID": "R+G+B,L5",
    "name": "Light-Pink-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1340,
    "newID": "R+Y+G+B+P+Bk,L5",
    "name": "Light-Pink-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1341,
    "newID": "R+Y+G+B+Bk,L5",
    "name": "Light-Pink-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1171,
    "newID": "R+Y+G+B+P+Ra,L5",
    "name": "Light-Pink-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1172,
    "newID": "R+Y+G+B+Ra,L5",
    "name": "Light-Pink-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1173,
    "newID": "R+G+B+Ra,L5",
    "name": "Light-Pink-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1174,
    "newID": "R+B+Ra,L5",
    "name": "Light-Pink-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1175,
    "newID": "R+Y+G+B+P+Pi,L5",
    "name": "Light-Pink-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1176,
    "newID": "R+Y+G+B+Pi,L5",
    "name": "Light-Pink-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1177,
    "newID": "R+G+B+Pi,L5",
    "name": "Light-Pink-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1178,
    "newID": "R+B+Pi,L5",
    "name": "Light-Pink-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1179,
    "newID": "R+Y+G+B+P+Wh,L5",
    "name": "Light-Pink-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1180,
    "newID": "R+Y+G+B+Wh,L5",
    "name": "Light-Pink-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1181,
    "newID": "R+G+B+Wh,L5",
    "name": "Light-Pink-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1182,
    "newID": "R+B+Wh,L5",
    "name": "Light-Pink-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1183,
    "newID": "R+Y+G+B+P+Br,L5",
    "name": "Light-Pink-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1184,
    "newID": "R+Y+G+B+Br,L5",
    "name": "Light-Pink-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1185,
    "newID": "R+G+B+Br,L5",
    "name": "Light-Pink-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1186,
    "newID": "R+B+Br,L5",
    "name": "Light-Pink-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1187,
    "newID": "R+Y+G+B+P+Om,L5",
    "name": "Light-Pink-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1188,
    "newID": "R+Y+G+B+Om,L5",
    "name": "Light-Pink-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1189,
    "newID": "R+G+B+Om,L5",
    "name": "Light-Pink-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1190,
    "newID": "R+B+Om,L5",
    "name": "Light-Pink-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1191,
    "newID": "R+Y+G+B+P+Nu,L5",
    "name": "Light-Pink-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1192,
    "newID": "R+Y+G+B+Nu,L5",
    "name": "Light-Pink-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1193,
    "newID": "R+G+B+Nu,L5",
    "name": "Light-Pink-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1194,
    "newID": "R+B+Nu,L5",
    "name": "Light-Pink-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1195,
    "newID": "R+Y+G+B+P+BrRa,L5",
    "name": "Light-Pink-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1196,
    "newID": "R+Y+G+B+BrRa,L5",
    "name": "Light-Pink-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1197,
    "newID": "R+G+B+BrRa,L5",
    "name": "Light-Pink-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1198,
    "newID": "R+B+BrRa,L5",
    "name": "Light-Pink-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1199,
    "newID": "R+Y+G+B+P+WhPi,L5",
    "name": "Light-Pink-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1200,
    "newID": "R+Y+G+B+WhPi,L5",
    "name": "Light-Pink-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1201,
    "newID": "R+G+B+WhPi,L5",
    "name": "Light-Pink-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1202,
    "newID": "R+B+WhPi,L5",
    "name": "Light-Pink-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1635,
    "newID": "R+Y+G+B+P+Pr,L5",
    "name": "Light-Pink-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1636,
    "newID": "R+Y+G+B+Pr,L5",
    "name": "Light-Pink-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1637,
    "newID": "R+G+B+Pr,L5",
    "name": "Light-Pink-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1638,
    "newID": "R+B+Pr,L5",
    "name": "Light-Pink-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1203,
    "newID": "R+Y+G+B+P+DRa,L5",
    "name": "Light-Pink-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1204,
    "newID": "R+Y+G+B+DRa,L5",
    "name": "Light-Pink-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1205,
    "newID": "R+Y+G+B+P+DPi,L5",
    "name": "Light-Pink-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1206,
    "newID": "R+Y+G+B+DPi,L5",
    "name": "Light-Pink-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1207,
    "newID": "R+Y+G+B+P+DWh,L5",
    "name": "Light-Pink-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1208,
    "newID": "R+Y+G+B+DWh,L5",
    "name": "Light-Pink-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1209,
    "newID": "R+Y+G+B+P+DBr,L5",
    "name": "Light-Pink-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1210,
    "newID": "R+Y+G+B+DBr,L5",
    "name": "Light-Pink-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1211,
    "newID": "R+Y+G+B+P+DOm,L5",
    "name": "Light-Pink-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1212,
    "newID": "R+Y+G+B+DOm,L5",
    "name": "Light-Pink-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1213,
    "newID": "R+Y+G+B+P+DNu,L5",
    "name": "Light-Pink-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1214,
    "newID": "R+Y+G+B+DNu,L5",
    "name": "Light-Pink-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1215,
    "newID": "R+Y+G+B+P+DBrRa,L5",
    "name": "Light-Pink-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1216,
    "newID": "R+Y+G+B+DBrRa,L5",
    "name": "Light-Pink-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1217,
    "newID": "R+Y+G+B+P+DWhPi,L5",
    "name": "Light-Pink-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1218,
    "newID": "R+Y+G+B+DWhPi,L5",
    "name": "Light-Pink-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1639,
    "newID": "R+Y+G+B+P+DPr,L5",
    "name": "Light-Pink-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1640,
    "newID": "R+Y+G+B+DPr,L5",
    "name": "Light-Pink-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankNoClueColors": true,
    "specialRankAllClueRanks": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1731,
    "newID": "R+Y+G+B+P+T,D5",
    "name": "Deceptive-Fives (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1732,
    "newID": "R+Y+G+B+P,D5",
    "name": "Deceptive-Fives (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1733,
    "newID": "R+Y+G+B,D5",
    "name": "Deceptive-Fives (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1734,
    "newID": "R+G+B,D5",
    "name": "Deceptive-Fives (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1735,
    "newID": "R+Y+G+B+P+Bk,D5",
    "name": "Deceptive-Fives & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1736,
    "newID": "R+Y+G+B+Bk,D5",
    "name": "Deceptive-Fives & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1737,
    "newID": "R+Y+G+B+P+Ra,D5",
    "name": "Deceptive-Fives & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1738,
    "newID": "R+Y+G+B+Ra,D5",
    "name": "Deceptive-Fives & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1739,
    "newID": "R+G+B+Ra,D5",
    "name": "Deceptive-Fives & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1740,
    "newID": "R+B+Ra,D5",
    "name": "Deceptive-Fives & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1741,
    "newID": "R+Y+G+B+P+Pi,D5",
    "name": "Deceptive-Fives & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1742,
    "newID": "R+Y+G+B+Pi,D5",
    "name": "Deceptive-Fives & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1743,
    "newID": "R+G+B+Pi,D5",
    "name": "Deceptive-Fives & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1744,
    "newID": "R+B+Pi,D5",
    "name": "Deceptive-Fives & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1745,
    "newID": "R+Y+G+B+P+Wh,D5",
    "name": "Deceptive-Fives & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1746,
    "newID": "R+Y+G+B+Wh,D5",
    "name": "Deceptive-Fives & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1747,
    "newID": "R+G+B+Wh,D5",
    "name": "Deceptive-Fives & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1748,
    "newID": "R+B+Wh,D5",
    "name": "Deceptive-Fives & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1749,
    "newID": "R+Y+G+B+P+Br,D5",
    "name": "Deceptive-Fives & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1750,
    "newID": "R+Y+G+B+Br,D5",
    "name": "Deceptive-Fives & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1751,
    "newID": "R+G+B+Br,D5",
    "name": "Deceptive-Fives & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1752,
    "newID": "R+B+Br,D5",
    "name": "Deceptive-Fives & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1753,
    "newID": "R+Y+G+B+P+Om,D5",
    "name": "Deceptive-Fives & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1754,
    "newID": "R+Y+G+B+Om,D5",
    "name": "Deceptive-Fives & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1755,
    "newID": "R+G+B+Om,D5",
    "name": "Deceptive-Fives & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1756,
    "newID": "R+B+Om,D5",
    "name": "Deceptive-Fives & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1757,
    "newID": "R+Y+G+B+P+Nu,D5",
    "name": "Deceptive-Fives & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1758,
    "newID": "R+Y+G+B+Nu,D5",
    "name": "Deceptive-Fives & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1759,
    "newID": "R+G+B+Nu,D5",
    "name": "Deceptive-Fives & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1760,
    "newID": "R+B+Nu,D5",
    "name": "Deceptive-Fives & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1761,
    "newID": "R+Y+G+B+P+BrRa,D5",
    "name": "Deceptive-Fives & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1762,
    "newID": "R+Y+G+B+BrRa,D5",
    "name": "Deceptive-Fives & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1763,
    "newID": "R+G+B+BrRa,D5",
    "name": "Deceptive-Fives & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1764,
    "newID": "R+B+BrRa,D5",
    "name": "Deceptive-Fives & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1765,
    "newID": "R+Y+G+B+P+WhPi,D5",
    "name": "Deceptive-Fives & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1766,
    "newID": "R+Y+G+B+WhPi,D5",
    "name": "Deceptive-Fives & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1767,
    "newID": "R+G+B+WhPi,D5",
    "name": "Deceptive-Fives & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1768,
    "newID": "R+B+WhPi,D5",
    "name": "Deceptive-Fives & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1769,
    "newID": "R+Y+G+B+P+Pr,D5",
    "name": "Deceptive-Fives & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1770,
    "newID": "R+Y+G+B+Pr,D5",
    "name": "Deceptive-Fives & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1771,
    "newID": "R+G+B+Pr,D5",
    "name": "Deceptive-Fives & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1772,
    "newID": "R+B+Pr,D5",
    "name": "Deceptive-Fives & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1773,
    "newID": "R+Y+G+B+P+DRa,D5",
    "name": "Deceptive-Fives & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1774,
    "newID": "R+Y+G+B+DRa,D5",
    "name": "Deceptive-Fives & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1775,
    "newID": "R+Y+G+B+P+DPi,D5",
    "name": "Deceptive-Fives & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1776,
    "newID": "R+Y+G+B+DPi,D5",
    "name": "Deceptive-Fives & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1777,
    "newID": "R+Y+G+B+P+DWh,D5",
    "name": "Deceptive-Fives & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1778,
    "newID": "R+Y+G+B+DWh,D5",
    "name": "Deceptive-Fives & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1779,
    "newID": "R+Y+G+B+P+DBr,D5",
    "name": "Deceptive-Fives & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1780,
    "newID": "R+Y+G+B+DBr,D5",
    "name": "Deceptive-Fives & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1781,
    "newID": "R+Y+G+B+P+DOm,D5",
    "name": "Deceptive-Fives & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1782,
    "newID": "R+Y+G+B+DOm,D5",
    "name": "Deceptive-Fives & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1783,
    "newID": "R+Y+G+B+P+DNu,D5",
    "name": "Deceptive-Fives & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1784,
    "newID": "R+Y+G+B+DNu,D5",
    "name": "Deceptive-Fives & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1785,
    "newID": "R+Y+G+B+P+DBrRa,D5",
    "name": "Deceptive-Fives & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1786,
    "newID": "R+Y+G+B+DBrRa,D5",
    "name": "Deceptive-Fives & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1787,
    "newID": "R+Y+G+B+P+DWhPi,D5",
    "name": "Deceptive-Fives & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1788,
    "newID": "R+Y+G+B+DWhPi,D5",
    "name": "Deceptive-Fives & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1789,
    "newID": "R+Y+G+B+P+DPr,D5",
    "name": "Deceptive-Fives & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 1790,
    "newID": "R+Y+G+B+DPr,D5",
    "name": "Deceptive-Fives & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "specialRank": 5,
    "specialRankDeceptive": true,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 8,
    "newID": "AR1+AR2+AG1+AG2+AB1+AB2",
    "name": "Ambiguous (6 Suits)",
    "suits": ["Tomato", "Mahogany", "Lime", "Forest", "Sky", "Navy"]
  },
  {
    "id": 30,
    "newID": "AR1+AR2+AB1+AB2",
    "name": "Ambiguous (4 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy"]
  },
  {
    "id": 1352,
    "newID": "AR1+AR2+AB1+AB2+Bk",
    "name": "Ambiguous & Black (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Black"]
  },
  {
    "id": 32,
    "newID": "AR1+AR2+AB1+AB2+Ra",
    "name": "Ambiguous & Rainbow (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Rainbow"]
  },
  {
    "id": 1354,
    "newID": "AR1+AR2+AB1+AB2+Pi",
    "name": "Ambiguous & Pink (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Pink"]
  },
  {
    "id": 1355,
    "newID": "AR1+AR2+Pi",
    "name": "Ambiguous & Pink (3 Suits)",
    "suits": ["Tomato", "Mahogany", "Pink"]
  },
  {
    "id": 31,
    "newID": "AR1+AR2+AB1+AB2+Wh",
    "name": "Ambiguous & White (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "White"]
  },
  {
    "id": 1360,
    "newID": "AR1+AR2+Wh",
    "name": "Ambiguous & White (3 Suits)",
    "suits": ["Tomato", "Mahogany", "White"]
  },
  {
    "id": 1361,
    "newID": "AR1+AR2+AB1+AB2+Br",
    "name": "Ambiguous & Brown (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Brown"]
  },
  {
    "id": 1362,
    "newID": "AR1+AR2+Br",
    "name": "Ambiguous & Brown (3 Suits)",
    "suits": ["Tomato", "Mahogany", "Brown"]
  },
  {
    "id": 1363,
    "newID": "AR1+AR2+AB1+AB2+Om",
    "name": "Ambiguous & Omni (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Omni"]
  },
  {
    "id": 1364,
    "newID": "AR1+AR2+Om",
    "name": "Ambiguous & Omni (3 Suits)",
    "suits": ["Tomato", "Mahogany", "Omni"]
  },
  {
    "id": 1365,
    "newID": "AR1+AR2+AB1+AB2+Nu",
    "name": "Ambiguous & Null (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Null"]
  },
  {
    "id": 1366,
    "newID": "AR1+AR2+Nu",
    "name": "Ambiguous & Null (3 Suits)",
    "suits": ["Tomato", "Mahogany", "Null"]
  },
  {
    "id": 1367,
    "newID": "AR1+AR2+AB1+AB2+BrRa",
    "name": "Ambiguous & Muddy Rainbow (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Muddy Rainbow"]
  },
  {
    "id": 1368,
    "newID": "AR1+AR2+BrRa",
    "name": "Ambiguous & Muddy Rainbow (3 Suits)",
    "suits": ["Tomato", "Mahogany", "Muddy Rainbow"]
  },
  {
    "id": 1369,
    "newID": "AR1+AR2+AB1+AB2+WhPi",
    "name": "Ambiguous & Light Pink (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Light Pink"]
  },
  {
    "id": 1370,
    "newID": "AR1+AR2+WhPi",
    "name": "Ambiguous & Light Pink (3 Suits)",
    "suits": ["Tomato", "Mahogany", "Light Pink"]
  },
  {
    "id": 1641,
    "newID": "AR1+AR2+AB1+AB2+Pr",
    "name": "Ambiguous & Prism (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Prism"]
  },
  {
    "id": 1371,
    "newID": "AR1+AR2+AB1+AB2+DRa",
    "name": "Ambiguous & Dark Rainbow (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Dark Rainbow"]
  },
  {
    "id": 1372,
    "newID": "AR1+AR2+AB1+AB2+DPi",
    "name": "Ambiguous & Dark Pink (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Dark Pink"]
  },
  {
    "id": 1373,
    "newID": "AR1+AR2+AB1+AB2+DWh",
    "name": "Ambiguous & Gray (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Gray"]
  },
  {
    "id": 1374,
    "newID": "AR1+AR2+AB1+AB2+DBr",
    "name": "Ambiguous & Dark Brown (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Dark Brown"]
  },
  {
    "id": 1375,
    "newID": "AR1+AR2+AB1+AB2+DOm",
    "name": "Ambiguous & Dark Omni (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Dark Omni"]
  },
  {
    "id": 1376,
    "newID": "AR1+AR2+AB1+AB2+DNu",
    "name": "Ambiguous & Dark Null (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Dark Null"]
  },
  {
    "id": 1377,
    "newID": "AR1+AR2+AB1+AB2+DBrRa",
    "name": "Ambiguous & Cocoa Rainbow (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Cocoa Rainbow"]
  },
  {
    "id": 1378,
    "newID": "AR1+AR2+AB1+AB2+DWhPi",
    "name": "Ambiguous & Gray Pink (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Gray Pink"]
  },
  {
    "id": 1643,
    "newID": "AR1+AR2+AB1+AB2+DPr",
    "name": "Ambiguous & Dark Prism (5 Suits)",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Dark Prism"]
  },
  {
    "id": 9,
    "newID": "VR1+VR2+VR3+VB1+VB2+VB3",
    "name": "Very Ambiguous (6 Suits)",
    "suits": [
      "Tomato VA",
      "Carrot VA",
      "Mahogany VA",
      "Sky VA",
      "Berry VA",
      "Navy VA"
    ]
  },
  {
    "id": 287,
    "newID": "VB1+VB2+VB3",
    "name": "Very Ambiguous (3 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA"]
  },
  {
    "id": 1390,
    "newID": "VB1+VB2+VB3+Pi",
    "name": "Very Ambiguous & Pink (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "Pink"]
  },
  {
    "id": 1393,
    "newID": "VB1+VB2+VB3+Wh",
    "name": "Very Ambiguous & White (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "White"]
  },
  {
    "id": 1396,
    "newID": "VB1+VB2+VB3+Br",
    "name": "Very Ambiguous & Brown (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "Brown"]
  },
  {
    "id": 1399,
    "newID": "VB1+VB2+VB3+Om",
    "name": "Very Ambiguous & Omni (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "Omni"]
  },
  {
    "id": 1402,
    "newID": "VB1+VB2+VB3+Nu",
    "name": "Very Ambiguous & Null (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "Null"]
  },
  {
    "id": 1405,
    "newID": "VB1+VB2+VB3+BrRa",
    "name": "Very Ambiguous & Muddy Rainbow (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "Muddy Rainbow"]
  },
  {
    "id": 1408,
    "newID": "VB1+VB2+VB3+WhPi",
    "name": "Very Ambiguous & Light Pink (4 Suits)",
    "suits": ["Sky VA", "Berry VA", "Navy VA", "Light Pink"]
  },
  {
    "id": 288,
    "newID": "EB1+EB2+EB3+EB4+EB5+EB6",
    "name": "Extremely Ambiguous (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Ocean EA"
    ]
  },
  {
    "id": 289,
    "newID": "EB1+EB2+EB3+EB4+EB5",
    "name": "Extremely Ambiguous (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA"]
  },
  {
    "id": 282,
    "newID": "EB1+EB2+EB3+EB4",
    "name": "Extremely Ambiguous (4 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA"]
  },
  {
    "id": 1379,
    "newID": "EB1+EB2+EB3+EB4+EB5+Bk",
    "name": "Extremely Ambiguous & Black (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "Black"]
  },
  {
    "id": 1382,
    "newID": "EB1+EB2+EB3+EB4+Bk",
    "name": "Extremely Ambiguous & Black (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Black"]
  },
  {
    "id": 1388,
    "newID": "EB1+EB2+EB3+EB4+EB5+Pi",
    "name": "Extremely Ambiguous & Pink (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "Pink"]
  },
  {
    "id": 1389,
    "newID": "EB1+EB2+EB3+EB4+Pi",
    "name": "Extremely Ambiguous & Pink (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Pink"]
  },
  {
    "id": 1391,
    "newID": "EB1+EB2+EB3+EB4+EB5+Wh",
    "name": "Extremely Ambiguous & White (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "White"]
  },
  {
    "id": 1392,
    "newID": "EB1+EB2+EB3+EB4+Wh",
    "name": "Extremely Ambiguous & White (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "White"]
  },
  {
    "id": 1394,
    "newID": "EB1+EB2+EB3+EB4+EB5+Br",
    "name": "Extremely Ambiguous & Brown (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "Brown"]
  },
  {
    "id": 1395,
    "newID": "EB1+EB2+EB3+EB4+Br",
    "name": "Extremely Ambiguous & Brown (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Brown"]
  },
  {
    "id": 1397,
    "newID": "EB1+EB2+EB3+EB4+EB5+Om",
    "name": "Extremely Ambiguous & Omni (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "Omni"]
  },
  {
    "id": 1398,
    "newID": "EB1+EB2+EB3+EB4+Om",
    "name": "Extremely Ambiguous & Omni (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Omni"]
  },
  {
    "id": 1400,
    "newID": "EB1+EB2+EB3+EB4+EB5+Nu",
    "name": "Extremely Ambiguous & Null (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "Null"]
  },
  {
    "id": 1401,
    "newID": "EB1+EB2+EB3+EB4+Nu",
    "name": "Extremely Ambiguous & Null (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Null"]
  },
  {
    "id": 1403,
    "newID": "EB1+EB2+EB3+EB4+EB5+BrRa",
    "name": "Extremely Ambiguous & Muddy Rainbow (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Muddy Rainbow"
    ]
  },
  {
    "id": 1404,
    "newID": "EB1+EB2+EB3+EB4+BrRa",
    "name": "Extremely Ambiguous & Muddy Rainbow (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Muddy Rainbow"]
  },
  {
    "id": 1406,
    "newID": "EB1+EB2+EB3+EB4+EB5+WhPi",
    "name": "Extremely Ambiguous & Light Pink (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Light Pink"
    ]
  },
  {
    "id": 1407,
    "newID": "EB1+EB2+EB3+EB4+WhPi",
    "name": "Extremely Ambiguous & Light Pink (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Light Pink"]
  },
  {
    "id": 1409,
    "newID": "EB1+EB2+EB3+EB4+EB5+DRa",
    "name": "Extremely Ambiguous & Dark Rainbow (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Dark Rainbow"
    ]
  },
  {
    "id": 1410,
    "newID": "EB1+EB2+EB3+EB4+DRa",
    "name": "Extremely Ambiguous & Dark Rainbow (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Dark Rainbow"]
  },
  {
    "id": 1411,
    "newID": "EB1+EB2+EB3+EB4+EB5+DPi",
    "name": "Extremely Ambiguous & Dark Pink (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Dark Pink"
    ]
  },
  {
    "id": 1412,
    "newID": "EB1+EB2+EB3+EB4+DPi",
    "name": "Extremely Ambiguous & Dark Pink (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Dark Pink"]
  },
  {
    "id": 1413,
    "newID": "EB1+EB2+EB3+EB4+EB5+DWh",
    "name": "Extremely Ambiguous & Gray (6 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Navy EA", "Gray"]
  },
  {
    "id": 1414,
    "newID": "EB1+EB2+EB3+EB4+DWh",
    "name": "Extremely Ambiguous & Gray (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Gray"]
  },
  {
    "id": 1415,
    "newID": "EB1+EB2+EB3+EB4+EB5+DBr",
    "name": "Extremely Ambiguous & Dark Brown (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Dark Brown"
    ]
  },
  {
    "id": 1416,
    "newID": "EB1+EB2+EB3+EB4+DBr",
    "name": "Extremely Ambiguous & Dark Brown (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Dark Brown"]
  },
  {
    "id": 1417,
    "newID": "EB1+EB2+EB3+EB4+EB5+DOm",
    "name": "Extremely Ambiguous & Dark Omni (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Dark Omni"
    ]
  },
  {
    "id": 1418,
    "newID": "EB1+EB2+EB3+EB4+DOm",
    "name": "Extremely Ambiguous & Dark Omni (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Dark Omni"]
  },
  {
    "id": 1419,
    "newID": "EB1+EB2+EB3+EB4+EB5+DNu",
    "name": "Extremely Ambiguous & Dark Null (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Dark Null"
    ]
  },
  {
    "id": 1420,
    "newID": "EB1+EB2+EB3+EB4+DNu",
    "name": "Extremely Ambiguous & Dark Null (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Dark Null"]
  },
  {
    "id": 1421,
    "newID": "EB1+EB2+EB3+EB4+EB5+DBrRa",
    "name": "Extremely Ambiguous & Cocoa Rainbow (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Cocoa Rainbow"
    ]
  },
  {
    "id": 1422,
    "newID": "EB1+EB2+EB3+EB4+DBrRa",
    "name": "Extremely Ambiguous & Cocoa Rainbow (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Cocoa Rainbow"]
  },
  {
    "id": 1423,
    "newID": "EB1+EB2+EB3+EB4+EB5+DWhPi",
    "name": "Extremely Ambiguous & Gray Pink (6 Suits)",
    "suits": [
      "Ice EA",
      "Sapphire EA",
      "Sky EA",
      "Berry EA",
      "Navy EA",
      "Gray Pink"
    ]
  },
  {
    "id": 1424,
    "newID": "EB1+EB2+EB3+EB4+DWhPi",
    "name": "Extremely Ambiguous & Gray Pink (5 Suits)",
    "suits": ["Ice EA", "Sapphire EA", "Sky EA", "Berry EA", "Gray Pink"]
  },
  {
    "id": 150,
    "newID": "R+MDY+MDG+MDB+MDP+MDT",
    "name": "Matryoshka (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Taupe MD"]
  },
  {
    "id": 193,
    "newID": "R+MDY+MDG+MDB+MDP",
    "name": "Matryoshka (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD"]
  },
  {
    "id": 197,
    "newID": "R+MDY+MDG+MDB",
    "name": "Matryoshka (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD"]
  },
  {
    "id": 785,
    "newID": "R+MDY+MDG",
    "name": "Matryoshka (3 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD"]
  },
  {
    "id": 1295,
    "newID": "R+MDY+MDG+MDB+MDP+Bk",
    "name": "Matryoshka & Black (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Black"]
  },
  {
    "id": 1476,
    "newID": "R+MDY+MDG+MDB+Bk",
    "name": "Matryoshka & Black (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Black"]
  },
  {
    "id": 1488,
    "newID": "R+MDY+MDG+MDB+MDP+Ra",
    "name": "Matryoshka & Rainbow (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Rainbow"]
  },
  {
    "id": 1500,
    "newID": "R+MDY+MDG+MDB+Ra",
    "name": "Matryoshka & Rainbow (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Rainbow"]
  },
  {
    "id": 1506,
    "newID": "R+MDY+MDG+Ra",
    "name": "Matryoshka & Rainbow (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Rainbow"]
  },
  {
    "id": 1512,
    "newID": "R+MDY+Ra",
    "name": "Matryoshka & Rainbow (3 Suits)",
    "suits": ["Red", "Yam MD", "Rainbow"]
  },
  {
    "id": 1518,
    "newID": "R+MDY+MDG+MDB+MDP+Pi",
    "name": "Matryoshka & Pink (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Pink"]
  },
  {
    "id": 2020,
    "newID": "R+MDY+MDG+MDB+Pi",
    "name": "Matryoshka & Pink (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Pink"]
  },
  {
    "id": 2021,
    "newID": "R+MDY+MDG+Pi",
    "name": "Matryoshka & Pink (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Pink"]
  },
  {
    "id": 2022,
    "newID": "R+MDY+Pi",
    "name": "Matryoshka & Pink (3 Suits)",
    "suits": ["Red", "Yam MD", "Pink"]
  },
  {
    "id": 2023,
    "newID": "R+MDY+MDG+MDB+MDP+Wh",
    "name": "Matryoshka & White (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "White"]
  },
  {
    "id": 2024,
    "newID": "R+MDY+MDG+MDB+Wh",
    "name": "Matryoshka & White (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "White"]
  },
  {
    "id": 2025,
    "newID": "R+MDY+MDG+Wh",
    "name": "Matryoshka & White (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "White"]
  },
  {
    "id": 2026,
    "newID": "R+MDY+Wh",
    "name": "Matryoshka & White (3 Suits)",
    "suits": ["Red", "Yam MD", "White"]
  },
  {
    "id": 2027,
    "newID": "R+MDY+MDG+MDB+MDP+Br",
    "name": "Matryoshka & Brown (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Brown"]
  },
  {
    "id": 2028,
    "newID": "R+MDY+MDG+MDB+Br",
    "name": "Matryoshka & Brown (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Brown"]
  },
  {
    "id": 2029,
    "newID": "R+MDY+MDG+Br",
    "name": "Matryoshka & Brown (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Brown"]
  },
  {
    "id": 2030,
    "newID": "R+MDY+Br",
    "name": "Matryoshka & Brown (3 Suits)",
    "suits": ["Red", "Yam MD", "Brown"]
  },
  {
    "id": 2031,
    "newID": "R+MDY+MDG+MDB+MDP+Om",
    "name": "Matryoshka & Omni (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Omni"]
  },
  {
    "id": 2032,
    "newID": "R+MDY+MDG+MDB+Om",
    "name": "Matryoshka & Omni (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Omni"]
  },
  {
    "id": 2033,
    "newID": "R+MDY+MDG+Om",
    "name": "Matryoshka & Omni (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Omni"]
  },
  {
    "id": 2034,
    "newID": "R+MDY+Om",
    "name": "Matryoshka & Omni (3 Suits)",
    "suits": ["Red", "Yam MD", "Omni"]
  },
  {
    "id": 2035,
    "newID": "R+MDY+MDG+MDB+MDP+Nu",
    "name": "Matryoshka & Null (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Null"]
  },
  {
    "id": 2036,
    "newID": "R+MDY+MDG+MDB+Nu",
    "name": "Matryoshka & Null (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Null"]
  },
  {
    "id": 2037,
    "newID": "R+MDY+MDG+Nu",
    "name": "Matryoshka & Null (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Null"]
  },
  {
    "id": 2038,
    "newID": "R+MDY+Nu",
    "name": "Matryoshka & Null (3 Suits)",
    "suits": ["Red", "Yam MD", "Null"]
  },
  {
    "id": 2039,
    "newID": "R+MDY+MDG+MDB+MDP+BrRa",
    "name": "Matryoshka & Muddy Rainbow (6 Suits)",
    "suits": [
      "Red",
      "Yam MD",
      "Geas MD",
      "Beatnik MD",
      "Plum MD",
      "Muddy Rainbow"
    ]
  },
  {
    "id": 2040,
    "newID": "R+MDY+MDG+MDB+BrRa",
    "name": "Matryoshka & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Muddy Rainbow"]
  },
  {
    "id": 2041,
    "newID": "R+MDY+MDG+BrRa",
    "name": "Matryoshka & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Muddy Rainbow"]
  },
  {
    "id": 2042,
    "newID": "R+MDY+BrRa",
    "name": "Matryoshka & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Yam MD", "Muddy Rainbow"]
  },
  {
    "id": 2043,
    "newID": "R+MDY+MDG+MDB+MDP+WhPi",
    "name": "Matryoshka & Light Pink (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Light Pink"]
  },
  {
    "id": 2044,
    "newID": "R+MDY+MDG+MDB+WhPi",
    "name": "Matryoshka & Light Pink (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Light Pink"]
  },
  {
    "id": 2045,
    "newID": "R+MDY+MDG+WhPi",
    "name": "Matryoshka & Light Pink (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Light Pink"]
  },
  {
    "id": 2046,
    "newID": "R+MDY+WhPi",
    "name": "Matryoshka & Light Pink (3 Suits)",
    "suits": ["Red", "Yam MD", "Light Pink"]
  },
  {
    "id": 2047,
    "newID": "R+MDY+MDG+MDB+MDP+Pr",
    "name": "Matryoshka & Prism (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Prism"]
  },
  {
    "id": 2048,
    "newID": "R+MDY+MDG+MDB+Pr",
    "name": "Matryoshka & Prism (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Prism"]
  },
  {
    "id": 2049,
    "newID": "R+MDY+MDG+Pr",
    "name": "Matryoshka & Prism (4 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Prism"]
  },
  {
    "id": 2050,
    "newID": "R+MDY+Pr",
    "name": "Matryoshka & Prism (3 Suits)",
    "suits": ["Red", "Yam MD", "Prism"]
  },
  {
    "id": 2051,
    "newID": "R+MDY+MDG+MDB+MDP+DRa",
    "name": "Matryoshka & Dark Rainbow (6 Suits)",
    "suits": [
      "Red",
      "Yam MD",
      "Geas MD",
      "Beatnik MD",
      "Plum MD",
      "Dark Rainbow"
    ]
  },
  {
    "id": 2052,
    "newID": "R+MDY+MDG+MDB+DRa",
    "name": "Matryoshka & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Dark Rainbow"]
  },
  {
    "id": 2053,
    "newID": "R+MDY+MDG+MDB+MDP+DPi",
    "name": "Matryoshka & Dark Pink (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Dark Pink"]
  },
  {
    "id": 2054,
    "newID": "R+MDY+MDG+MDB+DPi",
    "name": "Matryoshka & Dark Pink (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Dark Pink"]
  },
  {
    "id": 2055,
    "newID": "R+MDY+MDG+MDB+MDP+DWh",
    "name": "Matryoshka & Gray (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Gray"]
  },
  {
    "id": 2056,
    "newID": "R+MDY+MDG+MDB+DWh",
    "name": "Matryoshka & Gray (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Gray"]
  },
  {
    "id": 2057,
    "newID": "R+MDY+MDG+MDB+MDP+DBr",
    "name": "Matryoshka & Dark Brown (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Dark Brown"]
  },
  {
    "id": 2058,
    "newID": "R+MDY+MDG+MDB+DBr",
    "name": "Matryoshka & Dark Brown (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Dark Brown"]
  },
  {
    "id": 2059,
    "newID": "R+MDY+MDG+MDB+MDP+DOm",
    "name": "Matryoshka & Dark Omni (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Dark Omni"]
  },
  {
    "id": 2060,
    "newID": "R+MDY+MDG+MDB+DOm",
    "name": "Matryoshka & Dark Omni (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Dark Omni"]
  },
  {
    "id": 2061,
    "newID": "R+MDY+MDG+MDB+MDP+DNu",
    "name": "Matryoshka & Dark Null (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Dark Null"]
  },
  {
    "id": 2062,
    "newID": "R+MDY+MDG+MDB+DNu",
    "name": "Matryoshka & Dark Null (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Dark Null"]
  },
  {
    "id": 2063,
    "newID": "R+MDY+MDG+MDB+MDP+DBrRa",
    "name": "Matryoshka & Cocoa Rainbow (6 Suits)",
    "suits": [
      "Red",
      "Yam MD",
      "Geas MD",
      "Beatnik MD",
      "Plum MD",
      "Cocoa Rainbow"
    ]
  },
  {
    "id": 2064,
    "newID": "R+MDY+MDG+MDB+DBrRa",
    "name": "Matryoshka & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Cocoa Rainbow"]
  },
  {
    "id": 2065,
    "newID": "R+MDY+MDG+MDB+MDP+DWhPi",
    "name": "Matryoshka & Gray Pink (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Gray Pink"]
  },
  {
    "id": 2066,
    "newID": "R+MDY+MDG+MDB+DWhPi",
    "name": "Matryoshka & Gray Pink (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Gray Pink"]
  },
  {
    "id": 2067,
    "newID": "R+MDY+MDG+MDB+MDP+DPr",
    "name": "Matryoshka & Dark Prism (6 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Plum MD", "Dark Prism"]
  },
  {
    "id": 2068,
    "newID": "R+MDY+MDG+MDB+DPr",
    "name": "Matryoshka & Dark Prism (5 Suits)",
    "suits": ["Red", "Yam MD", "Geas MD", "Beatnik MD", "Dark Prism"]
  },
  {
    "id": 4,
    "newID": "DRY+DRB+DRK+DYB+DYB2+DBK",
    "name": "Dual-Color (6 Suits)",
    "suits": [
      "Orange D",
      "Purple D",
      "Mahogany D",
      "Green D",
      "Tan D",
      "Navy D"
    ]
  },
  {
    "id": 36,
    "newID": "DRY2+DYG+DGB+DBP+DPR",
    "name": "Dual-Color (5 Suits)",
    "suits": ["Orange D2", "Lime D", "Teal D", "Indigo D", "Cardinal D"]
  },
  {
    "id": 37,
    "newID": "DRY2+DRB+DYB",
    "name": "Dual-Color (3 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D"]
  },
  {
    "id": 1425,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Bk",
    "name": "Dual-Color & Black (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Black"
    ]
  },
  {
    "id": 5,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Ra",
    "name": "Dual-Color & Rainbow (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Rainbow"
    ]
  },
  {
    "id": 38,
    "newID": "DRY2+DRB+DYB+Ra",
    "name": "Dual-Color & Rainbow (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Rainbow"]
  },
  {
    "id": 1426,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Pi",
    "name": "Dual-Color & Pink (6 Suits)",
    "suits": ["Orange D2", "Lime D", "Teal D", "Indigo D", "Cardinal D", "Pink"]
  },
  {
    "id": 1427,
    "newID": "DRY2+DRB+DYB+Pi",
    "name": "Dual-Color & Pink (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Pink"]
  },
  {
    "id": 1428,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Wh",
    "name": "Dual-Color & White (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "White"
    ]
  },
  {
    "id": 1429,
    "newID": "DRY2+DRB+DYB+Wh",
    "name": "Dual-Color & White (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "White"]
  },
  {
    "id": 1430,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Br",
    "name": "Dual-Color & Brown (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Brown"
    ]
  },
  {
    "id": 1431,
    "newID": "DRY2+DRB+DYB+Br",
    "name": "Dual-Color & Brown (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Brown"]
  },
  {
    "id": 1432,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Om",
    "name": "Dual-Color & Omni (6 Suits)",
    "suits": ["Orange D2", "Lime D", "Teal D", "Indigo D", "Cardinal D", "Omni"]
  },
  {
    "id": 1433,
    "newID": "DRY2+DRB+DYB+Om",
    "name": "Dual-Color & Omni (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Omni"]
  },
  {
    "id": 1434,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Nu",
    "name": "Dual-Color & Null (6 Suits)",
    "suits": ["Orange D2", "Lime D", "Teal D", "Indigo D", "Cardinal D", "Null"]
  },
  {
    "id": 1435,
    "newID": "DRY2+DRB+DYB+Nu",
    "name": "Dual-Color & Null (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Null"]
  },
  {
    "id": 1436,
    "newID": "DRY2+DYG+DGB+DBP+DPR+BrRa",
    "name": "Dual-Color & Muddy Rainbow (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Muddy Rainbow"
    ]
  },
  {
    "id": 1437,
    "newID": "DRY2+DRB+DYB+BrRa",
    "name": "Dual-Color & Muddy Rainbow (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Muddy Rainbow"]
  },
  {
    "id": 1438,
    "newID": "DRY2+DYG+DGB+DBP+DPR+WhPi",
    "name": "Dual-Color & Light Pink (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Light Pink"
    ]
  },
  {
    "id": 1439,
    "newID": "DRY2+DRB+DYB+WhPi",
    "name": "Dual-Color & Light Pink (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Light Pink"]
  },
  {
    "id": 1649,
    "newID": "DRY2+DYG+DGB+DBP+DPR+Pr",
    "name": "Dual-Color & Prism (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Prism"
    ]
  },
  {
    "id": 1650,
    "newID": "DRY2+DRB+DYB+Pr",
    "name": "Dual-Color & Prism (4 Suits)",
    "suits": ["Orange D2", "Purple D", "Green D", "Prism"]
  },
  {
    "id": 1440,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DRa",
    "name": "Dual-Color & Dark Rainbow (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Dark Rainbow"
    ]
  },
  {
    "id": 1441,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DPi",
    "name": "Dual-Color & Dark Pink (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Dark Pink"
    ]
  },
  {
    "id": 1442,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DWh",
    "name": "Dual-Color & Gray (6 Suits)",
    "suits": ["Orange D2", "Lime D", "Teal D", "Indigo D", "Cardinal D", "Gray"]
  },
  {
    "id": 1443,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DBr",
    "name": "Dual-Color & Dark Brown (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Dark Brown"
    ]
  },
  {
    "id": 1444,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DOm",
    "name": "Dual-Color & Dark Omni (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Dark Omni"
    ]
  },
  {
    "id": 1445,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DNu",
    "name": "Dual-Color & Dark Null (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Dark Null"
    ]
  },
  {
    "id": 1446,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DBrRa",
    "name": "Dual-Color & Cocoa Rainbow (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Cocoa Rainbow"
    ]
  },
  {
    "id": 1447,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DWhPi",
    "name": "Dual-Color & Gray Pink (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Gray Pink"
    ]
  },
  {
    "id": 1651,
    "newID": "DRY2+DYG+DGB+DBP+DPR+DPr",
    "name": "Dual-Color & Dark Prism (6 Suits)",
    "suits": [
      "Orange D2",
      "Lime D",
      "Teal D",
      "Indigo D",
      "Cardinal D",
      "Dark Prism"
    ]
  },
  {
    "id": 159,
    "newID": "Bk+Ra+Pi+Wh+Br",
    "name": "Special Mix (5 Suits)",
    "suits": ["Black", "Rainbow", "Pink", "White", "Brown"]
  },
  {
    "id": 185,
    "newID": "Bk+Ra+Pi+Wh+Br+Nu",
    "name": "Special Mix (6 Suits)",
    "suits": ["Black", "Rainbow", "Pink", "White", "Brown", "Null"]
  },
  {
    "id": 20,
    "newID": "AR1+AR2+AB1+AB2+Bk+Wh",
    "name": "Ambiguous Mix",
    "suits": ["Tomato", "Mahogany", "Sky", "Navy", "Black", "White"]
  },
  {
    "id": 7,
    "newID": "DRY2+DRB+DYB+Bk+Ra+Wh",
    "name": "Dual-Color Mix",
    "suits": ["Orange D2", "Purple D", "Green D", "Black", "Rainbow", "White"]
  },
  {
    "id": 184,
    "newID": "DRY3+DRY4+DRB2+DRB3+DYB3+DYB4",
    "name": "Ambiguous & Dual-Color",
    "suits": [
      "Tangelo AD",
      "Peach AD",
      "Orchid AD",
      "Violet AD",
      "Lime AD",
      "Forest AD"
    ]
  },
  {
    "id": 2016,
    "newID": "R+DRY2+Y+Wh+Br",
    "name": "Candy Corn Mix (5 Suits)",
    "suits": ["Red", "Orange D2", "Yellow", "White", "Brown"]
  },
  {
    "id": 2017,
    "newID": "R+DRY2+Y+Wh+Br+DBrRa",
    "name": "Candy Corn Mix (6 Suits)",
    "suits": ["Red", "Orange D2", "Yellow", "White", "Brown", "Cocoa Rainbow"]
  },
  {
    "id": 2018,
    "newID": "DPi+G+Wh+AB1+AB2",
    "name": "Holiday Mix (5 Suits)",
    "suits": ["Dark Pink", "Green", "White", "Sky", "Navy"]
  },
  {
    "id": 2019,
    "newID": "WhPi+R+G+Wh+AB1+AB2",
    "name": "Holiday Mix (6 Suits)",
    "suits": ["Light Pink", "Red", "Green", "White", "Sky", "Navy"]
  },
  {
    "id": 2069,
    "newID": "R+Pi+Wh+WhPi+DBrRa",
    "name": "Valentine Mix (5 Suits)",
    "suits": ["Red", "Pink", "White", "Light Pink", "Cocoa Rainbow"]
  },
  {
    "id": 2070,
    "newID": "R+Pi+Wh+WhPi+DBrRa+Nu",
    "name": "Valentine Mix (6 Suits)",
    "suits": ["Red", "Pink", "White", "Light Pink", "Cocoa Rainbow", "Null"]
  },
  {
    "id": 2071,
    "newID": "R+YD+G+TD+B+DRB",
    "name": "RGB Mix (6 Suits)",
    "suits": ["Red", "Yellow D", "Green", "Teal D2", "Blue", "Purple D"]
  },
  {
    "id": 1821,
    "newID": "R+Y+G+B+P+T,C4",
    "name": "Critical Fours (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "criticalRank": 4
  },
  {
    "id": 1822,
    "newID": "R+Y+G+B+P,C4",
    "name": "Critical Fours (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "criticalRank": 4
  },
  {
    "id": 1645,
    "newID": "R+Y+G+B+P+Bk,C4",
    "name": "Critical Fours & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "criticalRank": 4
  },
  {
    "id": 1823,
    "newID": "R+Y+G+B+P+Ra,C4",
    "name": "Critical Fours & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "criticalRank": 4
  },
  {
    "id": 1824,
    "newID": "R+Y+G+B+Ra,C4",
    "name": "Critical Fours & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "criticalRank": 4
  },
  {
    "id": 1825,
    "newID": "R+Y+G+B+P+Pi,C4",
    "name": "Critical Fours & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "criticalRank": 4
  },
  {
    "id": 1826,
    "newID": "R+Y+G+B+Pi,C4",
    "name": "Critical Fours & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "criticalRank": 4
  },
  {
    "id": 1827,
    "newID": "R+Y+G+B+P+Wh,C4",
    "name": "Critical Fours & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "criticalRank": 4
  },
  {
    "id": 1828,
    "newID": "R+Y+G+B+Wh,C4",
    "name": "Critical Fours & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "criticalRank": 4
  },
  {
    "id": 1829,
    "newID": "R+Y+G+B+P+Br,C4",
    "name": "Critical Fours & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "criticalRank": 4
  },
  {
    "id": 1830,
    "newID": "R+Y+G+B+Br,C4",
    "name": "Critical Fours & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "criticalRank": 4
  },
  {
    "id": 1831,
    "newID": "R+Y+G+B+P+Om,C4",
    "name": "Critical Fours & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "criticalRank": 4
  },
  {
    "id": 1832,
    "newID": "R+Y+G+B+Om,C4",
    "name": "Critical Fours & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "criticalRank": 4
  },
  {
    "id": 1833,
    "newID": "R+Y+G+B+P+Nu,C4",
    "name": "Critical Fours & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "criticalRank": 4
  },
  {
    "id": 1834,
    "newID": "R+Y+G+B+Nu,C4",
    "name": "Critical Fours & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "criticalRank": 4
  },
  {
    "id": 1835,
    "newID": "R+Y+G+B+P+BrRa,C4",
    "name": "Critical Fours & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "criticalRank": 4
  },
  {
    "id": 1836,
    "newID": "R+Y+G+B+BrRa,C4",
    "name": "Critical Fours & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "criticalRank": 4
  },
  {
    "id": 1837,
    "newID": "R+Y+G+B+P+WhPi,C4",
    "name": "Critical Fours & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "criticalRank": 4
  },
  {
    "id": 1838,
    "newID": "R+Y+G+B+WhPi,C4",
    "name": "Critical Fours & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "criticalRank": 4
  },
  {
    "id": 1839,
    "newID": "R+Y+G+B+P+Pr,C4",
    "name": "Critical Fours & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "criticalRank": 4
  },
  {
    "id": 1840,
    "newID": "R+Y+G+B+Pr,C4",
    "name": "Critical Fours & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "criticalRank": 4
  },
  {
    "id": 1807,
    "newID": "R+Y+G+B+P+DRa,C4",
    "name": "Critical Fours & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "criticalRank": 4
  },
  {
    "id": 1811,
    "newID": "R+Y+G+B+P+DPi,C4",
    "name": "Critical Fours & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "criticalRank": 4
  },
  {
    "id": 1815,
    "newID": "R+Y+G+B+P+DWh,C4",
    "name": "Critical Fours & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "criticalRank": 4
  },
  {
    "id": 1819,
    "newID": "R+Y+G+B+P+DBr,C4",
    "name": "Critical Fours & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "criticalRank": 4
  },
  {
    "id": 2102,
    "newID": "R+Y+G+B+P+DOm,C4",
    "name": "Critical Fours & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "criticalRank": 4
  },
  {
    "id": 2103,
    "newID": "R+Y+G+B+P+DNu,C4",
    "name": "Critical Fours & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "criticalRank": 4
  },
  {
    "id": 2104,
    "newID": "R+Y+G+B+P+DBrRa,C4",
    "name": "Critical Fours & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "criticalRank": 4
  },
  {
    "id": 2105,
    "newID": "R+Y+G+B+P+DWhPi,C4",
    "name": "Critical Fours & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "criticalRank": 4
  },
  {
    "id": 2106,
    "newID": "R+Y+G+B+P+DPr,C4",
    "name": "Critical Fours & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "criticalRank": 4
  },
  {
    "id": 51,
    "newID": "R+Y+G+B+P+T,CS",
    "name": "Clue Starved (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "clueStarved": true
  },
  {
    "id": 52,
    "newID": "R+Y+G+B+P,CS",
    "name": "Clue Starved (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "clueStarved": true
  },
  {
    "id": 53,
    "newID": "R+Y+G+B+P+Ra,CS",
    "name": "Clue Starved & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "clueStarved": true
  },
  {
    "id": 1278,
    "newID": "R+Y+G+B+Ra,CS",
    "name": "Clue Starved & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "clueStarved": true
  },
  {
    "id": 1279,
    "newID": "R+Y+G+B+P+Pi,CS",
    "name": "Clue Starved & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "clueStarved": true
  },
  {
    "id": 1280,
    "newID": "R+Y+G+B+Pi,CS",
    "name": "Clue Starved & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "clueStarved": true
  },
  {
    "id": 1281,
    "newID": "R+Y+G+B+P+Wh,CS",
    "name": "Clue Starved & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "clueStarved": true
  },
  {
    "id": 1282,
    "newID": "R+Y+G+B+Wh,CS",
    "name": "Clue Starved & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "clueStarved": true
  },
  {
    "id": 1283,
    "newID": "R+Y+G+B+P+Br,CS",
    "name": "Clue Starved & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "clueStarved": true
  },
  {
    "id": 1284,
    "newID": "R+Y+G+B+Br,CS",
    "name": "Clue Starved & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "clueStarved": true
  },
  {
    "id": 1285,
    "newID": "R+Y+G+B+P+Om,CS",
    "name": "Clue Starved & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "clueStarved": true
  },
  {
    "id": 1286,
    "newID": "R+Y+G+B+Om,CS",
    "name": "Clue Starved & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "clueStarved": true
  },
  {
    "id": 1287,
    "newID": "R+Y+G+B+P+Nu,CS",
    "name": "Clue Starved & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "clueStarved": true
  },
  {
    "id": 1288,
    "newID": "R+Y+G+B+Nu,CS",
    "name": "Clue Starved & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "clueStarved": true
  },
  {
    "id": 1289,
    "newID": "R+Y+G+B+P+BrRa,CS",
    "name": "Clue Starved & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "clueStarved": true
  },
  {
    "id": 1290,
    "newID": "R+Y+G+B+BrRa,CS",
    "name": "Clue Starved & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "clueStarved": true
  },
  {
    "id": 1291,
    "newID": "R+Y+G+B+P+WhPi,CS",
    "name": "Clue Starved & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "clueStarved": true
  },
  {
    "id": 1219,
    "newID": "R+Y+G+B+WhPi,CS",
    "name": "Clue Starved & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "clueStarved": true
  },
  {
    "id": 1658,
    "newID": "R+Y+G+B+P+Pr,CS",
    "name": "Clue Starved & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "clueStarved": true
  },
  {
    "id": 1659,
    "newID": "R+Y+G+B+Pr,CS",
    "name": "Clue Starved & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "clueStarved": true
  },
  {
    "id": 10,
    "newID": "R+Y+G+B+P+T,CB",
    "name": "Color Blind (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "colorCluesTouchNothing": true
  },
  {
    "id": 33,
    "newID": "R+Y+G+B+P,CB",
    "name": "Color Blind (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "colorCluesTouchNothing": true
  },
  {
    "id": 34,
    "newID": "R+Y+G+B,CB",
    "name": "Color Blind (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "colorCluesTouchNothing": true
  },
  {
    "id": 35,
    "newID": "R+G+B,CB",
    "name": "Color Blind (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "colorCluesTouchNothing": true
  },
  {
    "id": 102,
    "newID": "R+Y+G+B+P+T,NB",
    "name": "Number Blind (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "rankCluesTouchNothing": true
  },
  {
    "id": 103,
    "newID": "R+Y+G+B+P,NB",
    "name": "Number Blind (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "rankCluesTouchNothing": true
  },
  {
    "id": 104,
    "newID": "R+Y+G+B,NB",
    "name": "Number Blind (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "rankCluesTouchNothing": true
  },
  {
    "id": 105,
    "newID": "R+G+B,NB",
    "name": "Number Blind (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "rankCluesTouchNothing": true
  },
  {
    "id": 151,
    "newID": "R+Y+G+B+P+T,TB",
    "name": "Totally Blind (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "colorCluesTouchNothing": true,
    "rankCluesTouchNothing": true
  },
  {
    "id": 152,
    "newID": "R+Y+G+B+P,TB",
    "name": "Totally Blind (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "colorCluesTouchNothing": true,
    "rankCluesTouchNothing": true
  },
  {
    "id": 153,
    "newID": "R+Y+G+B,TB",
    "name": "Totally Blind (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "colorCluesTouchNothing": true,
    "rankCluesTouchNothing": true
  },
  {
    "id": 154,
    "newID": "R+G+B,TB",
    "name": "Totally Blind (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "colorCluesTouchNothing": true,
    "rankCluesTouchNothing": true
  },
  {
    "id": 110,
    "newID": "R+Y+G+B+P+T,CM",
    "name": "Color Mute (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "clueColors": []
  },
  {
    "id": 111,
    "newID": "R+Y+G+B+P,CM",
    "name": "Color Mute (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "clueColors": []
  },
  {
    "id": 112,
    "newID": "R+Y+G+B,CM",
    "name": "Color Mute (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "clueColors": []
  },
  {
    "id": 113,
    "newID": "R+G+B,CM",
    "name": "Color Mute (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "clueColors": []
  },
  {
    "id": 114,
    "newID": "R+Y+G+B+P+T,NM",
    "name": "Number Mute (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "clueRanks": []
  },
  {
    "id": 115,
    "newID": "R+Y+G+B+P,NM",
    "name": "Number Mute (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "clueRanks": []
  },
  {
    "id": 116,
    "newID": "R+Y+G+B,NM",
    "name": "Number Mute (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "clueRanks": []
  },
  {
    "id": 117,
    "newID": "R+G+B,NM",
    "name": "Number Mute (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "clueRanks": []
  },
  {
    "id": 283,
    "newID": "R+Y+G+B+P+T,AC",
    "name": "Alternating Clues (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "alternatingClues": true
  },
  {
    "id": 284,
    "newID": "R+Y+G+B+P,AC",
    "name": "Alternating Clues (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "alternatingClues": true
  },
  {
    "id": 285,
    "newID": "R+Y+G+B,AC",
    "name": "Alternating Clues (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "alternatingClues": true
  },
  {
    "id": 286,
    "newID": "R+G+B,AC",
    "name": "Alternating Clues (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "alternatingClues": true
  },
  {
    "id": 1244,
    "newID": "R+Y+G+B+P+Bk,AC",
    "name": "Alternating Clues & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "alternatingClues": true
  },
  {
    "id": 1245,
    "newID": "R+Y+G+B+Bk,AC",
    "name": "Alternating Clues & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "alternatingClues": true
  },
  {
    "id": 1246,
    "newID": "R+Y+G+B+P+Ra,AC",
    "name": "Alternating Clues & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1247,
    "newID": "R+Y+G+B+Ra,AC",
    "name": "Alternating Clues & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1248,
    "newID": "R+G+B+Ra,AC",
    "name": "Alternating Clues & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1249,
    "newID": "R+B+Ra,AC",
    "name": "Alternating Clues & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1250,
    "newID": "R+Y+G+B+P+Pi,AC",
    "name": "Alternating Clues & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "alternatingClues": true
  },
  {
    "id": 1251,
    "newID": "R+Y+G+B+Pi,AC",
    "name": "Alternating Clues & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "alternatingClues": true
  },
  {
    "id": 1252,
    "newID": "R+G+B+Pi,AC",
    "name": "Alternating Clues & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "alternatingClues": true
  },
  {
    "id": 1253,
    "newID": "R+B+Pi,AC",
    "name": "Alternating Clues & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "alternatingClues": true
  },
  {
    "id": 1254,
    "newID": "R+Y+G+B+P+Wh,AC",
    "name": "Alternating Clues & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "alternatingClues": true
  },
  {
    "id": 1255,
    "newID": "R+Y+G+B+Wh,AC",
    "name": "Alternating Clues & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "alternatingClues": true
  },
  {
    "id": 1256,
    "newID": "R+G+B+Wh,AC",
    "name": "Alternating Clues & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "alternatingClues": true
  },
  {
    "id": 1257,
    "newID": "R+B+Wh,AC",
    "name": "Alternating Clues & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "alternatingClues": true
  },
  {
    "id": 1258,
    "newID": "R+Y+G+B+P+Br,AC",
    "name": "Alternating Clues & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "alternatingClues": true
  },
  {
    "id": 1259,
    "newID": "R+Y+G+B+Br,AC",
    "name": "Alternating Clues & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "alternatingClues": true
  },
  {
    "id": 1260,
    "newID": "R+G+B+Br,AC",
    "name": "Alternating Clues & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "alternatingClues": true
  },
  {
    "id": 1261,
    "newID": "R+B+Br,AC",
    "name": "Alternating Clues & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "alternatingClues": true
  },
  {
    "id": 1262,
    "newID": "R+Y+G+B+P+Om,AC",
    "name": "Alternating Clues & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "alternatingClues": true
  },
  {
    "id": 1263,
    "newID": "R+Y+G+B+Om,AC",
    "name": "Alternating Clues & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "alternatingClues": true
  },
  {
    "id": 1264,
    "newID": "R+G+B+Om,AC",
    "name": "Alternating Clues & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "alternatingClues": true
  },
  {
    "id": 1265,
    "newID": "R+B+Om,AC",
    "name": "Alternating Clues & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "alternatingClues": true
  },
  {
    "id": 1266,
    "newID": "R+Y+G+B+P+Nu,AC",
    "name": "Alternating Clues & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "alternatingClues": true
  },
  {
    "id": 1267,
    "newID": "R+Y+G+B+Nu,AC",
    "name": "Alternating Clues & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "alternatingClues": true
  },
  {
    "id": 1268,
    "newID": "R+G+B+Nu,AC",
    "name": "Alternating Clues & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "alternatingClues": true
  },
  {
    "id": 1269,
    "newID": "R+B+Nu,AC",
    "name": "Alternating Clues & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "alternatingClues": true
  },
  {
    "id": 1270,
    "newID": "R+Y+G+B+P+BrRa,AC",
    "name": "Alternating Clues & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1271,
    "newID": "R+Y+G+B+BrRa,AC",
    "name": "Alternating Clues & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1272,
    "newID": "R+G+B+BrRa,AC",
    "name": "Alternating Clues & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1273,
    "newID": "R+B+BrRa,AC",
    "name": "Alternating Clues & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1274,
    "newID": "R+Y+G+B+P+WhPi,AC",
    "name": "Alternating Clues & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "alternatingClues": true
  },
  {
    "id": 1275,
    "newID": "R+Y+G+B+WhPi,AC",
    "name": "Alternating Clues & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "alternatingClues": true
  },
  {
    "id": 1276,
    "newID": "R+G+B+WhPi,AC",
    "name": "Alternating Clues & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "alternatingClues": true
  },
  {
    "id": 1277,
    "newID": "R+B+WhPi,AC",
    "name": "Alternating Clues & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "alternatingClues": true
  },
  {
    "id": 1652,
    "newID": "R+Y+G+B+P+Pr,AC",
    "name": "Alternating Clues & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "alternatingClues": true
  },
  {
    "id": 1653,
    "newID": "R+Y+G+B+Pr,AC",
    "name": "Alternating Clues & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "alternatingClues": true
  },
  {
    "id": 1654,
    "newID": "R+G+B+Pr,AC",
    "name": "Alternating Clues & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "alternatingClues": true
  },
  {
    "id": 1655,
    "newID": "R+B+Pr,AC",
    "name": "Alternating Clues & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "alternatingClues": true
  },
  {
    "id": 1448,
    "newID": "R+Y+G+B+P+DRa,AC",
    "name": "Alternating Clues & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1449,
    "newID": "R+Y+G+B+DRa,AC",
    "name": "Alternating Clues & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1450,
    "newID": "R+Y+G+B+P+DPi,AC",
    "name": "Alternating Clues & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "alternatingClues": true
  },
  {
    "id": 1451,
    "newID": "R+Y+G+B+DPi,AC",
    "name": "Alternating Clues & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "alternatingClues": true
  },
  {
    "id": 1452,
    "newID": "R+Y+G+B+P+DWh,AC",
    "name": "Alternating Clues & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "alternatingClues": true
  },
  {
    "id": 1453,
    "newID": "R+Y+G+B+DWh,AC",
    "name": "Alternating Clues & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "alternatingClues": true
  },
  {
    "id": 1454,
    "newID": "R+Y+G+B+P+DBr,AC",
    "name": "Alternating Clues & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "alternatingClues": true
  },
  {
    "id": 1455,
    "newID": "R+Y+G+B+DBr,AC",
    "name": "Alternating Clues & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "alternatingClues": true
  },
  {
    "id": 1456,
    "newID": "R+Y+G+B+P+DOm,AC",
    "name": "Alternating Clues & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "alternatingClues": true
  },
  {
    "id": 1457,
    "newID": "R+Y+G+B+DOm,AC",
    "name": "Alternating Clues & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "alternatingClues": true
  },
  {
    "id": 1458,
    "newID": "R+Y+G+B+P+DNu,AC",
    "name": "Alternating Clues & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "alternatingClues": true
  },
  {
    "id": 1459,
    "newID": "R+Y+G+B+DNu,AC",
    "name": "Alternating Clues & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "alternatingClues": true
  },
  {
    "id": 1460,
    "newID": "R+Y+G+B+P+DBrRa,AC",
    "name": "Alternating Clues & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1461,
    "newID": "R+Y+G+B+DBrRa,AC",
    "name": "Alternating Clues & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "alternatingClues": true
  },
  {
    "id": 1462,
    "newID": "R+Y+G+B+P+DWhPi,AC",
    "name": "Alternating Clues & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "alternatingClues": true
  },
  {
    "id": 1463,
    "newID": "R+Y+G+B+DWhPi,AC",
    "name": "Alternating Clues & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "alternatingClues": true
  },
  {
    "id": 1656,
    "newID": "R+Y+G+B+P+DPr,AC",
    "name": "Alternating Clues & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "alternatingClues": true
  },
  {
    "id": 1657,
    "newID": "R+Y+G+B+DPr,AC",
    "name": "Alternating Clues & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "alternatingClues": true
  },
  {
    "id": 98,
    "newID": "R+Y+G+B+P+T,CP",
    "name": "Cow & Pig (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "cowAndPig": true
  },
  {
    "id": 99,
    "newID": "R+Y+G+B+P,CP",
    "name": "Cow & Pig (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "cowAndPig": true
  },
  {
    "id": 100,
    "newID": "R+Y+G+B,CP",
    "name": "Cow & Pig (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "cowAndPig": true
  },
  {
    "id": 101,
    "newID": "R+G+B,CP",
    "name": "Cow & Pig (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "cowAndPig": true
  },
  {
    "id": 54,
    "newID": "R+Y+G+B+P+T,Du",
    "name": "Duck (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "duck": true
  },
  {
    "id": 55,
    "newID": "R+Y+G+B+P,Du",
    "name": "Duck (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "duck": true
  },
  {
    "id": 56,
    "newID": "R+Y+G+B,Du",
    "name": "Duck (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "duck": true
  },
  {
    "id": 57,
    "newID": "R+G+B,Du",
    "name": "Duck (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "duck": true
  },
  {
    "id": 1646,
    "newID": "R+Y+G+B+P+T,OE",
    "name": "Odds and Evens (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1808,
    "newID": "R+Y+G+B+P,OE",
    "name": "Odds and Evens (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1812,
    "newID": "R+Y+G+B,OE",
    "name": "Odds and Evens (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1816,
    "newID": "R+G+B,OE",
    "name": "Odds and Evens (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1820,
    "newID": "R+Y+G+B+P+Bk,OE",
    "name": "Odds and Evens & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1841,
    "newID": "R+Y+G+B+Bk,OE",
    "name": "Odds and Evens & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1842,
    "newID": "R+Y+G+B+P+Ra,OE",
    "name": "Odds and Evens & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1843,
    "newID": "R+Y+G+B+Ra,OE",
    "name": "Odds and Evens & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1844,
    "newID": "R+G+B+Ra,OE",
    "name": "Odds and Evens & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1845,
    "newID": "R+B+Ra,OE",
    "name": "Odds and Evens & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1846,
    "newID": "R+Y+G+B+P+Pi,OE",
    "name": "Odds and Evens & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1847,
    "newID": "R+Y+G+B+Pi,OE",
    "name": "Odds and Evens & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1848,
    "newID": "R+G+B+Pi,OE",
    "name": "Odds and Evens & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1849,
    "newID": "R+B+Pi,OE",
    "name": "Odds and Evens & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1850,
    "newID": "R+Y+G+B+P+Wh,OE",
    "name": "Odds and Evens & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1851,
    "newID": "R+Y+G+B+Wh,OE",
    "name": "Odds and Evens & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1852,
    "newID": "R+G+B+Wh,OE",
    "name": "Odds and Evens & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1853,
    "newID": "R+B+Wh,OE",
    "name": "Odds and Evens & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1854,
    "newID": "R+Y+G+B+P+Br,OE",
    "name": "Odds and Evens & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1855,
    "newID": "R+Y+G+B+Br,OE",
    "name": "Odds and Evens & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1856,
    "newID": "R+G+B+Br,OE",
    "name": "Odds and Evens & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1857,
    "newID": "R+B+Br,OE",
    "name": "Odds and Evens & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1858,
    "newID": "R+Y+G+B+P+Om,OE",
    "name": "Odds and Evens & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1859,
    "newID": "R+Y+G+B+Om,OE",
    "name": "Odds and Evens & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1860,
    "newID": "R+G+B+Om,OE",
    "name": "Odds and Evens & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1861,
    "newID": "R+B+Om,OE",
    "name": "Odds and Evens & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1862,
    "newID": "R+Y+G+B+P+Nu,OE",
    "name": "Odds and Evens & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1863,
    "newID": "R+Y+G+B+Nu,OE",
    "name": "Odds and Evens & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1864,
    "newID": "R+G+B+Nu,OE",
    "name": "Odds and Evens & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1865,
    "newID": "R+B+Nu,OE",
    "name": "Odds and Evens & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1866,
    "newID": "R+Y+G+B+P+BrRa,OE",
    "name": "Odds and Evens & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1867,
    "newID": "R+Y+G+B+BrRa,OE",
    "name": "Odds and Evens & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1868,
    "newID": "R+G+B+BrRa,OE",
    "name": "Odds and Evens & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1869,
    "newID": "R+B+BrRa,OE",
    "name": "Odds and Evens & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1870,
    "newID": "R+Y+G+B+P+WhPi,OE",
    "name": "Odds and Evens & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1871,
    "newID": "R+Y+G+B+WhPi,OE",
    "name": "Odds and Evens & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1872,
    "newID": "R+G+B+WhPi,OE",
    "name": "Odds and Evens & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1873,
    "newID": "R+B+WhPi,OE",
    "name": "Odds and Evens & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1874,
    "newID": "R+Y+G+B+P+Pr,OE",
    "name": "Odds and Evens & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1875,
    "newID": "R+Y+G+B+Pr,OE",
    "name": "Odds and Evens & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1876,
    "newID": "R+G+B+Pr,OE",
    "name": "Odds and Evens & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1877,
    "newID": "R+B+Pr,OE",
    "name": "Odds and Evens & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1878,
    "newID": "R+Y+G+B+P+DRa,OE",
    "name": "Odds and Evens & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1879,
    "newID": "R+Y+G+B+DRa,OE",
    "name": "Odds and Evens & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1880,
    "newID": "R+Y+G+B+P+DPi,OE",
    "name": "Odds and Evens & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1881,
    "newID": "R+Y+G+B+DPi,OE",
    "name": "Odds and Evens & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1882,
    "newID": "R+Y+G+B+P+DWh,OE",
    "name": "Odds and Evens & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1883,
    "newID": "R+Y+G+B+DWh,OE",
    "name": "Odds and Evens & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1884,
    "newID": "R+Y+G+B+P+DBr,OE",
    "name": "Odds and Evens & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1885,
    "newID": "R+Y+G+B+DBr,OE",
    "name": "Odds and Evens & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1886,
    "newID": "R+Y+G+B+P+DOm,OE",
    "name": "Odds and Evens & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1887,
    "newID": "R+Y+G+B+DOm,OE",
    "name": "Odds and Evens & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1888,
    "newID": "R+Y+G+B+P+DNu,OE",
    "name": "Odds and Evens & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1889,
    "newID": "R+Y+G+B+DNu,OE",
    "name": "Odds and Evens & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1890,
    "newID": "R+Y+G+B+P+DBrRa,OE",
    "name": "Odds and Evens & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1891,
    "newID": "R+Y+G+B+DBrRa,OE",
    "name": "Odds and Evens & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1892,
    "newID": "R+Y+G+B+P+DWhPi,OE",
    "name": "Odds and Evens & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1893,
    "newID": "R+Y+G+B+DWhPi,OE",
    "name": "Odds and Evens & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1894,
    "newID": "R+Y+G+B+P+DPr,OE",
    "name": "Odds and Evens & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1895,
    "newID": "R+Y+G+B+DPr,OE",
    "name": "Odds and Evens & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "clueRanks": [1, 2],
    "oddsAndEvens": true
  },
  {
    "id": 1353,
    "newID": "R+Y+G+B+P+T,NM,Sy",
    "name": "Synesthesia (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1383,
    "newID": "R+Y+G+B+P,NM,Sy",
    "name": "Synesthesia (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1386,
    "newID": "R+Y+G+B,NM,Sy",
    "name": "Synesthesia (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1387,
    "newID": "R+G+B,NM,Sy",
    "name": "Synesthesia (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1642,
    "newID": "R+Y+G+B+P+Bk,NM,Sy",
    "name": "Synesthesia & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1644,
    "newID": "R+Y+G+B+Bk,NM,Sy",
    "name": "Synesthesia & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1647,
    "newID": "R+Y+G+B+P+Ra,NM,Sy",
    "name": "Synesthesia & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1648,
    "newID": "R+Y+G+B+Ra,NM,Sy",
    "name": "Synesthesia & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1791,
    "newID": "R+G+B+Ra,NM,Sy",
    "name": "Synesthesia & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1792,
    "newID": "R+B+Ra,NM,Sy",
    "name": "Synesthesia & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1793,
    "newID": "R+Y+G+B+P+Wh,NM,Sy",
    "name": "Synesthesia & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1794,
    "newID": "R+Y+G+B+Wh,NM,Sy",
    "name": "Synesthesia & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1795,
    "newID": "R+G+B+Wh,NM,Sy",
    "name": "Synesthesia & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1796,
    "newID": "R+B+Wh,NM,Sy",
    "name": "Synesthesia & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1797,
    "newID": "R+Y+G+B+P+Br,NM,Sy",
    "name": "Synesthesia & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1798,
    "newID": "R+Y+G+B+Br,NM,Sy",
    "name": "Synesthesia & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1799,
    "newID": "R+G+B+Br,NM,Sy",
    "name": "Synesthesia & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1800,
    "newID": "R+B+Br,NM,Sy",
    "name": "Synesthesia & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1801,
    "newID": "R+Y+G+B+P+Nu,NM,Sy",
    "name": "Synesthesia & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1802,
    "newID": "R+Y+G+B+Nu,NM,Sy",
    "name": "Synesthesia & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1803,
    "newID": "R+G+B+Nu,NM,Sy",
    "name": "Synesthesia & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1804,
    "newID": "R+B+Nu,NM,Sy",
    "name": "Synesthesia & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1805,
    "newID": "R+Y+G+B+P+DRa,NM,Sy",
    "name": "Synesthesia & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1806,
    "newID": "R+Y+G+B+DRa,NM,Sy",
    "name": "Synesthesia & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1809,
    "newID": "R+Y+G+B+P+DWh,NM,Sy",
    "name": "Synesthesia & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1810,
    "newID": "R+Y+G+B+DWh,NM,Sy",
    "name": "Synesthesia & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1813,
    "newID": "R+Y+G+B+P+DBr,NM,Sy",
    "name": "Synesthesia & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1814,
    "newID": "R+Y+G+B+DBr,NM,Sy",
    "name": "Synesthesia & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1817,
    "newID": "R+Y+G+B+P+DNu,NM,Sy",
    "name": "Synesthesia & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1818,
    "newID": "R+Y+G+B+DNu,NM,Sy",
    "name": "Synesthesia & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "clueRanks": [],
    "synesthesia": true
  },
  {
    "id": 1342,
    "newID": "R+Y+G+B+P+T:R",
    "name": "Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal Reversed"]
  },
  {
    "id": 1343,
    "newID": "R+Y+G+B+P:R",
    "name": "Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple Reversed"]
  },
  {
    "id": 1344,
    "newID": "R+Y+G+B:R",
    "name": "Reversed (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue Reversed"]
  },
  {
    "id": 1345,
    "newID": "R+G+B:R",
    "name": "Reversed (3 Suits)",
    "suits": ["Red", "Green", "Blue Reversed"]
  },
  {
    "id": 1346,
    "newID": "R+Y+G+B+P+Bk:R",
    "name": "Black Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black Reversed"]
  },
  {
    "id": 1347,
    "newID": "R+Y+G+B+Bk:R",
    "name": "Black Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black Reversed"]
  },
  {
    "id": 1348,
    "newID": "R+Y+G+B+P+Ra:R",
    "name": "Rainbow Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow Reversed"]
  },
  {
    "id": 1349,
    "newID": "R+Y+G+B+Ra:R",
    "name": "Rainbow Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow Reversed"]
  },
  {
    "id": 1350,
    "newID": "R+G+B+Ra:R",
    "name": "Rainbow Reversed (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow Reversed"]
  },
  {
    "id": 1351,
    "newID": "R+B+Ra:R",
    "name": "Rainbow Reversed (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow Reversed"]
  },
  {
    "id": 1356,
    "newID": "R+Y+G+B+P+Wh:R",
    "name": "White Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White Reversed"]
  },
  {
    "id": 1357,
    "newID": "R+Y+G+B+Wh:R",
    "name": "White Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White Reversed"]
  },
  {
    "id": 1358,
    "newID": "R+G+B+Wh:R",
    "name": "White Reversed (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White Reversed"]
  },
  {
    "id": 1359,
    "newID": "R+B+Wh:R",
    "name": "White Reversed (3 Suits)",
    "suits": ["Red", "Blue", "White Reversed"]
  },
  {
    "id": 1663,
    "newID": "R+Y+G+B+P+Pr:R",
    "name": "Prism Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism Reversed"]
  },
  {
    "id": 1664,
    "newID": "R+Y+G+B+Pr:R",
    "name": "Prism Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism Reversed"]
  },
  {
    "id": 1665,
    "newID": "R+G+B+Pr:R",
    "name": "Prism Reversed (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism Reversed"]
  },
  {
    "id": 1666,
    "newID": "R+B+Pr:R",
    "name": "Prism Reversed (3 Suits)",
    "suits": ["Red", "Blue", "Prism Reversed"]
  },
  {
    "id": 1380,
    "newID": "R+Y+G+B+P+DRa:R",
    "name": "Dark Rainbow Reversed (6 Suits)",
    "suits": [
      "Red",
      "Yellow",
      "Green",
      "Blue",
      "Purple",
      "Dark Rainbow Reversed"
    ]
  },
  {
    "id": 1381,
    "newID": "R+Y+G+B+DRa:R",
    "name": "Dark Rainbow Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow Reversed"]
  },
  {
    "id": 1384,
    "newID": "R+Y+G+B+P+DWh:R",
    "name": "Gray Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Reversed"]
  },
  {
    "id": 1385,
    "newID": "R+Y+G+B+DWh:R",
    "name": "Gray Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Reversed"]
  },
  {
    "id": 1667,
    "newID": "R+Y+G+B+P+DPr:R",
    "name": "Dark Prism Reversed (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism Reversed"]
  },
  {
    "id": 1668,
    "newID": "R+Y+G+B+DPr:R",
    "name": "Dark Prism Reversed (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism Reversed"]
  },
  {
    "id": 40,
    "newID": "R+Y+G+B+P+T,UD",
    "name": "Up or Down (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "upOrDown": true
  },
  {
    "id": 24,
    "newID": "R+Y+G+B+P,UD",
    "name": "Up or Down (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "upOrDown": true
  },
  {
    "id": 2107,
    "newID": "R+Y+G+B+P+Bk,UD",
    "name": "Up or Down & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "upOrDown": true
  },
  {
    "id": 25,
    "newID": "R+Y+G+B+P+Ra,UD",
    "name": "Up or Down & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "upOrDown": true
  },
  {
    "id": 43,
    "newID": "R+Y+G+B+Ra,UD",
    "name": "Up or Down & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "upOrDown": true
  },
  {
    "id": 155,
    "newID": "R+Y+G+B+P+Pi,UD",
    "name": "Up or Down & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "upOrDown": true
  },
  {
    "id": 156,
    "newID": "R+Y+G+B+Pi,UD",
    "name": "Up or Down & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "upOrDown": true
  },
  {
    "id": 41,
    "newID": "R+Y+G+B+P+Wh,UD",
    "name": "Up or Down & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "upOrDown": true
  },
  {
    "id": 42,
    "newID": "R+Y+G+B+Wh,UD",
    "name": "Up or Down & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "upOrDown": true
  },
  {
    "id": 157,
    "newID": "R+Y+G+B+P+Br,UD",
    "name": "Up or Down & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "upOrDown": true
  },
  {
    "id": 158,
    "newID": "R+Y+G+B+Br,UD",
    "name": "Up or Down & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "upOrDown": true
  },
  {
    "id": 277,
    "newID": "R+Y+G+B+P+Om,UD",
    "name": "Up or Down & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "upOrDown": true
  },
  {
    "id": 278,
    "newID": "R+Y+G+B+Om,UD",
    "name": "Up or Down & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "upOrDown": true
  },
  {
    "id": 61,
    "newID": "R+Y+G+B+P+Nu,UD",
    "name": "Up or Down & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "upOrDown": true
  },
  {
    "id": 44,
    "newID": "R+Y+G+B+Nu,UD",
    "name": "Up or Down & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "upOrDown": true
  },
  {
    "id": 863,
    "newID": "R+Y+G+B+P+BrRa,UD",
    "name": "Up or Down & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "upOrDown": true
  },
  {
    "id": 864,
    "newID": "R+Y+G+B+BrRa,UD",
    "name": "Up or Down & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "upOrDown": true
  },
  {
    "id": 865,
    "newID": "R+Y+G+B+P+WhPi,UD",
    "name": "Up or Down & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "upOrDown": true
  },
  {
    "id": 866,
    "newID": "R+Y+G+B+WhPi,UD",
    "name": "Up or Down & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "upOrDown": true
  },
  {
    "id": 1669,
    "newID": "R+Y+G+B+P+Pr,UD",
    "name": "Up or Down & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "upOrDown": true
  },
  {
    "id": 1670,
    "newID": "R+Y+G+B+Pr,UD",
    "name": "Up or Down & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "upOrDown": true
  },
  {
    "id": 2108,
    "newID": "R+Y+G+B+P+DRa,UD",
    "name": "Up or Down & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "upOrDown": true
  },
  {
    "id": 2109,
    "newID": "R+Y+G+B+P+DPi,UD",
    "name": "Up or Down & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "upOrDown": true
  },
  {
    "id": 2110,
    "newID": "R+Y+G+B+P+DWh,UD",
    "name": "Up or Down & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "upOrDown": true
  },
  {
    "id": 2111,
    "newID": "R+Y+G+B+P+DBr,UD",
    "name": "Up or Down & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "upOrDown": true
  },
  {
    "id": 2112,
    "newID": "R+Y+G+B+P+DOm,UD",
    "name": "Up or Down & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "upOrDown": true
  },
  {
    "id": 2113,
    "newID": "R+Y+G+B+P+DNu,UD",
    "name": "Up or Down & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "upOrDown": true
  },
  {
    "id": 2114,
    "newID": "R+Y+G+B+P+DBrRa,UD",
    "name": "Up or Down & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "upOrDown": true
  },
  {
    "id": 2115,
    "newID": "R+Y+G+B+P+DWhPi,UD",
    "name": "Up or Down & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "upOrDown": true
  },
  {
    "id": 2116,
    "newID": "R+Y+G+B+P+DPr,UD",
    "name": "Up or Down & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "upOrDown": true
  },
  {
    "id": 279,
    "newID": "R+Y+G+B+P+T,TH",
    "name": "Throw It in a Hole (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "throwItInAHole": true
  },
  {
    "id": 280,
    "newID": "R+Y+G+B+P,TH",
    "name": "Throw It in a Hole (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "throwItInAHole": true
  },
  {
    "id": 281,
    "newID": "R+Y+G+B,TH",
    "name": "Throw It in a Hole (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "throwItInAHole": true
  },
  {
    "id": 2117,
    "newID": "R+Y+G+B+P+Bk,TH",
    "name": "Throw It in a Hole & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "throwItInAHole": true
  },
  {
    "id": 1220,
    "newID": "R+Y+G+B+P+Ra,TH",
    "name": "Throw It in a Hole & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 1221,
    "newID": "R+Y+G+B+Ra,TH",
    "name": "Throw It in a Hole & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 1222,
    "newID": "R+G+B+Ra,TH",
    "name": "Throw It in a Hole & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 1223,
    "newID": "R+Y+G+B+P+Pi,TH",
    "name": "Throw It in a Hole & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "throwItInAHole": true
  },
  {
    "id": 1224,
    "newID": "R+Y+G+B+Pi,TH",
    "name": "Throw It in a Hole & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "throwItInAHole": true
  },
  {
    "id": 1225,
    "newID": "R+G+B+Pi,TH",
    "name": "Throw It in a Hole & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "throwItInAHole": true
  },
  {
    "id": 1226,
    "newID": "R+Y+G+B+P+Wh,TH",
    "name": "Throw It in a Hole & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "throwItInAHole": true
  },
  {
    "id": 1227,
    "newID": "R+Y+G+B+Wh,TH",
    "name": "Throw It in a Hole & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "throwItInAHole": true
  },
  {
    "id": 1228,
    "newID": "R+G+B+Wh,TH",
    "name": "Throw It in a Hole & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "throwItInAHole": true
  },
  {
    "id": 1229,
    "newID": "R+Y+G+B+P+Br,TH",
    "name": "Throw It in a Hole & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "throwItInAHole": true
  },
  {
    "id": 1230,
    "newID": "R+Y+G+B+Br,TH",
    "name": "Throw It in a Hole & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "throwItInAHole": true
  },
  {
    "id": 1231,
    "newID": "R+G+B+Br,TH",
    "name": "Throw It in a Hole & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "throwItInAHole": true
  },
  {
    "id": 1232,
    "newID": "R+Y+G+B+P+Om,TH",
    "name": "Throw It in a Hole & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "throwItInAHole": true
  },
  {
    "id": 1233,
    "newID": "R+Y+G+B+Om,TH",
    "name": "Throw It in a Hole & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "throwItInAHole": true
  },
  {
    "id": 1234,
    "newID": "R+G+B+Om,TH",
    "name": "Throw It in a Hole & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "throwItInAHole": true
  },
  {
    "id": 1235,
    "newID": "R+Y+G+B+P+Nu,TH",
    "name": "Throw It in a Hole & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "throwItInAHole": true
  },
  {
    "id": 1236,
    "newID": "R+Y+G+B+Nu,TH",
    "name": "Throw It in a Hole & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "throwItInAHole": true
  },
  {
    "id": 1237,
    "newID": "R+G+B+Nu,TH",
    "name": "Throw It in a Hole & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "throwItInAHole": true
  },
  {
    "id": 1238,
    "newID": "R+Y+G+B+P+BrRa,TH",
    "name": "Throw It in a Hole & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 1239,
    "newID": "R+Y+G+B+BrRa,TH",
    "name": "Throw It in a Hole & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 1240,
    "newID": "R+G+B+BrRa,TH",
    "name": "Throw It in a Hole & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 1241,
    "newID": "R+Y+G+B+P+WhPi,TH",
    "name": "Throw It in a Hole & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "throwItInAHole": true
  },
  {
    "id": 1242,
    "newID": "R+Y+G+B+WhPi,TH",
    "name": "Throw It in a Hole & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "throwItInAHole": true
  },
  {
    "id": 1243,
    "newID": "R+G+B+WhPi,TH",
    "name": "Throw It in a Hole & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "throwItInAHole": true
  },
  {
    "id": 1660,
    "newID": "R+Y+G+B+P+Pr,TH",
    "name": "Throw It in a Hole & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "throwItInAHole": true
  },
  {
    "id": 1661,
    "newID": "R+Y+G+B+Pr,TH",
    "name": "Throw It in a Hole & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "throwItInAHole": true
  },
  {
    "id": 1662,
    "newID": "R+G+B+Pr,TH",
    "name": "Throw It in a Hole & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "throwItInAHole": true
  },
  {
    "id": 2118,
    "newID": "R+Y+G+B+P+DRa,TH",
    "name": "Throw It in a Hole & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 2119,
    "newID": "R+Y+G+B+P+DPi,TH",
    "name": "Throw It in a Hole & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "throwItInAHole": true
  },
  {
    "id": 2120,
    "newID": "R+Y+G+B+P+DWh,TH",
    "name": "Throw It in a Hole & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "throwItInAHole": true
  },
  {
    "id": 2121,
    "newID": "R+Y+G+B+P+DBr,TH",
    "name": "Throw It in a Hole & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "throwItInAHole": true
  },
  {
    "id": 2122,
    "newID": "R+Y+G+B+P+DOm,TH",
    "name": "Throw It in a Hole & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "throwItInAHole": true
  },
  {
    "id": 2123,
    "newID": "R+Y+G+B+P+DNu,TH",
    "name": "Throw It in a Hole & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "throwItInAHole": true
  },
  {
    "id": 2124,
    "newID": "R+Y+G+B+P+DBrRa,TH",
    "name": "Throw It in a Hole & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "throwItInAHole": true
  },
  {
    "id": 2125,
    "newID": "R+Y+G+B+P+DWhPi,TH",
    "name": "Throw It in a Hole & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "throwItInAHole": true
  },
  {
    "id": 2126,
    "newID": "R+Y+G+B+P+DPr,TH",
    "name": "Throw It in a Hole & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "throwItInAHole": true
  },
  {
    "id": 1896,
    "newID": "R+Y+G+B+P+T,Fu",
    "name": "Funnels (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "funnels": true
  },
  {
    "id": 1897,
    "newID": "R+Y+G+B+P,Fu",
    "name": "Funnels (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "funnels": true
  },
  {
    "id": 1898,
    "newID": "R+Y+G+B,Fu",
    "name": "Funnels (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "funnels": true
  },
  {
    "id": 1899,
    "newID": "R+G+B,Fu",
    "name": "Funnels (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "funnels": true
  },
  {
    "id": 1900,
    "newID": "R+Y+G+B+P+Bk,Fu",
    "name": "Funnels & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "funnels": true
  },
  {
    "id": 1901,
    "newID": "R+Y+G+B+Bk,Fu",
    "name": "Funnels & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "funnels": true
  },
  {
    "id": 1902,
    "newID": "R+Y+G+B+P+Ra,Fu",
    "name": "Funnels & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "funnels": true
  },
  {
    "id": 1903,
    "newID": "R+Y+G+B+Ra,Fu",
    "name": "Funnels & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "funnels": true
  },
  {
    "id": 1904,
    "newID": "R+G+B+Ra,Fu",
    "name": "Funnels & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "funnels": true
  },
  {
    "id": 1905,
    "newID": "R+B+Ra,Fu",
    "name": "Funnels & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "funnels": true
  },
  {
    "id": 1906,
    "newID": "R+Y+G+B+P+Pi,Fu",
    "name": "Funnels & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "funnels": true
  },
  {
    "id": 1907,
    "newID": "R+Y+G+B+Pi,Fu",
    "name": "Funnels & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "funnels": true
  },
  {
    "id": 1908,
    "newID": "R+G+B+Pi,Fu",
    "name": "Funnels & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "funnels": true
  },
  {
    "id": 1909,
    "newID": "R+B+Pi,Fu",
    "name": "Funnels & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "funnels": true
  },
  {
    "id": 1910,
    "newID": "R+Y+G+B+P+Wh,Fu",
    "name": "Funnels & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "funnels": true
  },
  {
    "id": 1911,
    "newID": "R+Y+G+B+Wh,Fu",
    "name": "Funnels & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "funnels": true
  },
  {
    "id": 1912,
    "newID": "R+G+B+Wh,Fu",
    "name": "Funnels & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "funnels": true
  },
  {
    "id": 1913,
    "newID": "R+B+Wh,Fu",
    "name": "Funnels & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "funnels": true
  },
  {
    "id": 1914,
    "newID": "R+Y+G+B+P+Br,Fu",
    "name": "Funnels & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "funnels": true
  },
  {
    "id": 1915,
    "newID": "R+Y+G+B+Br,Fu",
    "name": "Funnels & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "funnels": true
  },
  {
    "id": 1916,
    "newID": "R+G+B+Br,Fu",
    "name": "Funnels & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "funnels": true
  },
  {
    "id": 1917,
    "newID": "R+B+Br,Fu",
    "name": "Funnels & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "funnels": true
  },
  {
    "id": 1918,
    "newID": "R+Y+G+B+P+Om,Fu",
    "name": "Funnels & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "funnels": true
  },
  {
    "id": 1919,
    "newID": "R+Y+G+B+Om,Fu",
    "name": "Funnels & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "funnels": true
  },
  {
    "id": 1920,
    "newID": "R+G+B+Om,Fu",
    "name": "Funnels & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "funnels": true
  },
  {
    "id": 1921,
    "newID": "R+B+Om,Fu",
    "name": "Funnels & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "funnels": true
  },
  {
    "id": 1922,
    "newID": "R+Y+G+B+P+Nu,Fu",
    "name": "Funnels & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "funnels": true
  },
  {
    "id": 1923,
    "newID": "R+Y+G+B+Nu,Fu",
    "name": "Funnels & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "funnels": true
  },
  {
    "id": 1924,
    "newID": "R+G+B+Nu,Fu",
    "name": "Funnels & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "funnels": true
  },
  {
    "id": 1925,
    "newID": "R+B+Nu,Fu",
    "name": "Funnels & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "funnels": true
  },
  {
    "id": 1926,
    "newID": "R+Y+G+B+P+BrRa,Fu",
    "name": "Funnels & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "funnels": true
  },
  {
    "id": 1927,
    "newID": "R+Y+G+B+BrRa,Fu",
    "name": "Funnels & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "funnels": true
  },
  {
    "id": 1928,
    "newID": "R+G+B+BrRa,Fu",
    "name": "Funnels & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "funnels": true
  },
  {
    "id": 1929,
    "newID": "R+B+BrRa,Fu",
    "name": "Funnels & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "funnels": true
  },
  {
    "id": 1930,
    "newID": "R+Y+G+B+P+WhPi,Fu",
    "name": "Funnels & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "funnels": true
  },
  {
    "id": 1931,
    "newID": "R+Y+G+B+WhPi,Fu",
    "name": "Funnels & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "funnels": true
  },
  {
    "id": 1932,
    "newID": "R+G+B+WhPi,Fu",
    "name": "Funnels & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "funnels": true
  },
  {
    "id": 1933,
    "newID": "R+B+WhPi,Fu",
    "name": "Funnels & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "funnels": true
  },
  {
    "id": 1934,
    "newID": "R+Y+G+B+P+Pr,Fu",
    "name": "Funnels & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "funnels": true
  },
  {
    "id": 1935,
    "newID": "R+Y+G+B+Pr,Fu",
    "name": "Funnels & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "funnels": true
  },
  {
    "id": 1936,
    "newID": "R+G+B+Pr,Fu",
    "name": "Funnels & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "funnels": true
  },
  {
    "id": 1937,
    "newID": "R+B+Pr,Fu",
    "name": "Funnels & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "funnels": true
  },
  {
    "id": 1938,
    "newID": "R+Y+G+B+P+DRa,Fu",
    "name": "Funnels & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "funnels": true
  },
  {
    "id": 1939,
    "newID": "R+Y+G+B+DRa,Fu",
    "name": "Funnels & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "funnels": true
  },
  {
    "id": 1940,
    "newID": "R+Y+G+B+P+DPi,Fu",
    "name": "Funnels & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "funnels": true
  },
  {
    "id": 1941,
    "newID": "R+Y+G+B+DPi,Fu",
    "name": "Funnels & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "funnels": true
  },
  {
    "id": 1942,
    "newID": "R+Y+G+B+P+DWh,Fu",
    "name": "Funnels & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "funnels": true
  },
  {
    "id": 1943,
    "newID": "R+Y+G+B+DWh,Fu",
    "name": "Funnels & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "funnels": true
  },
  {
    "id": 1944,
    "newID": "R+Y+G+B+P+DBr,Fu",
    "name": "Funnels & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "funnels": true
  },
  {
    "id": 1945,
    "newID": "R+Y+G+B+DBr,Fu",
    "name": "Funnels & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "funnels": true
  },
  {
    "id": 1946,
    "newID": "R+Y+G+B+P+DOm,Fu",
    "name": "Funnels & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "funnels": true
  },
  {
    "id": 1947,
    "newID": "R+Y+G+B+DOm,Fu",
    "name": "Funnels & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "funnels": true
  },
  {
    "id": 1948,
    "newID": "R+Y+G+B+P+DNu,Fu",
    "name": "Funnels & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "funnels": true
  },
  {
    "id": 1949,
    "newID": "R+Y+G+B+DNu,Fu",
    "name": "Funnels & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "funnels": true
  },
  {
    "id": 1950,
    "newID": "R+Y+G+B+P+DBrRa,Fu",
    "name": "Funnels & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "funnels": true
  },
  {
    "id": 1951,
    "newID": "R+Y+G+B+DBrRa,Fu",
    "name": "Funnels & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "funnels": true
  },
  {
    "id": 1952,
    "newID": "R+Y+G+B+P+DWhPi,Fu",
    "name": "Funnels & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "funnels": true
  },
  {
    "id": 1953,
    "newID": "R+Y+G+B+DWhPi,Fu",
    "name": "Funnels & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "funnels": true
  },
  {
    "id": 1954,
    "newID": "R+Y+G+B+P+DPr,Fu",
    "name": "Funnels & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "funnels": true
  },
  {
    "id": 1955,
    "newID": "R+Y+G+B+DPr,Fu",
    "name": "Funnels & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "funnels": true
  },
  {
    "id": 1956,
    "newID": "R+Y+G+B+P+T,Ch",
    "name": "Chimneys (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Teal"],
    "chimneys": true
  },
  {
    "id": 1957,
    "newID": "R+Y+G+B+P,Ch",
    "name": "Chimneys (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "chimneys": true
  },
  {
    "id": 1958,
    "newID": "R+Y+G+B,Ch",
    "name": "Chimneys (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "chimneys": true
  },
  {
    "id": 1959,
    "newID": "R+G+B,Ch",
    "name": "Chimneys (3 Suits)",
    "suits": ["Red", "Green", "Blue"],
    "chimneys": true
  },
  {
    "id": 1960,
    "newID": "R+Y+G+B+P+Bk,Ch",
    "name": "Chimneys & Black (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Black"],
    "chimneys": true
  },
  {
    "id": 1961,
    "newID": "R+Y+G+B+Bk,Ch",
    "name": "Chimneys & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "chimneys": true
  },
  {
    "id": 1962,
    "newID": "R+Y+G+B+P+Ra,Ch",
    "name": "Chimneys & Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Rainbow"],
    "chimneys": true
  },
  {
    "id": 1963,
    "newID": "R+Y+G+B+Ra,Ch",
    "name": "Chimneys & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "chimneys": true
  },
  {
    "id": 1964,
    "newID": "R+G+B+Ra,Ch",
    "name": "Chimneys & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "chimneys": true
  },
  {
    "id": 1965,
    "newID": "R+B+Ra,Ch",
    "name": "Chimneys & Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Rainbow"],
    "chimneys": true
  },
  {
    "id": 1966,
    "newID": "R+Y+G+B+P+Pi,Ch",
    "name": "Chimneys & Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Pink"],
    "chimneys": true
  },
  {
    "id": 1967,
    "newID": "R+Y+G+B+Pi,Ch",
    "name": "Chimneys & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "chimneys": true
  },
  {
    "id": 1968,
    "newID": "R+G+B+Pi,Ch",
    "name": "Chimneys & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "chimneys": true
  },
  {
    "id": 1969,
    "newID": "R+B+Pi,Ch",
    "name": "Chimneys & Pink (3 Suits)",
    "suits": ["Red", "Blue", "Pink"],
    "chimneys": true
  },
  {
    "id": 1970,
    "newID": "R+Y+G+B+P+Wh,Ch",
    "name": "Chimneys & White (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "White"],
    "chimneys": true
  },
  {
    "id": 1971,
    "newID": "R+Y+G+B+Wh,Ch",
    "name": "Chimneys & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "chimneys": true
  },
  {
    "id": 1972,
    "newID": "R+G+B+Wh,Ch",
    "name": "Chimneys & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "chimneys": true
  },
  {
    "id": 1973,
    "newID": "R+B+Wh,Ch",
    "name": "Chimneys & White (3 Suits)",
    "suits": ["Red", "Blue", "White"],
    "chimneys": true
  },
  {
    "id": 1974,
    "newID": "R+Y+G+B+P+Br,Ch",
    "name": "Chimneys & Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Brown"],
    "chimneys": true
  },
  {
    "id": 1975,
    "newID": "R+Y+G+B+Br,Ch",
    "name": "Chimneys & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "chimneys": true
  },
  {
    "id": 1976,
    "newID": "R+G+B+Br,Ch",
    "name": "Chimneys & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "chimneys": true
  },
  {
    "id": 1977,
    "newID": "R+B+Br,Ch",
    "name": "Chimneys & Brown (3 Suits)",
    "suits": ["Red", "Blue", "Brown"],
    "chimneys": true
  },
  {
    "id": 1978,
    "newID": "R+Y+G+B+P+Om,Ch",
    "name": "Chimneys & Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Omni"],
    "chimneys": true
  },
  {
    "id": 1979,
    "newID": "R+Y+G+B+Om,Ch",
    "name": "Chimneys & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "chimneys": true
  },
  {
    "id": 1980,
    "newID": "R+G+B+Om,Ch",
    "name": "Chimneys & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "chimneys": true
  },
  {
    "id": 1981,
    "newID": "R+B+Om,Ch",
    "name": "Chimneys & Omni (3 Suits)",
    "suits": ["Red", "Blue", "Omni"],
    "chimneys": true
  },
  {
    "id": 1982,
    "newID": "R+Y+G+B+P+Nu,Ch",
    "name": "Chimneys & Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Null"],
    "chimneys": true
  },
  {
    "id": 1983,
    "newID": "R+Y+G+B+Nu,Ch",
    "name": "Chimneys & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "chimneys": true
  },
  {
    "id": 1984,
    "newID": "R+G+B+Nu,Ch",
    "name": "Chimneys & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "chimneys": true
  },
  {
    "id": 1985,
    "newID": "R+B+Nu,Ch",
    "name": "Chimneys & Null (3 Suits)",
    "suits": ["Red", "Blue", "Null"],
    "chimneys": true
  },
  {
    "id": 1986,
    "newID": "R+Y+G+B+P+BrRa,Ch",
    "name": "Chimneys & Muddy Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Muddy Rainbow"],
    "chimneys": true
  },
  {
    "id": 1987,
    "newID": "R+Y+G+B+BrRa,Ch",
    "name": "Chimneys & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "chimneys": true
  },
  {
    "id": 1988,
    "newID": "R+G+B+BrRa,Ch",
    "name": "Chimneys & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "chimneys": true
  },
  {
    "id": 1989,
    "newID": "R+B+BrRa,Ch",
    "name": "Chimneys & Muddy Rainbow (3 Suits)",
    "suits": ["Red", "Blue", "Muddy Rainbow"],
    "chimneys": true
  },
  {
    "id": 1990,
    "newID": "R+Y+G+B+P+WhPi,Ch",
    "name": "Chimneys & Light Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Light Pink"],
    "chimneys": true
  },
  {
    "id": 1991,
    "newID": "R+Y+G+B+WhPi,Ch",
    "name": "Chimneys & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "chimneys": true
  },
  {
    "id": 1992,
    "newID": "R+G+B+WhPi,Ch",
    "name": "Chimneys & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "chimneys": true
  },
  {
    "id": 1993,
    "newID": "R+B+WhPi,Ch",
    "name": "Chimneys & Light Pink (3 Suits)",
    "suits": ["Red", "Blue", "Light Pink"],
    "chimneys": true
  },
  {
    "id": 1994,
    "newID": "R+Y+G+B+P+Pr,Ch",
    "name": "Chimneys & Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Prism"],
    "chimneys": true
  },
  {
    "id": 1995,
    "newID": "R+Y+G+B+Pr,Ch",
    "name": "Chimneys & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "chimneys": true
  },
  {
    "id": 1996,
    "newID": "R+G+B+Pr,Ch",
    "name": "Chimneys & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "chimneys": true
  },
  {
    "id": 1997,
    "newID": "R+B+Pr,Ch",
    "name": "Chimneys & Prism (3 Suits)",
    "suits": ["Red", "Blue", "Prism"],
    "chimneys": true
  },
  {
    "id": 1998,
    "newID": "R+Y+G+B+P+DRa,Ch",
    "name": "Chimneys & Dark Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Rainbow"],
    "chimneys": true
  },
  {
    "id": 1999,
    "newID": "R+Y+G+B+DRa,Ch",
    "name": "Chimneys & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "chimneys": true
  },
  {
    "id": 2000,
    "newID": "R+Y+G+B+P+DPi,Ch",
    "name": "Chimneys & Dark Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Pink"],
    "chimneys": true
  },
  {
    "id": 2001,
    "newID": "R+Y+G+B+DPi,Ch",
    "name": "Chimneys & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "chimneys": true
  },
  {
    "id": 2002,
    "newID": "R+Y+G+B+P+DWh,Ch",
    "name": "Chimneys & Gray (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray"],
    "chimneys": true
  },
  {
    "id": 2003,
    "newID": "R+Y+G+B+DWh,Ch",
    "name": "Chimneys & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "chimneys": true
  },
  {
    "id": 2004,
    "newID": "R+Y+G+B+P+DBr,Ch",
    "name": "Chimneys & Dark Brown (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Brown"],
    "chimneys": true
  },
  {
    "id": 2005,
    "newID": "R+Y+G+B+DBr,Ch",
    "name": "Chimneys & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "chimneys": true
  },
  {
    "id": 2006,
    "newID": "R+Y+G+B+P+DOm,Ch",
    "name": "Chimneys & Dark Omni (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Omni"],
    "chimneys": true
  },
  {
    "id": 2007,
    "newID": "R+Y+G+B+DOm,Ch",
    "name": "Chimneys & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "chimneys": true
  },
  {
    "id": 2008,
    "newID": "R+Y+G+B+P+DNu,Ch",
    "name": "Chimneys & Dark Null (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Null"],
    "chimneys": true
  },
  {
    "id": 2009,
    "newID": "R+Y+G+B+DNu,Ch",
    "name": "Chimneys & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "chimneys": true
  },
  {
    "id": 2010,
    "newID": "R+Y+G+B+P+DBrRa,Ch",
    "name": "Chimneys & Cocoa Rainbow (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Cocoa Rainbow"],
    "chimneys": true
  },
  {
    "id": 2011,
    "newID": "R+Y+G+B+DBrRa,Ch",
    "name": "Chimneys & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "chimneys": true
  },
  {
    "id": 2012,
    "newID": "R+Y+G+B+P+DWhPi,Ch",
    "name": "Chimneys & Gray Pink (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Gray Pink"],
    "chimneys": true
  },
  {
    "id": 2013,
    "newID": "R+Y+G+B+DWhPi,Ch",
    "name": "Chimneys & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "chimneys": true
  },
  {
    "id": 2014,
    "newID": "R+Y+G+B+P+DPr,Ch",
    "name": "Chimneys & Dark Prism (6 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple", "Dark Prism"],
    "chimneys": true
  },
  {
    "id": 2015,
    "newID": "R+Y+G+B+DPr,Ch",
    "name": "Chimneys & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "chimneys": true
  },
  {
    "id": 2092,
    "newID": "R+Y+G+B,Su",
    "name": "Sudoku (4 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2072,
    "newID": "R+Y+G+B+P,Su",
    "name": "Sudoku (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Purple"],
    "sudoku": true
  },
  {
    "id": 2093,
    "newID": "R+G+B+Ra,Su",
    "name": "Sudoku & Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Rainbow"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2094,
    "newID": "R+G+B+Pi,Su",
    "name": "Sudoku & Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Pink"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2095,
    "newID": "R+G+B+Wh,Su",
    "name": "Sudoku & White (4 Suits)",
    "suits": ["Red", "Green", "Blue", "White"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2096,
    "newID": "R+G+B+Br,Su",
    "name": "Sudoku & Brown (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Brown"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2097,
    "newID": "R+G+B+Om,Su",
    "name": "Sudoku & Omni (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Omni"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2098,
    "newID": "R+G+B+Nu,Su",
    "name": "Sudoku & Null (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Null"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2099,
    "newID": "R+G+B+BrRa,Su",
    "name": "Sudoku & Muddy Rainbow (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Muddy Rainbow"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2100,
    "newID": "R+G+B+WhPi,Su",
    "name": "Sudoku & Light Pink (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Light Pink"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2101,
    "newID": "R+G+B+Pr,Su",
    "name": "Sudoku & Prism (4 Suits)",
    "suits": ["Red", "Green", "Blue", "Prism"],
    "sudoku": true,
    "stackSize": 4,
    "clueRanks": [1, 2, 3, 4]
  },
  {
    "id": 2073,
    "newID": "R+Y+G+B+Bk,Su",
    "name": "Sudoku & Black (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Black"],
    "sudoku": true
  },
  {
    "id": 2074,
    "newID": "R+Y+G+B+Ra,Su",
    "name": "Sudoku & Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Rainbow"],
    "sudoku": true
  },
  {
    "id": 2075,
    "newID": "R+Y+G+B+Pi,Su",
    "name": "Sudoku & Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Pink"],
    "sudoku": true
  },
  {
    "id": 2076,
    "newID": "R+Y+G+B+Wh,Su",
    "name": "Sudoku & White (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "White"],
    "sudoku": true
  },
  {
    "id": 2077,
    "newID": "R+Y+G+B+Br,Su",
    "name": "Sudoku & Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Brown"],
    "sudoku": true
  },
  {
    "id": 2078,
    "newID": "R+Y+G+B+Om,Su",
    "name": "Sudoku & Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Omni"],
    "sudoku": true
  },
  {
    "id": 2079,
    "newID": "R+Y+G+B+Nu,Su",
    "name": "Sudoku & Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Null"],
    "sudoku": true
  },
  {
    "id": 2080,
    "newID": "R+Y+G+B+BrRa,Su",
    "name": "Sudoku & Muddy Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Muddy Rainbow"],
    "sudoku": true
  },
  {
    "id": 2081,
    "newID": "R+Y+G+B+WhPi,Su",
    "name": "Sudoku & Light Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Light Pink"],
    "sudoku": true
  },
  {
    "id": 2082,
    "newID": "R+Y+G+B+Pr,Su",
    "name": "Sudoku & Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Prism"],
    "sudoku": true
  },
  {
    "id": 2083,
    "newID": "R+Y+G+B+DRa,Su",
    "name": "Sudoku & Dark Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Rainbow"],
    "sudoku": true
  },
  {
    "id": 2084,
    "newID": "R+Y+G+B+DPi,Su",
    "name": "Sudoku & Dark Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Pink"],
    "sudoku": true
  },
  {
    "id": 2085,
    "newID": "R+Y+G+B+DWh,Su",
    "name": "Sudoku & Gray (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray"],
    "sudoku": true
  },
  {
    "id": 2086,
    "newID": "R+Y+G+B+DBr,Su",
    "name": "Sudoku & Dark Brown (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Brown"],
    "sudoku": true
  },
  {
    "id": 2087,
    "newID": "R+Y+G+B+DOm,Su",
    "name": "Sudoku & Dark Omni (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Omni"],
    "sudoku": true
  },
  {
    "id": 2088,
    "newID": "R+Y+G+B+DNu,Su",
    "name": "Sudoku & Dark Null (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Null"],
    "sudoku": true
  },
  {
    "id": 2089,
    "newID": "R+Y+G+B+DBrRa,Su",
    "name": "Sudoku & Cocoa Rainbow (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Cocoa Rainbow"],
    "sudoku": true
  },
  {
    "id": 2090,
    "newID": "R+Y+G+B+DWhPi,Su",
    "name": "Sudoku & Gray Pink (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Gray Pink"],
    "sudoku": true
  },
  {
    "id": 2091,
    "newID": "R+Y+G+B+DPr,Su",
    "name": "Sudoku & Dark Prism (5 Suits)",
    "suits": ["Red", "Yellow", "Green", "Blue", "Dark Prism"],
    "sudoku": true
  }
]
